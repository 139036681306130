/*
    node_js
    3/17/2021 3:56 PM
    by Oleksandr
*/

/*
    node_js
    3/17/2021 2:19 PM
    by Oleksandr
*/

import '../../CWP.css';
import React, {useState} from "react";
import {calcFlight} from "../../../../calc/calc";
import {getMachfromCAS} from "../../../../calc/geometry/airspeedConverter";

const SpdMenu = (props) => {
    const spdArray = [
        100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570, 580, 590, 600, 610, 620, 630, 640, 650, 660, 670, 680, 690, 700
    ];
    const {callsignMenuFlight, isCon, tickValue, showFlightMenu, typeOfFlightMenu, startDate, dispatchFlightClearance} = props;
    let index = isCon || !callsignMenuFlight ? 0 : tickValue + callsignMenuFlight.flight.timeShift;
    return (
        <>
            {(callsignMenuFlight && typeOfFlightMenu && callsignMenuFlight.flight.flight[index]) &&
            <div className={'callsignMenu'}
                 style={{left: callsignMenuFlight.clientX + 'px', top: callsignMenuFlight.clientY + 'px'}}>
                <span>{callsignMenuFlight.flight.c}</span><br/>
                <select style={{width: '100%'}} name="Mach" size="8"
                        onInput={(e) => {
                            dispatchFlightClearance({
                                type: 'spd',
                                value:e.target.value,
                                flight: callsignMenuFlight.flight,
                                index,
                                startDate,
                            });
                            // callsignMenuFlight.flight.flight[index].sS = e.target.value;
                            // callsignMenuFlight.flight.flight[index].isOS = true;
                            // calcFlight(callsignMenuFlight.flight, index, startDate.getTime());
                            showFlightMenu(null);
                        }}>
                    {spdArray.filter((spd => {
                            if (!callsignMenuFlight.flight.flight[index].maxS) return true;

                            return spd <= callsignMenuFlight.flight.flight[index].maxS &&
                                spd >= callsignMenuFlight.flight.flight[index].minS;
                        }
                    ))
                        .map((spd) => {
                            let selected = callsignMenuFlight.flight.flight[index].s === spd;
                            return (<option selected={selected} value={spd}>{spd}</option>)
                        })}
                </select>
                <span style={{width: '45px'}} className={'buttonSDD'} onClick={(e) => {
                    dispatchFlightClearance({
                        type: 'std',
                        flight: callsignMenuFlight.flight,
                        index,
                        startDate,
                    });
                    showFlightMenu(null);
                }}>
                    STD
                </span>
                <input type="number" autoFocus style={{width: '55px'}}

                       step='0.01' min='100' max='700'
                       onKeyDown={(e) => {

                           if (e.key === 'Enter') {
                               if (!e.target.value) return;
                               dispatchFlightClearance({
                                   type: 'spd',
                                   value:e.target.value,
                                   flight: callsignMenuFlight.flight,
                                   index,
                                   startDate,
                               });
                               // callsignMenuFlight.flight.flight[index].sS = e.target.value;
                               // callsignMenuFlight.flight.flight[index].isOS = true;
                               // calcFlight(callsignMenuFlight.flight, index, startDate.getTime());
                               showFlightMenu(null);
                           }
                       }}/>
            </div>}
        </>
    );
}

export default SpdMenu;
