import * as geolib from "geolib";

export const airports = [{
    "ICAOcode": "UKLL",
    Runways: {
        '31': {
            heading: 310,
            threshold: {
                latitude: 49.799906,
                longitude: 23.973181
            },
            elevation: 1077,
            approachArea: [
                {
                    latitude: 49.799906,
                    longitude: 23.973181
                },
                geolib.computeDestinationPoint({
                    latitude: 49.799906,
                    longitude: 23.973181
                }, 18 * 1.852 * 1000, (310 + 180 + 5 + 6) % 360),
                geolib.computeDestinationPoint({
                    latitude: 49.799906,
                    longitude: 23.973181
                }, 18 * 1.852 * 1000, (310 + 180 - 5 + 6) % 360),
                {
                    latitude: 49.799906,
                    longitude: 23.973181
                }
            ]
        },
        '13': {
            heading: 130,
            threshold: {
                latitude: 49.817781,
                longitude: 23.946283
            },
            elevation: 1071,
            approachArea: [
                {
                    latitude: 49.817781,
                    longitude: 23.946283
                },
                geolib.computeDestinationPoint({
                    latitude: 49.817781,
                    longitude: 23.946283
                }, 18 * 1.852 * 1000, (130 + 180 + 5 + 6) % 360),
                geolib.computeDestinationPoint({
                    latitude: 49.817781,
                    longitude: 23.946283
                }, 18 * 1.852 * 1000, (130 + 180 - 5 + 6) % 360),
                {
                    latitude: 49.817781,
                    longitude: 23.946283
                }
            ]
        }
    },
    "Sid": [
        {
            "Name": "ADBA1F",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LL401",
                    "Type": "Normal",
                    "Latitude": "49.735778",
                    "Longitude": "24.069444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LLE01",
                    "Type": "Normal",
                    "Latitude": "49.803889",
                    "Longitude": "24.903056",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "ADBAN",
                    "Type": "Normal",
                    "Latitude": "49.802222",
                    "Longitude": "25.391111",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ADBA1G",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL203",
                    "Type": "Normal",
                    "Latitude": "49.968028",
                    "Longitude": "23.899250",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Right"
                },
                {
                    "ID": "3",
                    "Name": "LLN01",
                    "Type": "Normal",
                    "Latitude": "49.932278",
                    "Longitude": "24.237028",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LLE01",
                    "Type": "Normal",
                    "Latitude": "49.803889",
                    "Longitude": "24.903056",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "ADBAN",
                    "Type": "Normal",
                    "Latitude": "49.802222",
                    "Longitude": "25.391111",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ADBA2B",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "006",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "6070",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Left"
                },
                {
                    "ID": "3",
                    "Name": "ADBAN",
                    "Type": "Normal",
                    "Latitude": "49.802222",
                    "Longitude": "25.391111",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ADBA3A",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "130",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "(INTC)",
                    "Type": "Intc",
                    "Latitude": "49.802222",
                    "Longitude": "25.391111",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "020",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "RadialtoIntercept": "264",
                    "Sp_Turn": "Left"
                },
                {
                    "ID": "3",
                    "Name": "ADBAN",
                    "Type": "Normal",
                    "Latitude": "49.802222",
                    "Longitude": "25.391111",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "DIBE1F",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LL401",
                    "Type": "Normal",
                    "Latitude": "49.735778",
                    "Longitude": "24.069444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL403",
                    "Type": "Normal",
                    "Latitude": "49.677361",
                    "Longitude": "23.976694",
                    "Speed": "0",
                    "Altitude": "3940",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LLW01",
                    "Type": "Normal",
                    "Latitude": "49.737639",
                    "Longitude": "23.672333",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "DIBED",
                    "Type": "Normal",
                    "Latitude": "49.888333",
                    "Longitude": "23.058333",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "DIBE1G",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL201",
                    "Type": "Normal",
                    "Latitude": "49.904278",
                    "Longitude": "23.785139",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL205",
                    "Type": "Normal",
                    "Latitude": "49.901778",
                    "Longitude": "23.455750",
                    "Speed": "0",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "DIBED",
                    "Type": "Normal",
                    "Latitude": "49.888333",
                    "Longitude": "23.058333",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "DIBE7A",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "130",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "6070",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Left"
                },
                {
                    "ID": "3",
                    "Name": "DIBED",
                    "Type": "Normal",
                    "Latitude": "49.888333",
                    "Longitude": "23.058333",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "DIBE8B",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "006",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "(INTC)",
                    "Type": "Intc",
                    "Latitude": "49.888333",
                    "Longitude": "23.058333",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "224",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "RadialtoIntercept": "092",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "DIBED",
                    "Type": "Normal",
                    "Latitude": "49.888333",
                    "Longitude": "23.058333",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GIDN1F",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LL401",
                    "Type": "Normal",
                    "Latitude": "49.735778",
                    "Longitude": "24.069444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LLE01",
                    "Type": "Normal",
                    "Latitude": "49.803889",
                    "Longitude": "24.903056",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "GIDNO",
                    "Type": "Normal",
                    "Latitude": "50.121389",
                    "Longitude": "26.037778",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GIDN1G",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL203",
                    "Type": "Normal",
                    "Latitude": "49.968028",
                    "Longitude": "23.899250",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Right"
                },
                {
                    "ID": "3",
                    "Name": "LLN01",
                    "Type": "Normal",
                    "Latitude": "49.932278",
                    "Longitude": "24.237028",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LLE01",
                    "Type": "Normal",
                    "Latitude": "49.803889",
                    "Longitude": "24.903056",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "GIDNO",
                    "Type": "Normal",
                    "Latitude": "50.121389",
                    "Longitude": "26.037778",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GIDN2B",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "006",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "6070",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Left"
                },
                {
                    "ID": "3",
                    "Name": "GIDNO",
                    "Type": "Normal",
                    "Latitude": "50.121389",
                    "Longitude": "26.037778",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GIDN3A",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "130",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "(INTC)",
                    "Type": "Intc",
                    "Latitude": "50.121389",
                    "Longitude": "26.037778",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "353",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "RadialtoIntercept": "250",
                    "Sp_Turn": "Left"
                },
                {
                    "ID": "3",
                    "Name": "GIDNO",
                    "Type": "Normal",
                    "Latitude": "50.121389",
                    "Longitude": "26.037778",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GORK1F",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LL401",
                    "Type": "Normal",
                    "Latitude": "49.735778",
                    "Longitude": "24.069444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "GORKU",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "25.072222",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GORK1G",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL203",
                    "Type": "Normal",
                    "Latitude": "49.968028",
                    "Longitude": "23.899250",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Right"
                },
                {
                    "ID": "3",
                    "Name": "LLN01",
                    "Type": "Normal",
                    "Latitude": "49.932278",
                    "Longitude": "24.237028",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LLE01",
                    "Type": "Normal",
                    "Latitude": "49.803889",
                    "Longitude": "24.903056",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "GORKU",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "25.072222",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GORK2B",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "006",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "6070",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Left"
                },
                {
                    "ID": "3",
                    "Name": "GORKU",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "25.072222",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GORK3A",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "130",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "(INTC)",
                    "Type": "Intc",
                    "Latitude": "49.605278",
                    "Longitude": "25.072222",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Hdg_Crs": "1",
                    "Hdg_Crs_value": "069",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "RadialtoIntercept": "279",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "GORKU",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "25.072222",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GOTI2F",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LL401",
                    "Type": "Normal",
                    "Latitude": "49.735778",
                    "Longitude": "24.069444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL403",
                    "Type": "Normal",
                    "Latitude": "49.677361",
                    "Longitude": "23.976694",
                    "Speed": "0",
                    "Altitude": "3940",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LLW01",
                    "Type": "Normal",
                    "Latitude": "49.737639",
                    "Longitude": "23.672333",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "GOTIX",
                    "Type": "Normal",
                    "Latitude": "50.021667",
                    "Longitude": "23.211944",
                    "Speed": "0",
                    "Altitude": "14000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GOTI2G",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL201",
                    "Type": "Normal",
                    "Latitude": "49.904278",
                    "Longitude": "23.785139",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL205",
                    "Type": "Normal",
                    "Latitude": "49.901778",
                    "Longitude": "23.455750",
                    "Speed": "0",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "GOTIX",
                    "Type": "Normal",
                    "Latitude": "50.021667",
                    "Longitude": "23.211944",
                    "Speed": "0",
                    "Altitude": "14000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GOTI5A",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "130",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "6070",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Left"
                },
                {
                    "ID": "3",
                    "Name": "GOTIX",
                    "Type": "Normal",
                    "Latitude": "50.021667",
                    "Longitude": "23.211944",
                    "Speed": "0",
                    "Altitude": "14000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GOTI5B",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "006",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "(INTC)",
                    "Type": "Intc",
                    "Latitude": "50.021667",
                    "Longitude": "23.211944",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Hdg_Crs": "1",
                    "Hdg_Crs_value": "258",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "RadialtoIntercept": "108",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "GOTIX",
                    "Type": "Normal",
                    "Latitude": "50.021667",
                    "Longitude": "23.211944",
                    "Speed": "0",
                    "Altitude": "14000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOKU1F",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LL401",
                    "Type": "Normal",
                    "Latitude": "49.735778",
                    "Longitude": "24.069444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL403",
                    "Type": "Normal",
                    "Latitude": "49.677361",
                    "Longitude": "23.976694",
                    "Speed": "0",
                    "Altitude": "3940",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOKU1G",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL204",
                    "Type": "Normal",
                    "Latitude": "49.863583",
                    "Longitude": "23.715056",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOKU4A",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "130",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "6070",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Left"
                },
                {
                    "ID": "3",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOKU4B",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "006",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "6070",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Right"
                },
                {
                    "ID": "3",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOVU1F",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LL401",
                    "Type": "Normal",
                    "Latitude": "49.735778",
                    "Longitude": "24.069444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL402",
                    "Type": "Normal",
                    "Latitude": "49.794111",
                    "Longitude": "24.162389",
                    "Speed": "0",
                    "Altitude": "3940",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Left"
                },
                {
                    "ID": "3",
                    "Name": "LLE02",
                    "Type": "Normal",
                    "Latitude": "49.927667",
                    "Longitude": "24.218111",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOVU1G",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL202",
                    "Type": "Normal",
                    "Latitude": "49.959944",
                    "Longitude": "23.846167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOVU2A",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "130",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "6070",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Right"
                },
                {
                    "ID": "3",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOVU3B",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "006",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "(INTC)",
                    "Type": "Intc",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "101",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "RadialtoIntercept": "203",
                    "Sp_Turn": "Right"
                },
                {
                    "ID": "3",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KUSA1F",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LL401",
                    "Type": "Normal",
                    "Latitude": "49.735778",
                    "Longitude": "24.069444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "KUSAK",
                    "Type": "Normal",
                    "Latitude": "49.345000",
                    "Longitude": "24.501667",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KUSA1G",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL204",
                    "Type": "Normal",
                    "Latitude": "49.863583",
                    "Longitude": "23.715056",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "KUSAK",
                    "Type": "Normal",
                    "Latitude": "49.345000",
                    "Longitude": "24.501667",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KUSA7B",
            "Runways": "31",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "006",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "6070",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Right"
                },
                {
                    "ID": "3",
                    "Name": "KUSAK",
                    "Type": "Normal",
                    "Latitude": "49.345000",
                    "Longitude": "24.501667",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KUSA8A",
            "Runways": "13",
            "Sid_Waypoint": [
                {
                    "ID": "1",
                    "Name": "LIV",
                    "Type": "DmeIntc",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "3450",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "130",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "DMEtoIntercept": "007",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "KUSAK",
                    "Type": "Normal",
                    "Latitude": "49.345000",
                    "Longitude": "24.501667",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        }
    ],
    "Star": [
        {
            "Name": "ADBA1H",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "ADBAN",
                    "Type": "Normal",
                    "Latitude": "49.802222",
                    "Longitude": "25.391111",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LLE01",
                    "Type": "Normal",
                    "Latitude": "49.803889",
                    "Longitude": "24.903056",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LLE02",
                    "Type": "Normal",
                    "Latitude": "49.927667",
                    "Longitude": "24.218111",
                    "Speed": "220",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL304",
                    "Type": "Normal",
                    "Latitude": "49.988944",
                    "Longitude": "23.869083",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL302",
                    "Type": "Normal",
                    "Latitude": "49.930361",
                    "Longitude": "23.776194",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "CI13",
                    "Type": "Normal",
                    "Latitude": "49.891894",
                    "Longitude": "23.834889",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ADBA1J",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "ADBAN",
                    "Type": "Normal",
                    "Latitude": "49.802222",
                    "Longitude": "25.391111",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LLE01",
                    "Type": "Normal",
                    "Latitude": "49.803889",
                    "Longitude": "24.903056",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL115",
                    "Type": "Normal",
                    "Latitude": "49.817611",
                    "Longitude": "24.307306",
                    "Speed": "220",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL114",
                    "Type": "Normal",
                    "Latitude": "49.865611",
                    "Longitude": "24.235639",
                    "Speed": "0",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL113",
                    "Type": "Normal",
                    "Latitude": "49.807306",
                    "Longitude": "24.142583",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "LL112",
                    "Type": "Normal",
                    "Latitude": "49.759389",
                    "Longitude": "24.214222",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "7",
                    "Name": "LL111",
                    "Type": "Normal",
                    "Latitude": "49.711444",
                    "Longitude": "24.285694",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "8",
                    "Name": "LL110",
                    "Type": "Normal",
                    "Latitude": "49.663444",
                    "Longitude": "24.357056",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "9",
                    "Name": "LL104",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "24.264167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "10",
                    "Name": "LL103",
                    "Type": "Normal",
                    "Latitude": "49.665389",
                    "Longitude": "24.174444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "11",
                    "Name": "CI31",
                    "Type": "Normal",
                    "Latitude": "49.707564",
                    "Longitude": "24.111789",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ADBA2C",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "ADBAN",
                    "Type": "Normal",
                    "Latitude": "49.802222",
                    "Longitude": "25.391111",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ADBA2D",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "ADBAN",
                    "Type": "Normal",
                    "Latitude": "49.802222",
                    "Longitude": "25.391111",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "DIBE1J",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "DIBED",
                    "Type": "Normal",
                    "Latitude": "49.888333",
                    "Longitude": "23.058333",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL109",
                    "Type": "Normal",
                    "Latitude": "49.632000",
                    "Longitude": "23.864611",
                    "Speed": "220",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL108",
                    "Type": "Normal",
                    "Latitude": "49.690500",
                    "Longitude": "23.957028",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL107",
                    "Type": "Normal",
                    "Latitude": "49.642722",
                    "Longitude": "24.028667",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "LL106",
                    "Type": "Normal",
                    "Latitude": "49.594889",
                    "Longitude": "24.100167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "7",
                    "Name": "LL105",
                    "Type": "Normal",
                    "Latitude": "49.547000",
                    "Longitude": "24.171528",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "8",
                    "Name": "LL104",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "24.264167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "9",
                    "Name": "LL103",
                    "Type": "Normal",
                    "Latitude": "49.665389",
                    "Longitude": "24.174444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "10",
                    "Name": "CI31",
                    "Type": "Normal",
                    "Latitude": "49.707564",
                    "Longitude": "24.111789",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "DIBE2H",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "DIBED",
                    "Type": "Normal",
                    "Latitude": "49.888333",
                    "Longitude": "23.058333",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LLW01",
                    "Type": "Normal",
                    "Latitude": "49.737639",
                    "Longitude": "23.672333",
                    "Speed": "220",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL303",
                    "Type": "Normal",
                    "Latitude": "49.871722",
                    "Longitude": "23.683500",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL302",
                    "Type": "Normal",
                    "Latitude": "49.930361",
                    "Longitude": "23.776194",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "CI13",
                    "Type": "Normal",
                    "Latitude": "49.891894",
                    "Longitude": "23.834889",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "DIBE7C",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "DIBED",
                    "Type": "Normal",
                    "Latitude": "49.888333",
                    "Longitude": "23.058333",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "DIBE7D",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "DIBED",
                    "Type": "Normal",
                    "Latitude": "49.888333",
                    "Longitude": "23.058333",
                    "Speed": "0",
                    "Altitude": "9030",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GORK1H",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "GORKU",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "25.072222",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LLE01",
                    "Type": "Normal",
                    "Latitude": "49.803889",
                    "Longitude": "24.903056",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LLE02",
                    "Type": "Normal",
                    "Latitude": "49.927667",
                    "Longitude": "24.218111",
                    "Speed": "220",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL304",
                    "Type": "Normal",
                    "Latitude": "49.988944",
                    "Longitude": "23.869083",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL302",
                    "Type": "Normal",
                    "Latitude": "49.930361",
                    "Longitude": "23.776194",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "CI13",
                    "Type": "Normal",
                    "Latitude": "49.891894",
                    "Longitude": "23.834889",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GORK1J",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "GORKU",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "25.072222",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL115",
                    "Type": "Normal",
                    "Latitude": "49.817611",
                    "Longitude": "24.307306",
                    "Speed": "220",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL114",
                    "Type": "Normal",
                    "Latitude": "49.865611",
                    "Longitude": "24.235639",
                    "Speed": "0",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL113",
                    "Type": "Normal",
                    "Latitude": "49.807306",
                    "Longitude": "24.142583",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL112",
                    "Type": "Normal",
                    "Latitude": "49.759389",
                    "Longitude": "24.214222",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "LL111",
                    "Type": "Normal",
                    "Latitude": "49.711444",
                    "Longitude": "24.285694",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "7",
                    "Name": "LL110",
                    "Type": "Normal",
                    "Latitude": "49.663444",
                    "Longitude": "24.357056",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "8",
                    "Name": "LL104",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "24.264167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "9",
                    "Name": "LL103",
                    "Type": "Normal",
                    "Latitude": "49.665389",
                    "Longitude": "24.174444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "10",
                    "Name": "CI31",
                    "Type": "Normal",
                    "Latitude": "49.707564",
                    "Longitude": "24.111789",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GORK2C",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "GORKU",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "25.072222",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "GORK2D",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "GORKU",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "25.072222",
                    "Speed": "0",
                    "Altitude": "10010",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },

        {
            "Name": "KOKU1J",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL109",
                    "Type": "Normal",
                    "Latitude": "49.632000",
                    "Longitude": "23.864611",
                    "Speed": "220",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL108",
                    "Type": "Normal",
                    "Latitude": "49.690500",
                    "Longitude": "23.957028",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL107",
                    "Type": "Normal",
                    "Latitude": "49.642722",
                    "Longitude": "24.028667",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL106",
                    "Type": "Normal",
                    "Latitude": "49.594889",
                    "Longitude": "24.100167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "LL105",
                    "Type": "Normal",
                    "Latitude": "49.547000",
                    "Longitude": "24.171528",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "7",
                    "Name": "LL104",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "24.264167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "8",
                    "Name": "LL103",
                    "Type": "Normal",
                    "Latitude": "49.665389",
                    "Longitude": "24.174444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "9",
                    "Name": "CI31",
                    "Type": "Normal",
                    "Latitude": "49.707564",
                    "Longitude": "24.111789",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOKU2H",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LLW01",
                    "Type": "Normal",
                    "Latitude": "49.737639",
                    "Longitude": "23.672333",
                    "Speed": "220",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL303",
                    "Type": "Normal",
                    "Latitude": "49.871722",
                    "Longitude": "23.683500",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL302",
                    "Type": "Normal",
                    "Latitude": "49.930361",
                    "Longitude": "23.776194",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "CI13",
                    "Type": "Normal",
                    "Latitude": "49.891894",
                    "Longitude": "23.834889",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOKU1D",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOKU4C",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },

        {
            "Name": "KOVU1H",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "220",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL304",
                    "Type": "Normal",
                    "Latitude": "49.988944",
                    "Longitude": "23.869083",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL302",
                    "Type": "Normal",
                    "Latitude": "49.930361",
                    "Longitude": "23.776194",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "CI13",
                    "Type": "Normal",
                    "Latitude": "49.891894",
                    "Longitude": "23.834889",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOVU1J",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LLN01",
                    "Type": "Normal",
                    "Latitude": "49.932278",
                    "Longitude": "24.237028",
                    "Speed": "220",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL114",
                    "Type": "Normal",
                    "Latitude": "49.865611",
                    "Longitude": "24.235639",
                    "Speed": "0",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL113",
                    "Type": "Normal",
                    "Latitude": "49.807306",
                    "Longitude": "24.142583",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL112",
                    "Type": "Normal",
                    "Latitude": "49.759389",
                    "Longitude": "24.214222",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "LL111",
                    "Type": "Normal",
                    "Latitude": "49.711444",
                    "Longitude": "24.285694",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "7",
                    "Name": "LL110",
                    "Type": "Normal",
                    "Latitude": "49.663444",
                    "Longitude": "24.357056",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "8",
                    "Name": "LL104",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "24.264167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "9",
                    "Name": "LL103",
                    "Type": "Normal",
                    "Latitude": "49.665389",
                    "Longitude": "24.174444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "10",
                    "Name": "CI31",
                    "Type": "Normal",
                    "Latitude": "49.707564",
                    "Longitude": "24.111789",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOVU1D",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KOVU4C",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KUSA1J",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KUSAK",
                    "Type": "Normal",
                    "Latitude": "49.345000",
                    "Longitude": "24.501667",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL116",
                    "Type": "Normal",
                    "Latitude": "49.584222",
                    "Longitude": "23.936306",
                    "Speed": "220",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL109",
                    "Type": "Normal",
                    "Latitude": "49.632000",
                    "Longitude": "23.864611",
                    "Speed": "0",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL108",
                    "Type": "Normal",
                    "Latitude": "49.690500",
                    "Longitude": "23.957028",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL107",
                    "Type": "Normal",
                    "Latitude": "49.642722",
                    "Longitude": "24.028667",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "LL106",
                    "Type": "Normal",
                    "Latitude": "49.594889",
                    "Longitude": "24.100167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "7",
                    "Name": "LL105",
                    "Type": "Normal",
                    "Latitude": "49.547000",
                    "Longitude": "24.171528",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "8",
                    "Name": "LL104",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "24.264167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "9",
                    "Name": "LL103",
                    "Type": "Normal",
                    "Latitude": "49.665389",
                    "Longitude": "24.174444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "10",
                    "Name": "CI31",
                    "Type": "Normal",
                    "Latitude": "49.707564",
                    "Longitude": "24.111789",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KUSA2H",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KUSAK",
                    "Type": "Normal",
                    "Latitude": "49.345000",
                    "Longitude": "24.501667",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LLW01",
                    "Type": "Normal",
                    "Latitude": "49.737639",
                    "Longitude": "23.672333",
                    "Speed": "220",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL303",
                    "Type": "Normal",
                    "Latitude": "49.871722",
                    "Longitude": "23.683500",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL302",
                    "Type": "Normal",
                    "Latitude": "49.930361",
                    "Longitude": "23.776194",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "CI13",
                    "Type": "Normal",
                    "Latitude": "49.891894",
                    "Longitude": "23.834889",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KUSA7C",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KUSAK",
                    "Type": "Normal",
                    "Latitude": "49.345000",
                    "Longitude": "24.501667",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "KUSA7D",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "KUSAK",
                    "Type": "Normal",
                    "Latitude": "49.345000",
                    "Longitude": "24.501667",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ROLK1H",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "ROLKA",
                    "Type": "Normal",
                    "Latitude": "50.404167",
                    "Longitude": "23.825556",
                    "Speed": "0",
                    "Altitude": "21000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "220",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL304",
                    "Type": "Normal",
                    "Latitude": "49.988944",
                    "Longitude": "23.869083",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL302",
                    "Type": "Normal",
                    "Latitude": "49.930361",
                    "Longitude": "23.776194",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "CI13",
                    "Type": "Normal",
                    "Latitude": "49.891894",
                    "Longitude": "23.834889",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ROLK1J",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "ROLKA",
                    "Type": "Normal",
                    "Latitude": "50.404167",
                    "Longitude": "23.825556",
                    "Speed": "0",
                    "Altitude": "21000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "KOVUS",
                    "Type": "Normal",
                    "Latitude": "50.142222",
                    "Longitude": "24.241389",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LLN01",
                    "Type": "Normal",
                    "Latitude": "49.932278",
                    "Longitude": "24.237028",
                    "Speed": "220",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL114",
                    "Type": "Normal",
                    "Latitude": "49.865611",
                    "Longitude": "24.235639",
                    "Speed": "0",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL113",
                    "Type": "Normal",
                    "Latitude": "49.807306",
                    "Longitude": "24.142583",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "LL112",
                    "Type": "Normal",
                    "Latitude": "49.759389",
                    "Longitude": "24.214222",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "7",
                    "Name": "LL111",
                    "Type": "Normal",
                    "Latitude": "49.711444",
                    "Longitude": "24.285694",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "8",
                    "Name": "LL110",
                    "Type": "Normal",
                    "Latitude": "49.663444",
                    "Longitude": "24.357056",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "9",
                    "Name": "LL104",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "24.264167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "10",
                    "Name": "LL103",
                    "Type": "Normal",
                    "Latitude": "49.665389",
                    "Longitude": "24.174444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "11",
                    "Name": "CI31",
                    "Type": "Normal",
                    "Latitude": "49.707564",
                    "Longitude": "24.111789",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ROLK2C",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "ROLKA",
                    "Type": "Normal",
                    "Latitude": "50.404167",
                    "Longitude": "23.825556",
                    "Speed": "0",
                    "Altitude": "21000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "ROLK2D",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "ROLKA",
                    "Type": "Normal",
                    "Latitude": "50.404167",
                    "Longitude": "23.825556",
                    "Speed": "0",
                    "Altitude": "21000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "TEPN1J",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "TEPNA",
                    "Type": "Normal",
                    "Latitude": "49.504722",
                    "Longitude": "22.656111",
                    "Speed": "0",
                    "Altitude": "15000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LL109",
                    "Type": "Normal",
                    "Latitude": "49.632000",
                    "Longitude": "23.864611",
                    "Speed": "220",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL108",
                    "Type": "Normal",
                    "Latitude": "49.690500",
                    "Longitude": "23.957028",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL107",
                    "Type": "Normal",
                    "Latitude": "49.642722",
                    "Longitude": "24.028667",
                    "Speed": "0",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "LL106",
                    "Type": "Normal",
                    "Latitude": "49.594889",
                    "Longitude": "24.100167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "7",
                    "Name": "LL105",
                    "Type": "Normal",
                    "Latitude": "49.547000",
                    "Longitude": "24.171528",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "8",
                    "Name": "LL104",
                    "Type": "Normal",
                    "Latitude": "49.605278",
                    "Longitude": "24.264167",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "9",
                    "Name": "LL103",
                    "Type": "Normal",
                    "Latitude": "49.665389",
                    "Longitude": "24.174444",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "10",
                    "Name": "CI31",
                    "Type": "Normal",
                    "Latitude": "49.707564",
                    "Longitude": "24.111789",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "TEPN2H",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "TEPNA",
                    "Type": "Normal",
                    "Latitude": "49.504722",
                    "Longitude": "22.656111",
                    "Speed": "0",
                    "Altitude": "15000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "KOKUP",
                    "Type": "Normal",
                    "Latitude": "49.528333",
                    "Longitude": "23.655000",
                    "Speed": "0",
                    "Altitude": "8040",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LLW01",
                    "Type": "Normal",
                    "Latitude": "49.737639",
                    "Longitude": "23.672333",
                    "Speed": "220",
                    "Altitude": "4930",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "LL303",
                    "Type": "Normal",
                    "Latitude": "49.871722",
                    "Longitude": "23.683500",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LL302",
                    "Type": "Normal",
                    "Latitude": "49.930361",
                    "Longitude": "23.776194",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "6",
                    "Name": "CI13",
                    "Type": "Normal",
                    "Latitude": "49.891894",
                    "Longitude": "23.834889",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "TEPN2C",
            "Runways": "13",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "TEPNA",
                    "Type": "Normal",
                    "Latitude": "49.504722",
                    "Longitude": "22.656111",
                    "Speed": "0",
                    "Altitude": "15000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "D244Y",
                    "Type": "Normal",
                    "Latitude": "49.672025",
                    "Longitude": "23.351856",
                    "Speed": "0",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        },
        {
            "Name": "TEPN2D",
            "Runways": "31",
            "Star_Waypoint": [
                {
                    "ID": "1",
                    "Name": "TEPNA",
                    "Type": "Normal",
                    "Latitude": "49.504722",
                    "Longitude": "22.656111",
                    "Speed": "0",
                    "Altitude": "15000",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "D244Y",
                    "Type": "Normal",
                    "Latitude": "49.672025",
                    "Longitude": "23.351856",
                    "Speed": "0",
                    "Altitude": "7060",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                }
            ]
        }

    ],
    "Approach": [
        {
            "Name": "VDM13",
            "App_Waypoint": [
                {
                    "ID": "1",
                    "Name": "FD13",
                    "Type": "Normal",
                    "Latitude": "49.871944",
                    "Longitude": "23.876111",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "MD13",
                    "Type": "Normal",
                    "Latitude": "49.826564",
                    "Longitude": "23.933083",
                    "Speed": "0",
                    "Altitude": "1357",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "RW13",
                    "Type": "Runway",
                    "Latitude": "49.817781",
                    "Longitude": "23.946283",
                    "Speed": "0",
                    "Altitude": "1132",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "(2960)",
                    "Type": "ConstHdgtoAlt",
                    "Latitude": "0.000000",
                    "Longitude": "0.000000",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "135",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Right"
                }
            ],
            "App_Transition": [
                {
                    "Name": "LIV1",
                    "AppTr_Waypoint": [
                        {
                            "ID": "1",
                            "Name": "LIV",
                            "Type": "Normal",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2960",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "Sp_Turn": "Auto"
                        },
                        {
                            "ID": "2",
                            "Name": "LIV",
                            "Type": "DmeIntc",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2630",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Hdg_Crs": "0",
                            "Hdg_Crs_value": "276",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "DMEtoIntercept": "7.0",
                            "Sp_Turn": "Auto"
                        }
                    ]
                },
                {
                    "Name": "LIV2",
                    "AppTr_Waypoint": [
                        {
                            "ID": "1",
                            "Name": "LIV",
                            "Type": "Normal",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2960",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "Sp_Turn": "Auto"
                        },
                        {
                            "ID": "2",
                            "Name": "LIV",
                            "Type": "DmeIntc",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2630",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Hdg_Crs": "0",
                            "Hdg_Crs_value": "300",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "DMEtoIntercept": "6.0",
                            "Sp_Turn": "Auto"
                        }
                    ]
                }
            ]
        },
        {
            "Name": "VDM31",
            "App_Waypoint": [
                {
                    "ID": "1",
                    "Name": "FD31",
                    "Type": "Normal",
                    "Latitude": "49.733400",
                    "Longitude": "24.081214",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "MD31",
                    "Type": "Normal",
                    "Latitude": "49.790475",
                    "Longitude": "23.986939",
                    "Speed": "0",
                    "Altitude": "1373",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "RW31",
                    "Type": "Runway",
                    "Latitude": "49.799906",
                    "Longitude": "23.973181",
                    "Speed": "0",
                    "Altitude": "1127",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-over",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "(2960)",
                    "Type": "ConstHdgtoAlt",
                    "Latitude": "0.000000",
                    "Longitude": "0.000000",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "307",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Left"
                }
            ],
            "App_Transition": [
                {
                    "Name": "LIV1",
                    "AppTr_Waypoint": [
                        {
                            "ID": "1",
                            "Name": "LIV",
                            "Type": "Normal",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "0",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "at",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "Sp_Turn": "Auto"
                        },
                        {
                            "ID": "2",
                            "Name": "LIV",
                            "Type": "DmeIntc",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2960",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Hdg_Crs": "0",
                            "Hdg_Crs_value": "156",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "DMEtoIntercept": "9.0",
                            "Sp_Turn": "Auto"
                        }
                    ]
                },
                {
                    "Name": "LIV2",
                    "AppTr_Waypoint": [
                        {
                            "ID": "1",
                            "Name": "LIV",
                            "Type": "Normal",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "0",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "at",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "Sp_Turn": "Auto"
                        },
                        {
                            "ID": "2",
                            "Name": "LIV",
                            "Type": "DmeIntc",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2960",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Hdg_Crs": "0",
                            "Hdg_Crs_value": "138",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "DMEtoIntercept": "8.0",
                            "Sp_Turn": "Auto"
                        }
                    ]
                }
            ]
        },
        {
            "Name": "ILS13",
            "App_Waypoint": [
                {
                    "ID": "1",
                    "Name": "CI13",
                    "Type": "Normal",
                    "Latitude": "49.891894",
                    "Longitude": "23.834889",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL301",
                    "Type": "Normal",
                    "Latitude": "49.868056",
                    "Longitude": "23.870833",
                    "Speed": "0",
                    "Altitude": "2470",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "RW13",
                    "Type": "Runway",
                    "Latitude": "49.817781",
                    "Longitude": "23.946283",
                    "Speed": "0",
                    "Altitude": "1132",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "(2960)",
                    "Type": "ConstHdgtoAlt",
                    "Latitude": "0.000000",
                    "Longitude": "0.000000",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "130",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Right"
                }
            ],
            "App_Transition": [
                {
                    "Name": "LIV1",
                    "AppTr_Waypoint": [
                        {
                            "ID": "1",
                            "Name": "LIV",
                            "Type": "Normal",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "230",
                            "Altitude": "2960",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "Sp_Turn": "Auto"
                        },
                        {
                            "ID": "2",
                            "Name": "LIV",
                            "Type": "DmeIntc",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2630",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Hdg_Crs": "0",
                            "Hdg_Crs_value": "284",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "DMEtoIntercept": "8.0",
                            "Sp_Turn": "Auto"
                        }
                    ]
                },
                {
                    "Name": "LIV2",
                    "AppTr_Waypoint": [
                        {
                            "ID": "1",
                            "Name": "LIV",
                            "Type": "Normal",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "230",
                            "Altitude": "2960",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "Sp_Turn": "Auto"
                        },
                        {
                            "ID": "2",
                            "Name": "LIV",
                            "Type": "DmeIntc",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2630",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Hdg_Crs": "0",
                            "Hdg_Crs_value": "298",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "DMEtoIntercept": "8.0",
                            "Sp_Turn": "Auto"
                        }
                    ]
                }
            ]
        },
        {
            "Name": "ILS31",
            "App_Waypoint": [
                {
                    "ID": "1",
                    "Name": "CI31",
                    "Type": "Normal",
                    "Latitude": "49.707564",
                    "Longitude": "24.111789",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "2",
                    "Name": "LL102",
                    "Type": "Normal",
                    "Latitude": "49.731389",
                    "Longitude": "24.075833",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "3",
                    "Name": "RW31",
                    "Type": "Runway",
                    "Latitude": "49.799906",
                    "Longitude": "23.973181",
                    "Speed": "0",
                    "Altitude": "1127",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "4",
                    "Name": "(2960)",
                    "Type": "ConstHdgtoAlt",
                    "Latitude": "0.000000",
                    "Longitude": "0.000000",
                    "Speed": "0",
                    "Altitude": "2960",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "above",
                    "Hdg_Crs": "0",
                    "Hdg_Crs_value": "310",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Auto"
                },
                {
                    "ID": "5",
                    "Name": "LIV",
                    "Type": "Normal",
                    "Latitude": "49.811944",
                    "Longitude": "23.951389",
                    "Speed": "0",
                    "Altitude": "0",
                    "AltitudeCons": "0",
                    "AltitudeRestriction": "at",
                    "Flytype": "Fly-by",
                    "BankLimit": "25",
                    "Sp_Turn": "Left"
                }
            ],
            "App_Transition": [
                {
                    "Name": "LIV1",
                    "AppTr_Waypoint": [
                        {
                            "ID": "1",
                            "Name": "LIV",
                            "Type": "Normal",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2960",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "Sp_Turn": "Auto"
                        },
                        {
                            "ID": "2",
                            "Name": "LIV",
                            "Type": "DmeIntc",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "0",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "at",
                            "Hdg_Crs": "0",
                            "Hdg_Crs_value": "152",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "DMEtoIntercept": "11.0",
                            "Sp_Turn": "Auto"
                        }
                    ]
                },
                {
                    "Name": "LIV2",
                    "AppTr_Waypoint": [
                        {
                            "ID": "1",
                            "Name": "LIV",
                            "Type": "Normal",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "2960",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "Sp_Turn": "Auto"
                        },
                        {
                            "ID": "2",
                            "Name": "LIV",
                            "Type": "DmeIntc",
                            "Latitude": "49.811944",
                            "Longitude": "23.951389",
                            "Speed": "0",
                            "Altitude": "0",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "at",
                            "Hdg_Crs": "0",
                            "Hdg_Crs_value": "139",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "DMEtoIntercept": "9.0",
                            "Sp_Turn": "Auto"
                        }
                    ]
                }
            ]
        }
    ]
},
    {
        "ICAOcode": "UKLN",
        Runways: {
            '33': {
                heading: 328,
                threshold: {
                    latitude: 48.250617,
                    longitude: 25.986811
                },
                elevation: 827,
                approachArea: [
                    {
                        latitude: 48.250617,
                        longitude: 25.986811
                    },
                    geolib.computeDestinationPoint({
                        latitude: 48.250617,
                        longitude: 25.986811
                    }, 18 * 1.852 * 1000, (328 + 180 + 5 + 6) % 360),
                    geolib.computeDestinationPoint({
                        latitude: 49.799906,
                        longitude: 23.973181
                    }, 18 * 1.852 * 1000, (328 + 180 - 5 + 6) % 360),
                    {
                        latitude: 48.250617,
                        longitude: 25.986811
                    }
                ]
            },
            '15': {
                heading: 148,
                threshold: {
                    latitude: 48.268389,
                    longitude: 25.973303
                },
                elevation: 727,
                approachArea: [
                    {
                        latitude: 48.268389,
                    longitude: 25.973303
                    },
                    geolib.computeDestinationPoint({
                        latitude: 48.268389,
                    longitude: 25.973303
                    }, 18 * 1.852 * 1000, (148 + 180 + 5 + 6) % 360),
                    geolib.computeDestinationPoint({
                        latitude: 48.268389,
                    longitude: 25.973303
                    }, 18 * 1.852 * 1000, (148 + 180 - 5 + 6) % 360),
                    {
                        latitude: 48.268389,
                    longitude: 25.973303
                    }
                ]
            }
        },
        "Sid": [
            {
                "Name": "BUKO1T",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN101",
                        "Type": "Normal",
                        "Latitude": "48.188389",
                        "Longitude": "26.033889",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "BUKOV",
                        "Type": "Normal",
                        "Latitude": "47.951667",
                        "Longitude": "25.958333",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "BUKO1V",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN301",
                        "Type": "Normal",
                        "Latitude": "48.332556",
                        "Longitude": "25.924417",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNN10",
                        "Type": "Normal",
                        "Latitude": "48.371028",
                        "Longitude": "26.037750",
                        "Speed": "0",
                        "Altitude": "6070",
                        "AltitudeCons": "2960",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNS05",
                        "Type": "Normal",
                        "Latitude": "48.147000",
                        "Longitude": "26.205778",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "4",
                        "Name": "BUKOV",
                        "Type": "Normal",
                        "Latitude": "47.951667",
                        "Longitude": "25.958333",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "BUKO9A",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "148",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "4930",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Left"
                    },
                    {
                        "ID": "3",
                        "Name": "BUKOV",
                        "Type": "Normal",
                        "Latitude": "47.951667",
                        "Longitude": "25.958333",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "BUKO9B",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "328",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "4930",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Right"
                    },
                    {
                        "ID": "3",
                        "Name": "BUKOV",
                        "Type": "Normal",
                        "Latitude": "47.951667",
                        "Longitude": "25.958333",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "LOPN1T",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN101",
                        "Type": "Normal",
                        "Latitude": "48.188389",
                        "Longitude": "26.033889",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNS01",
                        "Type": "Normal",
                        "Latitude": "48.226417",
                        "Longitude": "26.146472",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "2960",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNE01",
                        "Type": "Normal",
                        "Latitude": "48.526556",
                        "Longitude": "26.214500",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "4",
                        "Name": "LOPNU",
                        "Type": "Normal",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "LOPN1V",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN301",
                        "Type": "Normal",
                        "Latitude": "48.332556",
                        "Longitude": "25.924417",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNN10",
                        "Type": "Normal",
                        "Latitude": "48.371028",
                        "Longitude": "26.037750",
                        "Speed": "0",
                        "Altitude": "6070",
                        "AltitudeCons": "2960",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LOPNU",
                        "Type": "Normal",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "LOPN6E",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "148",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "(INTC)",
                        "Type": "Intc",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "0",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "353",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "RadialtoIntercept": "204",
                        "Sp_Turn": "Left"
                    },
                    {
                        "ID": "3",
                        "Name": "LOPNU",
                        "Type": "Normal",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "LOPN6F",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "328",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "(INTC)",
                        "Type": "Intc",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "0",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "083",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "RadialtoIntercept": "204",
                        "Sp_Turn": "Right"
                    },
                    {
                        "ID": "3",
                        "Name": "LOPNU",
                        "Type": "Normal",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "POBE1T",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN101",
                        "Type": "Normal",
                        "Latitude": "48.188389",
                        "Longitude": "26.033889",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNS01",
                        "Type": "Normal",
                        "Latitude": "48.226417",
                        "Longitude": "26.146472",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "2960",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNN05",
                        "Type": "Normal",
                        "Latitude": "48.446306",
                        "Longitude": "25.978083",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "4",
                        "Name": "POBED",
                        "Type": "Normal",
                        "Latitude": "48.720556",
                        "Longitude": "25.457222",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "POBE1V",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN301",
                        "Type": "Normal",
                        "Latitude": "48.332556",
                        "Longitude": "25.924417",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "POBED",
                        "Type": "Normal",
                        "Latitude": "48.720556",
                        "Longitude": "25.457222",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "POBE4A",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "148",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "4930",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Left"
                    },
                    {
                        "ID": "3",
                        "Name": "POBED",
                        "Type": "Normal",
                        "Latitude": "48.720556",
                        "Longitude": "25.457222",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "POBE4B",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "328",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "POBED",
                        "Type": "Normal",
                        "Latitude": "48.720556",
                        "Longitude": "25.457222",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "RIMO1T",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN101",
                        "Type": "Normal",
                        "Latitude": "48.188389",
                        "Longitude": "26.033889",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNS01",
                        "Type": "Normal",
                        "Latitude": "48.226417",
                        "Longitude": "26.146472",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "2960",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNN05",
                        "Type": "Normal",
                        "Latitude": "48.446306",
                        "Longitude": "25.978083",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "4",
                        "Name": "LNN01",
                        "Type": "Normal",
                        "Latitude": "48.583528",
                        "Longitude": "25.978972",
                        "Speed": "0",
                        "Altitude": "8040",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "5",
                        "Name": "RIMOS",
                        "Type": "Normal",
                        "Latitude": "48.758333",
                        "Longitude": "25.980000",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "RIMO1V",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN301",
                        "Type": "Normal",
                        "Latitude": "48.332556",
                        "Longitude": "25.924417",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNN10",
                        "Type": "Normal",
                        "Latitude": "48.371028",
                        "Longitude": "26.037750",
                        "Speed": "0",
                        "Altitude": "6070",
                        "AltitudeCons": "2960",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "RIMOS",
                        "Type": "Normal",
                        "Latitude": "48.758333",
                        "Longitude": "25.980000",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "RIMO9A",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "148",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "4930",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Left"
                    },
                    {
                        "ID": "3",
                        "Name": "RIMOS",
                        "Type": "Normal",
                        "Latitude": "48.758333",
                        "Longitude": "25.980000",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "RIMO9B",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "328",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "(INTC)",
                        "Type": "Intc",
                        "Latitude": "48.758333",
                        "Longitude": "25.980000",
                        "Speed": "0",
                        "Altitude": "0",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "024",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "RadialtoIntercept": "174",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "RIMOS",
                        "Type": "Normal",
                        "Latitude": "48.758333",
                        "Longitude": "25.980000",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "TUMB1T",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN101",
                        "Type": "Normal",
                        "Latitude": "48.188389",
                        "Longitude": "26.033889",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNS01",
                        "Type": "Normal",
                        "Latitude": "48.226417",
                        "Longitude": "26.146472",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "2960",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "TUMBI",
                        "Type": "Normal",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "TUMB1V",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LN301",
                        "Type": "Normal",
                        "Latitude": "48.332556",
                        "Longitude": "25.924417",
                        "Speed": "0",
                        "Altitude": "1640",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNN10",
                        "Type": "Normal",
                        "Latitude": "48.371028",
                        "Longitude": "26.037750",
                        "Speed": "0",
                        "Altitude": "6070",
                        "AltitudeCons": "2960",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "TUMBI",
                        "Type": "Normal",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "TUMB6A",
                "Runways": "15",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "148",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "(INTC)",
                        "Type": "Intc",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "0",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "353",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "RadialtoIntercept": "232",
                        "Sp_Turn": "Left"
                    },
                    {
                        "ID": "3",
                        "Name": "TUMBI",
                        "Type": "Normal",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "TUMB6B",
                "Runways": "33",
                "Sid_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "328",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "(INTC)",
                        "Type": "Intc",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "0",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "142",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "RadialtoIntercept": "232",
                        "Sp_Turn": "Right"
                    },
                    {
                        "ID": "3",
                        "Name": "TUMBI",
                        "Type": "Normal",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            }
        ],
        "Star": [
            {
                "Name": "BUKO2J",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "BUKOV",
                        "Type": "Normal",
                        "Latitude": "47.951667",
                        "Longitude": "25.958333",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNS01",
                        "Type": "Normal",
                        "Latitude": "48.226417",
                        "Longitude": "26.146472",
                        "Speed": "0",
                        "Altitude": "8040",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNN05",
                        "Type": "Normal",
                        "Latitude": "48.446306",
                        "Longitude": "25.978083",
                        "Speed": "220",
                        "Altitude": "6070",
                        "AltitudeCons": "3940",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "BUKO2U",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "BUKOV",
                        "Type": "Normal",
                        "Latitude": "47.951667",
                        "Longitude": "25.958333",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LN405",
                        "Type": "Normal",
                        "Latitude": "48.109306",
                        "Longitude": "26.094111",
                        "Speed": "220",
                        "Altitude": "2960",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "BUKO9C",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "BUKOV",
                        "Type": "Normal",
                        "Latitude": "47.951667",
                        "Longitude": "25.958333",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "BUKO9D",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "BUKOV",
                        "Type": "Normal",
                        "Latitude": "47.951667",
                        "Longitude": "25.958333",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "LOPN2J",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LOPNU",
                        "Type": "Normal",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNE01",
                        "Type": "Normal",
                        "Latitude": "48.526556",
                        "Longitude": "26.214500",
                        "Speed": "0",
                        "Altitude": "6070",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNN05",
                        "Type": "Normal",
                        "Latitude": "48.446306",
                        "Longitude": "25.978083",
                        "Speed": "220",
                        "Altitude": "6070",
                        "AltitudeCons": "3940",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "LOPN2U",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LOPNU",
                        "Type": "Normal",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNN10",
                        "Type": "Normal",
                        "Latitude": "48.371028",
                        "Longitude": "26.037750",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNS05",
                        "Type": "Normal",
                        "Latitude": "48.147000",
                        "Longitude": "26.205778",
                        "Speed": "220",
                        "Altitude": "6070",
                        "AltitudeCons": "3940",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "LOPN6G",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LOPNU",
                        "Type": "Normal",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "LOPN6H",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "LOPNU",
                        "Type": "Normal",
                        "Latitude": "49.074167",
                        "Longitude": "26.681389",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "POBE2J",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "POBED",
                        "Type": "Normal",
                        "Latitude": "48.720556",
                        "Longitude": "25.457222",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LN215",
                        "Type": "Normal",
                        "Latitude": "48.467000",
                        "Longitude": "25.672222",
                        "Speed": "0",
                        "Altitude": "6070",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LN210",
                        "Type": "Normal",
                        "Latitude": "48.370333",
                        "Longitude": "25.754000",
                        "Speed": "220",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "POBE2U",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "POBED",
                        "Type": "Normal",
                        "Latitude": "48.720556",
                        "Longitude": "25.457222",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNN10",
                        "Type": "Normal",
                        "Latitude": "48.371028",
                        "Longitude": "26.037750",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNS05",
                        "Type": "Normal",
                        "Latitude": "48.147000",
                        "Longitude": "26.205778",
                        "Speed": "220",
                        "Altitude": "6070",
                        "AltitudeCons": "3940",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "POBE4C",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "POBED",
                        "Type": "Normal",
                        "Latitude": "48.720556",
                        "Longitude": "25.457222",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "POBE4D",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "POBED",
                        "Type": "Normal",
                        "Latitude": "48.720556",
                        "Longitude": "25.457222",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "RIMO2J",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "RIMOS",
                        "Type": "Normal",
                        "Latitude": "48.758333",
                        "Longitude": "25.980000",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNN01",
                        "Type": "Normal",
                        "Latitude": "48.583528",
                        "Longitude": "25.978972",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "6070",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNN05",
                        "Type": "Normal",
                        "Latitude": "48.446306",
                        "Longitude": "25.978083",
                        "Speed": "220",
                        "Altitude": "6070",
                        "AltitudeCons": "3940",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "RIMO2U",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "RIMOS",
                        "Type": "Normal",
                        "Latitude": "48.758333",
                        "Longitude": "25.980000",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNN10",
                        "Type": "Normal",
                        "Latitude": "48.371028",
                        "Longitude": "26.037750",
                        "Speed": "0",
                        "Altitude": "7060",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNS05",
                        "Type": "Normal",
                        "Latitude": "48.147000",
                        "Longitude": "26.205778",
                        "Speed": "220",
                        "Altitude": "6070",
                        "AltitudeCons": "3940",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "RIMO9C",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "RIMOS",
                        "Type": "Normal",
                        "Latitude": "48.758333",
                        "Longitude": "25.980000",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "RIMO9D",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "RIMOS",
                        "Type": "Normal",
                        "Latitude": "48.758333",
                        "Longitude": "25.980000",
                        "Speed": "0",
                        "Altitude": "9030",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "TUMB2J",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "TUMBI",
                        "Type": "Normal",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LNE01",
                        "Type": "Normal",
                        "Latitude": "48.526556",
                        "Longitude": "26.214500",
                        "Speed": "0",
                        "Altitude": "6070",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNN05",
                        "Type": "Normal",
                        "Latitude": "48.446306",
                        "Longitude": "25.978083",
                        "Speed": "220",
                        "Altitude": "6070",
                        "AltitudeCons": "3940",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "TUMB2U",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "TUMBI",
                        "Type": "Normal",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "LN410",
                        "Type": "Normal",
                        "Latitude": "48.314417",
                        "Longitude": "26.392083",
                        "Speed": "0",
                        "Altitude": "6070",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "LNS05",
                        "Type": "Normal",
                        "Latitude": "48.147000",
                        "Longitude": "26.205778",
                        "Speed": "220",
                        "Altitude": "6070",
                        "AltitudeCons": "3940",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "TUMB6C",
                "Runways": "15",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "TUMBI",
                        "Type": "Normal",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            },
            {
                "Name": "TUMB6D",
                "Runways": "33",
                "Star_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "TUMBI",
                        "Type": "Normal",
                        "Latitude": "48.502778",
                        "Longitude": "26.602222",
                        "Speed": "0",
                        "Altitude": "10010",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    }
                ]
            }
        ],
        "Approach": [
            {
                "Name": "ILS15",
                "App_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "CI15",
                        "Type": "Normal",
                        "Latitude": "48.365569",
                        "Longitude": "25.899328",
                        "Speed": "0",
                        "Altitude": "2300",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "FF15",
                        "Type": "Normal",
                        "Latitude": "48.336056",
                        "Longitude": "25.921139",
                        "Speed": "0",
                        "Altitude": "2300",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "RW15",
                        "Type": "Runway",
                        "Latitude": "48.268389",
                        "Longitude": "25.973303",
                        "Speed": "0",
                        "Altitude": "879",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "4",
                        "Name": "(1970)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "1970",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "148",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "5",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Left"
                    }
                ],
                "App_Transition": [
                    {
                        "Name": "CR1",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "CR",
                                "Type": "Normal",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "3940",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "CR",
                                "Type": "PBD",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "2790",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Hdg_Crs": "0",
                                "Hdg_Crs_value": "357",
                                "Flytype": "Fly-over",
                                "BankLimit": "25",
                                "DMEtoIntercept": "007",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    },
                    {
                        "Name": "CR2",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "CR",
                                "Type": "Normal",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "3940",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "CR",
                                "Type": "PBD",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "2630",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Hdg_Crs": "0",
                                "Hdg_Crs_value": "340",
                                "Flytype": "Fly-over",
                                "BankLimit": "25",
                                "DMEtoIntercept": "007",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    },
                    {
                        "Name": "LN210",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "LN210",
                                "Type": "Normal",
                                "Latitude": "48.370333",
                                "Longitude": "25.754000",
                                "Speed": "220",
                                "Altitude": "3940",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "LN205",
                                "Type": "Normal",
                                "Latitude": "48.408278",
                                "Longitude": "25.866056",
                                "Speed": "0",
                                "Altitude": "2960",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    },
                    {
                        "Name": "LNN05",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "LNN05",
                                "Type": "Normal",
                                "Latitude": "48.446306",
                                "Longitude": "25.978083",
                                "Speed": "220",
                                "Altitude": "6070",
                                "AltitudeCons": "3940",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "LN205",
                                "Type": "Normal",
                                "Latitude": "48.408278",
                                "Longitude": "25.866056",
                                "Speed": "0",
                                "Altitude": "2960",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    }
                ]
            },
            {
                "Name": "ILS33",
                "App_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "CI33",
                        "Type": "Normal",
                        "Latitude": "48.154803",
                        "Longitude": "26.059817",
                        "Speed": "0",
                        "Altitude": "2300",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "FF33",
                        "Type": "Normal",
                        "Latitude": "48.184028",
                        "Longitude": "26.037667",
                        "Speed": "0",
                        "Altitude": "2300",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "RW33",
                        "Type": "Runway",
                        "Latitude": "48.250617",
                        "Longitude": "25.986811",
                        "Speed": "0",
                        "Altitude": "881",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "4",
                        "Name": "(2470)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "2470",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "328",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "5",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Right"
                    }
                ],
                "App_Transition": [
                    {
                        "Name": "CR1",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "CR",
                                "Type": "Normal",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "3940",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "CR",
                                "Type": "PBD",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "2790",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Hdg_Crs": "0",
                                "Hdg_Crs_value": "110",
                                "Flytype": "Fly-over",
                                "BankLimit": "25",
                                "DMEtoIntercept": "005",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    },
                    {
                        "Name": "CR2",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "CR",
                                "Type": "Normal",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "3940",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "CR",
                                "Type": "PBD",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "2630",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Hdg_Crs": "0",
                                "Hdg_Crs_value": "134",
                                "Flytype": "Fly-over",
                                "BankLimit": "25",
                                "DMEtoIntercept": "006",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    },
                    {
                        "Name": "LN405",
                        "AppTr_Waypoint": {
                            "ID": "1",
                            "Name": "LN405",
                            "Type": "Normal",
                            "Latitude": "48.109306",
                            "Longitude": "26.094111",
                            "Speed": "220",
                            "Altitude": "2960",
                            "AltitudeCons": "0",
                            "AltitudeRestriction": "above",
                            "Flytype": "Fly-by",
                            "BankLimit": "25",
                            "Sp_Turn": "Auto"
                        }
                    },
                    {
                        "Name": "LNS05",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "LNS05",
                                "Type": "Normal",
                                "Latitude": "48.147000",
                                "Longitude": "26.205778",
                                "Speed": "220",
                                "Altitude": "6070",
                                "AltitudeCons": "3940",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "LN405",
                                "Type": "Normal",
                                "Latitude": "48.109306",
                                "Longitude": "26.094111",
                                "Speed": "0",
                                "Altitude": "2960",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    }
                ]
            },
            {
                "Name": "NDB15",
                "App_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "FF15",
                        "Type": "Normal",
                        "Latitude": "48.336056",
                        "Longitude": "25.921139",
                        "Speed": "0",
                        "Altitude": "2300",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "RW15",
                        "Type": "Runway",
                        "Latitude": "48.268389",
                        "Longitude": "25.973303",
                        "Speed": "0",
                        "Altitude": "879",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "0",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "4",
                        "Name": "(1970)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "1970",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "148",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "5",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Left"
                    }
                ],
                "App_Transition": [
                    {
                        "Name": "CR1",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "CR",
                                "Type": "Normal",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "3940",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "CR",
                                "Type": "PBD",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "2790",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Hdg_Crs": "0",
                                "Hdg_Crs_value": "357",
                                "Flytype": "Fly-over",
                                "BankLimit": "25",
                                "DMEtoIntercept": "007",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    },
                    {
                        "Name": "CR2",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "CR",
                                "Type": "Normal",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "3940",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "CR",
                                "Type": "PBD",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "2630",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Hdg_Crs": "0",
                                "Hdg_Crs_value": "340",
                                "Flytype": "Fly-over",
                                "BankLimit": "25",
                                "DMEtoIntercept": "007",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    }
                ]
            },
            {
                "Name": "NDB33",
                "App_Waypoint": [
                    {
                        "ID": "1",
                        "Name": "FF33",
                        "Type": "Normal",
                        "Latitude": "48.184028",
                        "Longitude": "26.037667",
                        "Speed": "0",
                        "Altitude": "2300",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "2",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "1082",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "3",
                        "Name": "RW33",
                        "Type": "Runway",
                        "Latitude": "48.250617",
                        "Longitude": "25.986811",
                        "Speed": "0",
                        "Altitude": "881",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-over",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "4",
                        "Name": "(3120)",
                        "Type": "ConstHdgtoAlt",
                        "Latitude": "0.000000",
                        "Longitude": "0.000000",
                        "Speed": "0",
                        "Altitude": "3120",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "above",
                        "Hdg_Crs": "0",
                        "Hdg_Crs_value": "328",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Auto"
                    },
                    {
                        "ID": "5",
                        "Name": "CR",
                        "Type": "Normal",
                        "Latitude": "48.241167",
                        "Longitude": "25.993778",
                        "Speed": "0",
                        "Altitude": "3940",
                        "AltitudeCons": "0",
                        "AltitudeRestriction": "at",
                        "Flytype": "Fly-by",
                        "BankLimit": "25",
                        "Sp_Turn": "Right"
                    }
                ],
                "App_Transition": [
                    {
                        "Name": "CR1",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "CR",
                                "Type": "Normal",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "3940",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "CR",
                                "Type": "PBD",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "2790",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Hdg_Crs": "0",
                                "Hdg_Crs_value": "110",
                                "Flytype": "Fly-over",
                                "BankLimit": "25",
                                "DMEtoIntercept": "005",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    },
                    {
                        "Name": "CR2",
                        "AppTr_Waypoint": [
                            {
                                "ID": "1",
                                "Name": "CR",
                                "Type": "Normal",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "3940",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Flytype": "Fly-by",
                                "BankLimit": "25",
                                "Sp_Turn": "Auto"
                            },
                            {
                                "ID": "2",
                                "Name": "CR",
                                "Type": "PBD",
                                "Latitude": "48.241167",
                                "Longitude": "25.993778",
                                "Speed": "0",
                                "Altitude": "2630",
                                "AltitudeCons": "0",
                                "AltitudeRestriction": "above",
                                "Hdg_Crs": "0",
                                "Hdg_Crs_value": "134",
                                "Flytype": "Fly-over",
                                "BankLimit": "25",
                                "DMEtoIntercept": "006",
                                "Sp_Turn": "Auto"
                            }
                        ]
                    }
                ]
            }
        ]
    }
    ];