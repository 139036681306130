/*
    node_js
    2/15/2021 11:16 PM
    by Oleksandr
*/
import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export default function LoadExerciseWindow(props) {
    const {open, onClose, files} = props;
    // useEffect(() => {
    //     console.log('files: ', files);
    // }, []);

    // useEffect(() => {
    //     console.log('Use effect files: ', files);
    // }, [files])
    return (
        <>
            <Dialog open={open} onClose={() => {
                onClose(null);
            }}>
                <DialogTitle id="lEWindow-title">Load exercise:</DialogTitle>
                <List>
                    {files?files.map((file) => (
                        <ListItem button key={'loadExerciseWindow_' + file.name} onClick={() => {
                            let atm_code = localStorage.getItem('atm_code');
                            atm_code = atm_code === 'undefined' ? 'CON' : atm_code;
                            let promptName = window.prompt('Exercise name: ' + atm_code + '_',
                                file.name.replace(atm_code + '_', ''));
                            if (promptName === null) return;
                            let searchParams = new URLSearchParams();
                            searchParams.set('action', 'rename');
                            searchParams.set('old_name', file.name);
                            searchParams.set('new_name', atm_code + '_' + promptName);
                            let requestOptions = {
                                method: 'POST',
                                mode: "cors",
                                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                                body: searchParams
                            };
                            fetch('https://lvivacc.site/ptt/exercise_2.php', requestOptions)
                                .then(response => response.json())
                                .then(data => {
                                    console.log('Success:', data);
                                    // setFiles(data);
                                    // return data;
                                })
                                .catch((error) => {
                                    console.error('Error:', error);
                                });
                            // onClose(file)
                        }}>
                            <ListItemText primary={file.name}/>
                        </ListItem>
                    )):''}
                </List>
            </Dialog>
        </>
    )
}