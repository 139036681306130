/*
    node_js
    5/15/2021 9:12 PM
    by Oleksandr
*/

import {calcApproach, calcFlight} from "../calc/calc";
import {useContext} from "react";
import {CwpContext} from "../contexts/CwpContext";
const getState = (state, index, flight, value, type) => {
    console.log("getState");
    return {
        ...state,
        [flight.id]: [...state[flight.id].filter(clearence => clearence[0] <= index), [
            index,
            flight.id,
            value,
            type
        ]]
    }
}

// const testDb = async (state) => {
//
//     const pool = createPool({
//         host: 'sql151.main-hosting.eu',
//         user:'u620800789_pttaero',
//         password: 'u5~Jk6yGc',
//         database: 'u620800789_pttaero',
//         connectionLimit: 5
//     });
//     const conn = await pool.getConnection();
//     const res = await conn.query("INSERT INTO exercisePlayback(exerciseData, playback) value (?,?)", ["exerciseData", state], (err,result) => {
//         if(err){console.log(err)}
//     });
//     console.log(res);
// }

const flightClearanceReducer = (state, action) => {

    console.log('payload', action, JSON.stringify(state));
    // testDb(JSON.stringify(state));
    const {type, value, flight, index, startDate} = action;
    if (flight && !state[flight.id]) state[flight.id] = [];
    switch (type) {
        case 'sfl':
            flight.flight[index].sA = value;
            calcFlight(flight, index, startDate.getTime());
            return getState(state, index, flight, value, type);
        case 'spd':
            flight.flight[index].sS = value;
            flight.flight[index].isOS = true;
            calcFlight(flight, index, startDate.getTime());
            return getState(state, index, flight, value, type);
        case 'mach':
            flight.flight[index].M = value;
            flight.flight[index].isOS = true;
            calcFlight(flight, index, startDate.getTime());
            return getState(state, index, flight, value, type);
        case 'std':
            flight.flight[index].isOS = false;
            calcFlight(flight, index, startDate.getTime());
            return getState(state, index, flight, value, type);
        case 'hdg':
            flight.flight[index].sH = parseInt(value);
            flight.flight[index].isOH = true;
            flight.flight[index].isOO = false;
            flight.flight[index].isOSt = false;
            calcFlight(flight, index, startDate.getTime());
            return getState(state, index, flight, value, type);
        case 'dct':
            let ronPointIndex = flight.fpl[10].map(e => e.name).indexOf(value);
            if (ronPointIndex >= 0) {
                flight.flight[index].nP = ronPointIndex;
                flight.flight[index].isOH = false;
            }
            calcFlight(flight, index, startDate.getTime());
            return getState(state, index, flight, value, type);
        case 'ga':
            flight.flight[index].isGA = true;
            calcApproach(flight, value);
            calcFlight(flight, index, startDate.getTime());
            return getState(state, index, flight, value, type);
        case 'ukll':
            flight.ades = 'UKLL';
            flight.fpl[10].push({
                isAdes: true,
                latitude: 49.809722,
                longitude: 23.958333,
                name: 'UKLL'
            });
            flight.flight[index].nP = flight.fpl[10].length - 1;
            calcApproach(flight, value);
            calcFlight(flight, index, startDate.getTime());
            return getState(state, index, flight, value, type);
        case 'assume':
            // console.log("flight.flight[index].isAssumed", index, flight.flight[index].isAdvanced, flight.flight[index].isAssumed);
            // if (flight.flight[index].isAdvanced) {
            //     console.log("isAdvanced", flight.flight[index].isAdvanced, flight.flight[index])
            //     flight.flight[index].isAssumed = true;
            //     flight.flight[index].isAdvanced = false;
            //     // flight.isAssumed = true;
            //     // flight.isAdvanced = false;
            // } else if (flight.flight[index].isAssumed) {
            //     console.log("isAssumed")
            //     flight.flight[index].isAssumed = false;
            //     // flight.isAssumed = false;
            // }
            // else if (flight.flight[index].isCorrelated) {
            //     console.log("isCorrelated")
            //     flight.flight[index].isAssumed = true;
            //     flight.flight[index].isAdvanced = false;
            //     // flight.isAssumed = true;
            //     // flight.isAdvanced = false;
            // }
            // console.log("flight.flight[index].isAssumed", flight.flight[index].isAssumed, index);
            // calcFlight(flight, index, startDate.getTime());
            // console.log("after calcFlight", flight.flight[index].isAssumed, flight.flight[index+1].isAssumed);
            return getState(state, index, flight, value, type);
        case 'clear':
            return {};


        default:
            throw new Error();
    }

}


export default flightClearanceReducer;