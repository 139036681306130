/*
    node_js
    3/23/2021 12:40 AM
    by Oleksandr
*/

import Window from "../Window";
import React from "react";

const SpeedVectorWindow = (props) => {
    const {pttWindows, setPttWindows, speedVector, setSpeedVector} = props;
    const sVButtonValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20];

    return (
        <Window label='Speed Vector #1' width={'auto'} dXY={{dx: 360, dy: 80}}
                toggleWindow={() => setPttWindows({...pttWindows, speedVectorWindow: false})}
                content={<div style={{
                    display: "grid", gridTemplateColumns: "auto auto auto auto auto auto auto auto auto auto auto auto auto",
                    gridGap: "2px", color: "grey", textAlign: "center", padding: "3px"
                }}
                ><span className={`buttonSDD ${speedVector.isVisible?"buttonSDDpressed":""}`} style={{minWidth: "18px", width:"auto"}}
                                    onClick={() => {
                                        setSpeedVector({...speedVector, isVisible: !speedVector.isVisible});
                                    }}>{speedVector.isVisible ? 'ON' : 'OFF'}</span>
                    {sVButtonValue.map(value => <span key={'sVButton' + value}
                                                      className={`buttonSDD ${speedVector.time===value?"buttonSDDpressed":""}`}
                                                      style={{minWidth: "20px", padding:"0px", width:"auto"}}
                                                      onClick={e => setSpeedVector({
                                                          ...speedVector,
                                                          time: parseInt(e.target.outerText), isVisible:true
                                                      })}>{value}</span>)}
                </div>}/>
    )
}

export default SpeedVectorWindow;