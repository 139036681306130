/*
    node_js
    3/15/2021 11:08 AM
    by Oleksandr
*/

import React from "react";
import {calcFlight, calcPassedPoints, getFlightColor} from "../../../../../calc/calc";
import {ASSUMED_COLOR, SSR_COLOR} from "../../colors/colors";

const ActiveFlightsList = (props) => {
    const {flights, columns, calcFlightsNum, startDate, isEpp, isPlt, tickValue, isCon, updateFlightForEpp} = props;
    const deleteFlight = (e) => {
        const index = flights.map(e => e.id).indexOf(e.target.getAttribute('fltId'));
        if (index >= 0) flights.splice(index, 1);
        calcFlightsNum(flights);
    }

    const getCallsignColor = (flt) => {
        if (isPlt && flt.flight[tickValue + flt.timeShift] && (flt.flight[tickValue + flt.timeShift].isAdvanced ||
            flt.flight[tickValue + flt.timeShift].isAssumed)) {
            return ASSUMED_COLOR;
        } else if (isCon && flt && flt.isAdvanced || flt.isAssumed) {
            return ASSUMED_COLOR;
        } else {
            return SSR_COLOR;
        }

    }
    return (
        <tbody>
        {
            flights.map(row =>
                <tr className='sddTableTr'
                    style={{color: isCon ? getFlightColor(row) : getFlightColor(row.flight[tickValue + row.timeShift])}}>
                    {
                        columns.map(column => {
                            let onclick = () => {
                            };
                            let style;
                            if (column === 'c') {
                                style = {color: getCallsignColor(row)}
                            }
                            if (column === 'rfl') onclick = (e) => {
                                if (!isEpp) return;
                                let promptRFL = window.prompt('New RFL:');
                                if (promptRFL === null) return;
                                let flight = flights.filter((flight) => {
                                    return flight.id === e.target.getAttribute('fltid');
                                })[0];
                                flight.rfl = promptRFL;
                                flight.flight[0].sA = parseInt(promptRFL) * 100;
                                updateFlightForEpp(flight, isEpp);
                            }
                            if (column === 'entryTime' || column === 'exitTime') onclick = (e) => {
                                if (isPlt) return;
                                let promptTime = window.prompt('New time:');
                                if (promptTime === null) return;
                                let flight = flights.filter((flight) => {
                                    return flight.id === e.target.getAttribute('fltid');
                                })[0];
                                let cop = e.target.getAttribute('cop') === 'entryTime' ? 'entryCop' :
                                    e.target.getAttribute('cop') === 'exitTime' ? 'exitCop' : '';
                                // console.log(flight,flight.entryCop,flight.passedPoints);
                                if (flight[cop]) {
                                    if (flight.passedPoints) {
                                        let entryPointIndex = flight.passedPoints.map(e => e.pp.pP).indexOf(flight[cop]);
                                        let testTime = new Date(startDate.getTime());
                                        let t1 = testTime.setHours(parseInt(promptTime.substr(0, 2)), promptTime.substr(2, 2));
                                        let newShift = flight.passedPoints[entryPointIndex].index - (t1 - startDate.getTime()) / 4000;
                                        flight.timeShift = parseInt(newShift);
                                        calcPassedPoints(flight, startDate.getTime());
                                        // calcFlight(flight, 0, startDate.getTime());
                                    }
                                    calcFlightsNum(flights);
                                }
                            }
                            return <td className='sddTableTd' fltid={row.id} cop={column} onClick={onclick}
                                       style={style}>
                                {row[column]}
                            </td>
                        })
                    }
                    {isEpp && <>
                        <td className='sddTableTd' fltId={row.id} onClick = {(e) => {
                        let promptXFL = window.prompt('New XFL:');
                                if (promptXFL === null) return;
                                let flight = flights.filter((flight) => {
                                    return flight.id === e.target.getAttribute('fltid');
                                })[0];
                                flight.xfl = promptXFL;
                            }}>{row.xfl}</td>
                        <td className='sddTableTd'>
                        <span className='buttonSDD' fltId={row.id} onClick={deleteFlight}>Delete</span>
                    </td></>}
                </tr>
            )
        }

        </tbody>
    )
}

export default ActiveFlightsList;