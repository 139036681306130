/*
    node_js
    2/22/2021 7:39 PM
    by Oleksandr
*/

import CWP from "./cwp/CWP";
import ATG from "./atg/ATG";
import {useContext} from "react";
import {CwpContext} from "../contexts/CwpContext";

const EPP = () => {
    // const context = useContext(CwpContext);
    return (
        <>
            <CWP isEpp={true}/>
            <ATG isEpp={true}/>
        </>
    )
}

export default EPP;