/*
    node_js
    4/8/2021 12:05 AM
    by Oleksandr
*/

import {calcFlight} from "../../../../calc/calc";
import React from "react";

const CflConMenu = (props) => {
    const cflArray = ['650', '630', '610', '590', '570', '550', '530', '510', '490', '470', '450', '430', '410', '400', '390', '380', '370', '360', '350', '340', '330', '320', '310', '300', '290', '280', '270', '260', '250', '240', '230', '220', '210', '200', '190', '180', '170', '160', '150', '140', '130', '120', '110', '100', '090', '080', '070', '060', '050', '040', '030', '020', '010'];
    const {callsignMenuFlight, isCon, tickValue, showFlightMenu, typeOfFlightMenu,
        setUpdateFlightsTime} = props;
    let index = isCon || !callsignMenuFlight ? 0 : tickValue + callsignMenuFlight.flight.timeShift;
    return (
        <>
            {(callsignMenuFlight && typeOfFlightMenu) &&
            <div className={'callsignMenu'}
                 style={{left: callsignMenuFlight.clientX + 'px', top: callsignMenuFlight.clientY + 'px'}}>
                <span>{callsignMenuFlight.flight.c}</span><br/>
                <select style={{width: '100%'}} name="Cfl" size="8"
                        onInput={(e) => {
                            callsignMenuFlight.flight.cfl = parseInt(e.target.value);
                            setUpdateFlightsTime(new Date().getTime());

                            // calcFlight(callsignMenuFlight.flight, index);
                            showFlightMenu(null);
                        }}>
                    {cflArray.map((cfl) => {
                            let selected = callsignMenuFlight.flight.cfl === parseInt(cfl) ? true : false;
                            return (<option selected={selected} value={cfl}>{cfl}</option>)
                        })}
                </select>
                <span style={{width: '45px'}} className={'buttonSDD'} onClick={(e) => {
                    // showFlightMenu(null);
                }}>
                    Probe
                </span>
                <input type="number" autoFocus style={{width: '55px'}} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (!e.target.value) return;
                        callsignMenuFlight.flight.cfl = parseInt(e.target.value);
                        setUpdateFlightsTime(new Date().getTime());
                        // calcFlight(callsignMenuFlight.flight, index);
                        showFlightMenu(null);
                    }
                }}/>
            </div>}
        </>
    );

}

export default CflConMenu;