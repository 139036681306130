export const createOffer = async (connection, localStream, userToCall, doOffer, username) => {
    try {
        connection.addStream(localStream)

        const offer = await connection.createOffer()
        await connection.setLocalDescription(offer)

        const doOfferRes = doOffer(userToCall, offer, username)
        return doOfferRes
    } catch (exception) {
        console.error(exception)
    }
}

export const initiateLocalStream = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        })
        return stream
    } catch (exception) {
        // console.error(exception)
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                // video: true,
                audio: true
            })
            return stream
        } catch (exception) {
            console.error(exception)
        }
    }
}
// export const initiateConnection = async () => {
//     try {
//         // using Google public stun server
//         var configuration = {
//             iceServers: [{urls: 'stun:stun2.1.google.com:19302'}]
//         }
//
//         const conn = new RTCPeerConnection(configuration)
//
//         return conn
//     } catch (exception) {
//         console.error(exception)
//     }
// }

export const listenToConnectionEvents = (conn, username, remoteUsername, remoteVideoRef, doCandidate, pushCandidate) => {
    conn.onicecandidate = function (event) {
        if (event.candidate) {
            if (pushCandidate) {
                pushCandidate(JSON.stringify({
                    type: 'candidate',
                    from: username,
                    candidate: JSON.stringify(event.candidate)
                }))
            }
        }
    }

    // when a remote user adds stream to the peer connection, we display it
    conn.ontrack = function (e) {
        if (remoteVideoRef.srcObject !== e.streams[0]) {
            remoteVideoRef.srcObject = e.streams[0]
        }
    }
}

export const sendAnswer = async (conn, localStream, notif, doAnswer, username) => {
    try {
        if (!notif) return;
        conn.addStream(localStream)

        notif = JSON.parse(notif)
        const offer = JSON.parse(notif.offer)
        conn.setRemoteDescription(offer)

        // create an answer to an offer
        const answer = await conn.createAnswer()
        conn.setLocalDescription(answer)

        return JSON.stringify({
            type: 'answer',
            from: username,
            answer: JSON.stringify(answer)
        })
    } catch (exception) {
        console.error(exception)
    }
}

export const startCall = (yourConn, notif) => {
    if (!notif) return
    notif = JSON.parse(notif)
    const answer = JSON.parse(notif.answer)
    yourConn.setRemoteDescription(answer)
}

export const addCandidate = (yourConn, notif) => {
    // apply the new received candidate to the connection
    console.log(notif);
    const candidate = JSON.parse(notif.candidate)
    console.log(candidate)
    yourConn.addIceCandidate(new RTCIceCandidate(candidate))
}

export const createPeerConnection = async (socketID, setPeerConnections, localStream,
                                           socketRef, remoteVideoRef, setRemoteStreams, remoteStreams, callback) => {

    try {
        let configuration = {
            iceServers: [{
                // urls: 'turn:turn.lvivacc.online:5349',
                urls: 'turn:turn.lvivacc.online:8443?transport=tcp',
                username: 'lvivacc',
                credential: 'lviv1234'
            }]
        }
        let pc = await new RTCPeerConnection(configuration)

        // add pc to peerConnections object
        // const peerConnections = {...peerConnections, [socketID]: pc}
        setPeerConnections(socketID, pc);

        pc.onicecandidate = (e) => {
            if (e.candidate) {
                socketRef.current.emit('candidate', {
                    socketID:
                        {
                            local: socketRef.current.id,
                            remote: socketID
                        },
                    payload: e.candidate
                })
            }
        }

        pc.oniceconnectionstatechange = (e) => {
        }

        pc.ontrack = (e) => {
            const remoteVideo = {
                id: socketID,
                name: socketID,
                stream: e.streams[0]
            }
            if (!remoteStreams.some(e => e.stream === remoteVideo.stream)) {
                remoteStreams.push(remoteVideo);
                setRemoteStreams(remoteStreams);
            }
            // if (remoteVideoRef.srcObject !== e.streams[0]) {
            //     remoteVideoRef.srcObject = e.streams[0]
            // }

            //TODO
            // this.setState(prevState => {
            //
            //     // If we already have a stream in display let it stay the same, otherwise use the latest stream
            //     const remoteStream = prevState.remoteStreams.length > 0 ? {} : { remoteStream: e.streams[0] }
            //
            //     // get currently selected video
            //     let selectedVideo = prevState.remoteStreams.filter(stream => stream.id === prevState.selectedVideo.id)
            //     // if the video is still in the list, then do nothing, otherwise set to new video stream
            //     selectedVideo = selectedVideo.length ? {} : { selectedVideo: remoteVideo }
            //
            //     return {
            //         ...selectedVideo,
            //         ...remoteStream,
            //         remoteStreams: [...prevState.remoteStreams, remoteVideo]
            //     }
            // })
        }

        pc.close = () => {
            // alert('GONE')
        }

        if (localStream) {
            pc.addStream(localStream)
        }

        // return pc
        callback(pc)

    } catch (e) {
        console.log('Something went wrong! pc not created!!', e)
        callback(null)
    }
}
