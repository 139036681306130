/*
    node_js
    3/26/2021 11:21 AM
    by Oleksandr
*/

import ActiveWindow from "../ActiveWindow";

const PlannerList = (props) => {
    const {setPttWindows, pttWindows, flights, isCon, tickValue} = props;
    const filterFlights = () => {

        return flights.filter(flt => {
            let index = isCon ? 0 : tickValue + flt.timeShift;
            if (!flt.flight[index]) return false;
            const tempFlt = isCon ? flt : flt.flight[index];
            return tempFlt.isAssumed || tempFlt.isAdvanced;
        })
    }

    return (
        <>
            <ActiveWindow {...props} flights={filterFlights()}/>
        </>
    )
}

export default PlannerList;