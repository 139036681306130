/*
    node_js
    2/10/2021 6:59 AM
    by Oleksandr
*/

import React from "react";
import {Link} from "react-router-dom";

const ENDPOINT = "195.110.59.199:8011";

// const socket = require('socket.io-client')('195.110.59.199:8011', {
//     transports: ['websocket'],
//     jsonp: false,
//     'forceNew': true
// });

// const connect = function () {
//
//     socket.on('respond', function (msg) {
//         console.log('message: ' + msg);
//     });
//
//     socket.emit('greet', 'test');
// }
// const emit = () => {
//     socket.emit('greet', 'test');
//     console.log('emit');
// }

const Home = () => {
    // const [response, setResponse] = useState("");

    // useEffect(() => {
    //     const socket = socketIOClient(ENDPOINT);
    //     socket.on("FromAPI", data => {
    //         setResponse(data);
    //     });
    // }, []);
    return (
        <div>
            <div
                className={'headerContainer'}
                // style={{textAlign: 'left', width: '100vw'}}
                >
                <Link to='/home' className={'routerLink'} style={{width:'300px', textAlign: 'center'}}>Hello, {localStorage.getItem('user_display_name')}</Link>
                <span></span>
                <Link to="/" className={'routerLink'} style={{width:'100px', textAlign:'left'}} onClick={() => {
                    localStorage.clear();
                }}>Logout</Link>
            </div>
            <div style={{height: '100vh', width: '100vw', position: 'relative'}}>

                <div style={{position: 'absolute', top: '40%', left: '40%', margin: 0}}>
                    <Link to="/epp" className={'routerLink homeLinkButton buttonSDD'}>Exersice Preparation Tool</Link>
                    <Link to="/plt" className={'routerLink buttonSDD homeLinkButton'}>Run Exersice</Link>
                    <Link to="/con" className={'routerLink buttonSDD homeLinkButton'}>Connect to session</Link>
                    <Link to="/demo" className={'routerLink buttonSDD homeLinkButton'}>OpenSky</Link>
                </div>
            </div>

            {/*<div>*/}
            {/*    tst*/}
            {/*    <div>{response}</div>*/}
            {/*</div>*/}
            {/*<button onClick={connect}>connect</button>*/}
            {/*<button onClick={emit}>EMIT</button>*/}
        </div>
    )
}

export default Home;