/*
    node_js
    3/16/2021 7:57 AM
    by Oleksandr
*/

import Window from "./Window";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import {useEffect, useState} from "react";
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ExerciseDescriptionWindow = (props) => {
    const {exerciseDescription, setExerciseDescription, setPttWindows, pttWindows, isEpp} = props;
    // const blocksFromHTML = convertFromHTML(exerciseDescription);
    // const state = ContentState.createFromBlockArray(
    //     blocksFromHTML.contentBlocks,
    //     blocksFromHTML.entityMap,
    // );
    // const [editorState, setEditorState] = useState(EditorState.createWithContent(state));
    const onEditorStateChange = (editorState) => {
        setExerciseDescription(editorState);
        // console.log(JSON.stringify(stateToHTML(editorState.getCurrentContent())))
    }

    return (
        <Window width={850} height={'auto'} label='Exercise Description'
                toggleWindow={() => setPttWindows({...pttWindows, exerciseDescriptionWindow: false})}
                content={<div className='editor'>
                    <Editor
                        editorStyle={{ border: "1px solid", overflowY: "scroll", maxHeight: "500px",
                        margin:"5px", background: "grey"}}
                        editorState={exerciseDescription}
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                            inline: {inDropdown: true},
                            list: {inDropdown: true},
                            textAlign: {inDropdown: true},
                            link: {inDropdown: true},
                            history: {inDropdown: true},
                            // image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                        }}
                        toolbarHidden={isEpp ?false:true}
                        readOnly={isEpp ?false:true}
                    />
                </div>
                } />
                    // :
                    // content={!isPlt ? <textarea style={{width: '95%', height: '95%'}} value={exerciseDescription}
                    // onChange={(e) => setExerciseDescription(e.target.value)}></textarea> :
                    // <div style={{overflowY: "scroll", height: "500px", paddingRight: "1px"}}
                    //      dangerouslySetInnerHTML={{__html: stateToHTML(exerciseDescription.getCurrentContent())}}/>}/>
    )
}

export default ExerciseDescriptionWindow;