/*
    node_js
    2/26/2021 8:27 AM
    by Oleksandr
*/

import CWP from "./cwp/CWP";
import {useContext} from "react";
import {CwpContext} from "../contexts/CwpContext";

const CNTL = () => {
    const context = useContext(CwpContext);
    return (
        <>
            <CWP isCon={true} context={context}/>
        </>
    )
}

export default CNTL;