/*
    node_js
    1/31/2021 9:40 PM
    by Oleksandr
*/

import TrackLayer from "../graphics/layers/TrackLayer";
import MapLayer from "../graphics/layers/MapLayer";
import AswWindowLayer from "../graphics/layers/AswWindowLayer";

export function ASW(props) {

    // useEffect(() => {
    //     console.log(flights);
    // }, [flights]);
    document.getElementsByTagName('body')[0].style.position='fixed';


    return (
        <>
            <MapLayer {...props} />
            <TrackLayer {...props} />
            <AswWindowLayer {...props} />
            {/*{flightSpan}*/}
        </>
    )

}