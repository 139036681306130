/*
    node_js
    2/27/2021 2:34 AM
    by Oleksandr
*/
import React, {useEffect, useState} from 'react'
import {useDrag} from "react-use-gesture";

const Window = (props) => {
    const {width, height, label, isVisible, toggleWindow, content, style, hideCloseButton, dXY} = props;
    const [{dx, dy}, setDxDy] = useState({dx: 10, dy: 26});
    useEffect(()=>{
        if (dXY) {
            setDxDy(dXY);
        }
    },[])

    const bind = useDrag(({buttons, delta: [deltaX, deltaY]}) => {
        setDxDy({dx: dx + deltaX, dy: dy + deltaY});
    });

    return (
        <>
            <div className="cwp-window" style={style ||{
                width: width,
                height:height,
                left: dx + "px", top: dy + "px"
            }}>
                <div {...bind()} className="window-header">
                    <span className="window-header-text">{label}</span>
                </div>
                <div className="window-content">
                    {content}
                </div>
                {!hideCloseButton &&
                    <div className="close-button" onClick={() => toggleWindow(isVisible)}>
                        <div className="close-button-cross">
                            <span>+</span>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
export default Window;