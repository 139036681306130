/*
    node_js
    9/28/2021 8:30 PM
    by Oleksandr
*/

import Dialog from "@material-ui/core/Dialog";
import React from "react";

const Loading = (props) => {

    return <Dialog open style={{background:"transparent", overflow:"hidden", opacity:0.5, zIndex: 1000001}}>

        <div className="lds-roller" style={{backgroundColor:"grey", overflow:"hidden"}}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </Dialog>
}

export default Loading;