/*
    node_js
    2/20/2021 8:14 AM
    by Oleksandr
*/

import React from "react";

const Rbl = (props) => {
    const {x1, y1, x2, y2, key, text, deleteRbl, id, minSepText, bearingText, timeText, minSepRbl} = props;
    return (
        <g>
            <line key={key} x1={x1} y1={y1} x2={x2} y2={y2} stroke={'yellow'} />
            <text x={(x1+x2)/2} y={(y1+y2)/2} fill={'yellow'} rblid={id} onMouseUp={deleteRbl}
                  style={{pointerEvents: 'bounding-box', fontSize: '11px'}}>
                <tspan x={(x1+x2)/2} dy='1.2em' rblid={id} onMouseUp={deleteRbl}>B {bearingText}</tspan>
                <tspan x={(x1+x2)/2} dy='1.2em' rblid={id} onMouseUp={deleteRbl}>R {text}</tspan>
                <tspan x={(x1+x2)/2} dy='1.2em' rblid={id} onMouseUp={deleteRbl}>{timeText}</tspan>
                <tspan x={(x1+x2)/2} dy='1.2em' rblid={id} onClick={minSepRbl} onMouseUp={deleteRbl}>{minSepText}</tspan>
            </text>
        </g>
    )
}

export default Rbl;