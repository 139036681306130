/*
    node_js
    3/5/2021 6:13 AM
    by Oleksandr
*/

import CWP from "./cwp/CWP";
import {useContext, useEffect, useState} from "react";
import {CwpContext} from "../contexts/CwpContext";
import * as geolib from "geolib";

const DEMO = () => {
    const [isDemoMsgWindowVisible, setIsDemoMsgWindowVisible] = useState(true);
    const context = useContext(CwpContext);


    return (
        <>
            <CWP isCon={true} isDemo={true}/>
            {context.historicalFlights.length > 0 && <OpenSky/>}
            {/*<ATG isPlt={true}/>*/}
            {/*{isDemoMsgWindowVisible && <DemoMsgWindow />}*/}
        </>
    )
}

export default DEMO;

const OpenSky = (props) => {
    const context = useContext(CwpContext);
    const requestOptions = {
        method: 'GET',
        headers: new Headers().append('Authorization', 'Basic' + btoa("ptt:Lviv_@1234"))
    };
    const updateFlt = () => {
        fetch('https://opensky-network.org/api/states/all?lamin=45.00&lomin=20.00&lamax=53.00&lomax=36.00', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                data.states.forEach((flt) => {

                    flt[12] = "";
                    flt[13] = {};
                    flt[13].name = "";
                    let indexOfFlight = context.flights.map(e => e.id).indexOf(flt[0]);
                    if (indexOfFlight >= 0) {
                        flt[12] = context.flights[indexOfFlight].fpl[3];
                        if (context.flights[indexOfFlight].routePlan && context.flights[indexOfFlight].routePlan.planPoints) {
                            flt[14] = context.flights[indexOfFlight].routePlan.planPoints;
                            // console.log("flt[14]", flt[14], flt[1]);
                            // flt[13] = getClousestPoint(flt[5], flt[6], flt[14]);
                            // flt[17] = flt[14].map(p => p.name).indexOf(flt[13].name);
                            flt[18] = geolib.convertDistance(
                                geolib.getDistance({
                                    latitude: flt[6],
                                    longitude: flt[5]
                                }, flt[14][context.flights[indexOfFlight].flight[0].nP]),
                                "sm"
                            );
                            if (context.flights[indexOfFlight].distToNextPoint < flt[18]) {
                                flt[17] = flt[14].length - 1 > context.flights[indexOfFlight].flight[0].nP + 1 ?
                                    context.flights[indexOfFlight].flight[0].nP + 1 : flt[14].length - 1;
                                // console.log(flt[1], flt[17], flt[14].length, flt[14], context.flights[indexOfFlight].flight[0].nP)
                                // flt[18] = 999999999999999;
                                flt[18] = geolib.convertDistance(
                                    geolib.getDistance({latitude: flt[6], longitude: flt[5]}, flt[14][flt[17]]),
                                    "sm"
                                );
                            } else {
                                // console.log(flt[1], "context.flights[indexOfFlight].distToNextPoint", context.flights[indexOfFlight].distToNextPoint, context.flights[indexOfFlight].flight[0].nP)
                                flt[17] = context.flights[indexOfFlight].flight[0].nP;
                            }
                            flt[13].name = flt[14][flt[17]].name;
                        }
                        context.flights[indexOfFlight].distToNextPoint = flt[18];
                        context.flights[indexOfFlight].flight = addFlt(flt).flight;
                    } else {
                        const filter = context.historicalFlights.filter((hist) => {
                            return hist.c === flt[1].replaceAll(" ", "");
                        });
                        flt[14] = null;
                        flt[15] = null;
                        flt[16] = [];
                        flt[17] = 0;
                        let isAdvanced = false;
                        if (filter.length > 0) {
                            flt[12] = filter[0].type;
                            flt[14] = filter[0].routePlan.planPoints;
                            flt[16] = flt[14].map(pP => {
                                return {index: 0, pp: {pP: pP.name, a: flt[7] * 3.28084}, pt: "12:00:00"}
                            })
                            flt[15] = filter[0].fpl;
                            flt[13] = getClousestPoint(flt[5], flt[6], flt[14]);
                            flt[17] = flt[14].map(p => p.name).indexOf(flt[13].name);
                            flt[18] = geolib.convertDistance(
                                geolib.getDistance({latitude: flt[6], longitude: flt[5]}, flt[14][flt[17]]),
                                "sm"
                            );
                            isAdvanced = true;
                            // console.log("flt[16]",flt[16],flt[14], flt[13].name, flt[14].map(p=>p.name))
                        }
                        const tempFlt = addFlt(flt);
                        tempFlt.cfl = parseInt(flt[7] * 0.0328084);
                        tempFlt.sectors = [];
                        tempFlt.isAdvanced = isAdvanced;
                        context.flights.push(tempFlt);
                    }

                    // console.log(flt[1].replaceAll(" ", "_"));

                })
                // context.flights.forEach((flt, index) => {
                //     let indexOfFlight = context.flights.map(e => e.id).indexOf(flt.id);
                //     if (indexOfFlight === -1) {
                //         context.flights.splice(index, 1);
                //     }
                // })
                const d1 = new Date();
                d1.setHours(d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds());
                context.setStartDate(d1);
                // context.setFlights(flights);
                console.log(data);
            })
            .catch(e=> {
                console.log(e)
            })
    }


    useEffect(() => {
        // updateFlt();
        const interval = setInterval(() => {
            updateFlt();
        }, 4000)
        return () => clearInterval(interval)
    }, [])

    return <></>
}

const addFlt = (flt) => {
    return {
        id: flt[0], c: flt[1],
        fpl: flt[15] ? flt[15] : ["", "", "", flt[12], "", "", "", "", "", "", [], ""],
        dx: 0, dy: 0,
        timeShift: 0,
        routePlan: {planPoints: flt[14]},
        passedPoints: flt[16],
        distToNextPoint: flt[18],
        flight: [{
            gs: flt[9] * 1.94384,
            t: flt[9] * 1.94384,
            h: flt[10] - 6,
            c: flt[1],
            cP: {latitude: flt[6], longitude: flt[5]},
            a: flt[7] * 3.28084,
            isClimb: flt[11] > 1,
            isDesc: flt[11] < -1,
            sA: 0,
            nP: flt[17] || 0,
            nPN: flt[13].name
        }]
    }

}

const getClousestPoint = (flt5, flt6, flt14) => {

    const dist = flt14.map(point => {
        return {
            dist: Math.abs(point.latitude - flt6) +
                Math.abs(point.longitude - flt5),
            name: point.name
        }
    })

    return dist.reduce((min, p) => p.dist < min.dist ? {
        dist: p.dist,
        name: p.name
    } : min, {dist: dist[0].dist, name: dist[0].name})
}