/*
    node_js
    2/13/2021 4:40 PM
    by Oleksandr
*/

import React from "react";

const TargetHistory = (props) => {
    const {track, color} = props;

    return (
        <circle stroke={color} cx={track.X} cy={track.Y} r={1.5} fillOpacity="0"/>
    )
}

export default TargetHistory;