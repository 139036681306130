/*
    node_js
    3/23/2021 9:29 PM
    by Oleksandr
*/

import Window from "./Window";
import React, {useEffect} from "react";
import {calcApproach, calcDeparture, calcFlight, calcPassedPoints} from "../../../../calc/calc";
import {convertFromRaw, EditorState} from "draft-js";

const ExerciseWindow = (props) => {
    const {
        files, getAllSim, setExerciseName, setTickValue,
        historicalFlights, isPlt, isEpp, getSquawk, runways,
        isDemo, startDate, setFlights, calcFlightsNum, setExerciseDescription,
        flights, saveExercise, pttWindows, setPttWindows, setTickPause,
        sectors, dispatchFlightClearance, setExerciseData, exerciseWorkload,
        updateFlightForEpp, setIsLoading
    } = props;

    useEffect(() => {
        getAllSim();
    }, []);
    const getOtherExercises = () => {
        let atm_code = localStorage.getItem('atm_code');
        return <div style={{overflowY: "scroll", height: "500px", width: "500px"}}>
            <table style={{padding: "5px", width: "100%"}}>
                <thead>
                <tr className='headerTh'>
                    <td colSpan="2" style={{textAlign: "center"}}>Other Exercises</td>
                </tr>
                </thead>
                {files.filter((file) => {
                    return file.name.split('_')[0] !== atm_code;
                })
                    .map((exercise) => {
                        return <tr className='sddTableTr'>
                            <td style={{paddingRight: "20px"}}><span>{exercise.name}</span></td>
                            <td style={{cursor: "pointer"}} key={'exercisename' + exercise.name}><span
                                className='buttonSDD'
                                id={exercise.name} onClick={(e) => {
                                loadExercise(e.target.id);
                                pttWindows.systemTimeWindow = true;
                                setPttWindows({...pttWindows, exerciseWindow: false});
                            }}
                                style={{width: "50px"}}>Load</span></td>
                        </tr>
                    })}
            </table>
        </div>
    }
    const loadExercise = (file) => {
        if (file === null) return;
        setExerciseName(file);
        setIsLoading(true);
        let flightsClone = [];
        setTickValue(0);
        // fetch(file,
        //     {
        //         mode: "no-cors",
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Accept': 'application/json'
        //         }
        //     }
        // )
        //     .then(function (response) {
        //         console.log(response);
        //         return response.json();
        //     })
        //     .then(resp => {
        //         resp.forEach((flight) => {
        //             const index = historicalFlights.map(e => e.id).indexOf(flight.id);
        //             if (index >= 0) {
        //                 historicalFlights[index].timeShift = flight.timeShift;
        //                 let tempFlight;
        //                 if (isPlt) {
        //                     tempFlight = JSON.parse(JSON.stringify(historicalFlights[index]));
        //                 } else {
        //                     tempFlight = historicalFlights[index];
        //                 }
        //                 if (flight.rfl) {
        //                     tempFlight.rfl = flight.rfl;
        //                     tempFlight.flight[0].sA = parseInt(flight.rfl) * 100;
        //                     tempFlight.flight[0].isAdvanced = true;
        //                     tempFlight.flight[0].isCorrelated = true;
        //                 }
        //                 tempFlight.squawk = getSquawk();
        //                 calcDeparture(tempFlight, runways);
        //                 calcApproach(tempFlight, runways);
        //                 calcFlight(tempFlight, 0);
        //                 if ((isDemo || isPlt) && tempFlight.timeShift > 0) {
        //                     tempFlight.flight.splice(0, flight.timeShift);
        //                     tempFlight.timeShift = 0;
        //                 }
        //                 tempFlight.dx = 0;
        //                 tempFlight.dy = 0;
        //                 tempFlight.isTrackPlan = false;
        //
        //                 calcPassedPoints(tempFlight, startDate.getTime());
        //                 flightsClone.push(tempFlight);
        //             }
        //         })
        //         setFlights(flightsClone);
        //         return null;
        //     })
        //     .then(() => calcFlightsNum(flightsClone))
        // .then(() => {
        //     setTickPause(false);
        // });
        let searchParams = new URLSearchParams();
        searchParams.set('action', 'load');
        searchParams.set('exercise', file);
        // searchParams.set('exerciseDescription', exerciseDescription);
        const requestOptions = {
            method: 'POST',
            mode: "cors",
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: searchParams
        };
        fetch('https://lvivacc.site/ptt/exercise_2.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                if (data[0]) {
                    setExerciseData(data[0]);
                    let tempDescr;
                    try {
                        tempDescr = convertFromRaw(JSON.parse(data[0].exerciseDescription));
                        setExerciseDescription(EditorState.createWithContent(tempDescr));
                    } catch (e) {
                        setExerciseDescription(EditorState.createEmpty());
                    }
                    JSON.parse(data[0].flights).forEach((flight) => {
                        const index = historicalFlights.map(e => e.id).indexOf(flight.id);
                        if (index >= 0) {
                            historicalFlights[index].timeShift = flight.timeShift;
                            let tempFlight;
                            if (isPlt) {
                                tempFlight = JSON.parse(JSON.stringify(historicalFlights[index]));
                            } else {
                                tempFlight = historicalFlights[index];
                            }
                            if (flight.rfl) {
                                tempFlight.rfl = flight.rfl;
                                tempFlight.flight[0].sA = parseInt(flight.rfl) * 100;
                                tempFlight.flight[0].isAdvanced = false;
                                tempFlight.flight[0].isCorrelated = true;
                            }
                            tempFlight.squawk = getSquawk();
                            tempFlight.xfl = flight.xfl || null;
                            calcDeparture(tempFlight, runways);
                            calcApproach(tempFlight, runways);
                            // calcFlight(tempFlight, 0);
                            // if (tempFlight.sectors.has(sectors.ops.sector)) {
                            //     tempFlight.isAdvanced = true;
                            // } else {
                            //     tempFlight.isAdvanced = false;
                            // }

                            // calcFlight(tempFlight, 0, startDate.getTime());
                            // let ind1 = tempFlight.timeShift;
                            //
                            // let tempSetOfBasicSetors = new Set();
                            // for (let ii = ind1; ii < tempFlight.flight.length; ii += 15) {
                            //     if (tempFlight.flight[ii] && tempFlight.flight[ii].iBSN) tempSetOfBasicSetors.add(tempFlight.flight[ii].iBSN);
                            // }
                            // let arrayOfBasicSetors = Array.from(tempSetOfBasicSetors);
                            // arrayOfBasicSetors.forEach(a => {
                            //     if (sectors.ops.basicSectors.includes(a)) tempFlight.flight[0].isAdvanced = true;
                            // });
                            // calcFlight(tempFlight, 0, startDate.getTime());
                            // console.log("sectors.ops.basicSectors", sectors.ops.basicSectors);
                            updateFlightForEpp(tempFlight, isEpp);
                            if ((isDemo || isPlt) && tempFlight.timeShift > 0) {
                                tempFlight.flight.splice(0, flight.timeShift);
                                tempFlight.timeShift = 0;
                            }
                            tempFlight.dx = 0;
                            tempFlight.dy = 0;
                            tempFlight.isTrackPlan = false;

                            // calcPassedPoints(tempFlight, startDate.getTime());
                            flightsClone.push(tempFlight);
                        }
                    });
                    setFlights(flightsClone);
                } else {
                    setExerciseDescription(EditorState.createWithText(''));
                }
            })
            .then(() => {
                calcFlightsNum(flightsClone);
                setTickPause(false);
                dispatchFlightClearance({type: 'clear'});
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getMyExercises = () => {
        let atm_code = localStorage.getItem('atm_code');
        return <div style={{overflowY: "scroll", height: "500px", width: "500px"}}>
            <table style={{padding: "5px", width: "100%"}}>
                <thead>
                <tr className='headerTh'>
                    <td colSpan="4" style={{textAlign: "center"}}>My Exercises</td>
                </tr>
                </thead>
                {files.filter((file) => {
                    return file.name.split('_')[0] === atm_code;
                })
                    .map((exercise) => {
                        return <tr className='sddTableTr'>
                            <td style={{paddingRight: "20px"}}><span>{exercise.name}</span></td>
                            <td style={{cursor: "pointer"}} key={'exercisename' + exercise.name}><span
                                className='buttonSDD'
                                id={exercise.name} onClick={(e) => {
                                loadExercise(e.target.id);
                                pttWindows.systemTimeWindow = true;
                                setPttWindows({...pttWindows, exerciseWindow: false});
                            }}
                                style={{width: "50px"}}>Load</span></td>
                            <td style={{cursor: "pointer", padding: "3px"}} onClick={() => {
                                atm_code = atm_code === 'undefined' ? 'CON' : atm_code;
                                let promptName = window.prompt('Exercise name: ' + atm_code + '_',
                                    exercise.name.replace(atm_code + '_', ''));
                                if (promptName === null) return;
                                let searchParams = new URLSearchParams();
                                searchParams.set('action', 'rename');
                                searchParams.set('old_name', exercise.name);
                                searchParams.set('new_name', atm_code + '_' + promptName);
                                let requestOptions = {
                                    method: 'POST',
                                    mode: "cors",
                                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                                    body: searchParams
                                };
                                fetch('https://lvivacc.site/ptt/exercise_2.php', requestOptions)
                                    // .then(response => response.json())
                                    .then(() => {
                                        getAllSim()
                                    })
                                    .catch((error) => {
                                        console.error('Error:', error);
                                    });
                            }
                            }><span className='buttonSDD' style={{width: "50px"}}>Rename</span></td>
                            <td style={{cursor: "pointer", padding: "3px"}}><span className='buttonSDD'
                                                                                  style={{width: "50px"}}
                                                                                  id={exercise.name}
                                                                                  onClick={(e) => {
                                                                                      let confirmation = window.confirm("Are you sure want to delete " + exercise.name);
                                                                                      if (confirmation == true) {
                                                                                          console.log('Yes');
                                                                                      } else {
                                                                                          return;
                                                                                      }
                                                                                      let searchParams = new URLSearchParams();
                                                                                      searchParams.set('action', 'delete');
                                                                                      searchParams.set('exercise', e.target.id);
                                                                                      let requestOptions = {
                                                                                          method: 'POST',
                                                                                          mode: "cors",
                                                                                          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                                                                                          body: searchParams
                                                                                      };
                                                                                      fetch('https://lvivacc.site/ptt/exercise_2.php', requestOptions)
                                                                                          // .then(response => response.json())
                                                                                          .then(() => {
                                                                                              getAllSim()
                                                                                          })
                                                                                          .catch((error) => {
                                                                                              console.error('Error:', error);
                                                                                          });
                                                                                  }}>Delete</span>
                            </td>
                        </tr>
                    })}
            </table>
        </div>
    }
    return (
        <>
            <Window label='Exercise Management' width={'auto'}
                    toggleWindow={() => setPttWindows({...pttWindows, exerciseWindow: false})}
                    content={
                        <>
                            {isEpp && <div style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(12, 1fr)",
                                gridGap: "3px",
                                padding: "5px 7px"
                            }}>
                                <span className='buttonSDD' onClick={(e) => {
                                    setFlights([]);
                                    setExerciseName('new_exercise');
                                    setExerciseDescription(EditorState.createEmpty());
                                    setPttWindows({...pttWindows, exerciseWindow: false});
                                }}>New Exercise</span>
                                <span className='buttonSDD'
                                      onClick={() => {
                                          let exerciseJson = [];
                                          flights.forEach((flight) => {
                                              exerciseJson.push({
                                                  id: flight.id,
                                                  timeShift: flight.timeShift,
                                                  rfl: flight.rfl,
                                                  xfl: flight.xfl
                                              });
                                          });
                                          console.log('exerciseWorkload', exerciseWorkload);
                                          saveExercise(exerciseJson, flights).then(() => {
                                              console.log(exerciseJson);
                                              getAllSim()
                                          });
                                      }}
                                >Save</span></div>}
                            <div style={{
                                display: "grid", gridTemplateColumns: "auto auto",
                                gridGap: "3px"
                            }}>
                                {files && getMyExercises()}
                                {files && getOtherExercises()}
                            </div>
                        </>
                    }

            />

        </>
    )
}

export default ExerciseWindow;