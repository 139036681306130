/*
    node_js
    1/31/2021 7:17 PM
    by Oleksandr
*/
import Flight from "./aircraft/Flight";
import {geoMercator} from "d3-geo";
// import {runways} from "../config/adap/runways";
import {airports} from "../config/adap/nav/sidStar/ukll";
import {lve, lvt, lvw, uklv} from "../config/adap/map"
import {cops} from "../config/adap/cops";
import * as geolib from "geolib";
import {ADVANCED_COLOR, ASSUMED_COLOR, SSR_COLOR, UNCONCERNED_COLOR} from "../components/cwp/graphics/colors/colors";


export const getProjection = (zoom, lonLatCenter) => {
    return geoMercator().scale(zoom).center(lonLatCenter);
}
export const LatToY = (lat0, zoom) => {
    let lat = 180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + lat0 * (Math.PI / 180) / 2));
    let lat1 = 180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + 49.2 * (Math.PI / 180) / 2));
    return 500 + (lat1 - lat) * (50000 / zoom);
};
export const LonToX = (lon, zoom) => {
    return 500 + (lon - 23) * (50000 / zoom);
};
export const YToLat = (y, zoom) => {
    let lat1 = 180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + 49.2 * (Math.PI / 180) / 2));
    let lat = (LatToY(49.2, zoom) - y) / (50000 / zoom) + 49.2;
    return lat;
}

export const XToLon = (x, zoom) => {
    let lon = (-1) * ((LonToX(23, zoom) - x) / (50000 / zoom) - 23);
    return lon;
}
export const xmlToLat = (tempLat) => {

    return Number(parseInt(tempLat.substr(1, 3))) + Number(parseInt(tempLat.substr(5, 2)) / 60) + Number(parseInt(tempLat.substr(8, 2)) / 3600);
}

export const xmlToLon = (tempLon) => {

    return Number(parseInt(tempLon.substr(1, 3))) + Number(parseInt(tempLon.substr(5, 2)) / 60) + Number(parseInt(tempLon.substr(8, 2)) / 3600);
}

export const distanceLatLon = (lat1, lon1, lat2, lon2) => {
    var R = 6372.797560856;
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d / 1.852; // nm / kilometers
}

export const calcStcaAll = (flights, tickValue, isCon) => {
    let stcas = [];
    for (let i = 0; i < flights.length; i++) {
        let tSh1 = isCon ? 0 : tickValue + flights[i].timeShift;
        if ((flights[i] && flights[i].flight.length > tSh1 && tSh1 >= 0) && flights[i].flight[tSh1]) {
            let flt1Pos = flights[i].flight[tSh1].cP;
            if ((flt1Pos.latitude > 47.47 && flt1Pos.longitude > 22 &&
                flt1Pos.latitude < 52.2 && flt1Pos.longitude < 28)) {
                for (let j = i + 1; j < flights.length; j++) {
                    let tSh2 = isCon ? 0 : tickValue + flights[j].timeShift;
                    if ((flights[j].flight.length > tSh2 && tSh2 >= 0) && flights[j].flight[tSh2]) {
                        let flt2Pos = flights[j].flight[tSh2].cP;
                        let alt1 = flights[i].flight[tSh1].a;
                        let alt2 = flights[j].flight[tSh2].a;
                        if (Math.abs(alt1 - alt2) < 950) {
                            if (flt2Pos.latitude > 47.47 && flt2Pos.longitude > 22 &&
                                flt2Pos.latitude < 52.2 && flt2Pos.longitude < 28) {
                                let dist = distanceLatLon(flt1Pos.latitude, flt1Pos.longitude,
                                    flt2Pos.latitude, flt2Pos.longitude);
                                let separation = 5;
                                if (flights[i].isLvt && flights[j].isLvt) separation = 5;
                                if (dist < separation) {
                                    stcas.push({
                                        id1: flights[i].id,
                                        id2: flights[j].id,
                                        callsign1: flights[i].flight[tSh1].c,
                                        callsign2: flights[j].flight[tSh2].c,
                                        distance: dist
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // console.log(stcas);
    return stcas;
}

export const calcDeparture = (flight, runways) => {
    let adep = flight.adep;
    let ades = flight.ades;
    let tempRunway = runways.filter(r => r.ICAOcode === adep);
    if (tempRunway.length === 0) return;
    let adepRunway = tempRunway[0].Runways;
    if (adepRunway === '') return;
    let tempAirport = airports.filter(a => a.ICAOcode === adep);
    if (tempAirport.length === 0) return;

    flight.adepRunway = adepRunway;

    let tempSid = tempAirport[0].Sid.filter(s => s.Name.substr(0, 4) ===
        flight.fpl[10][1].name.substr(0, 4) && s.Runways === adepRunway);
    let AllDepartureWaypoints = [];

    if (tempSid.length > 0) {
        AllDepartureWaypoints = AllDepartureWaypoints.concat(tempSid[0].Sid_Waypoint);
        flight.fpl[10].splice(0, 2);

        let tempPoints = [];
        AllDepartureWaypoints.forEach((wp, index) => {
                let dist = 0;
                let length = AllDepartureWaypoints.length;
                let latLongNext;

                let tempWp;
                if (wp.Type === 'DmeIntc') {
                    let dmeIntc = geolib.computeDestinationPoint(
                        {
                            latitude: wp.Latitude,
                            longitude: wp.Longitude
                        },
                        parseInt(wp.DMEtoIntercept) * 1.852 * 1000,
                        parseInt(wp.Hdg_Crs_value) + 6
                    );
                    tempWp = {
                        Name: wp.Name + wp.DMEtoIntercept,
                        Latitude: dmeIntc.latitude,
                        Longitude: dmeIntc.longitude
                    }
                } else {
                    tempWp = wp;
                }


                latLongNext = length > index + 1 ?
                    {
                        latitude: parseFloat(AllDepartureWaypoints[index + 1].Latitude),
                        longitude: parseFloat(AllDepartureWaypoints[index + 1].Longitude)
                    }
                    : {
                        latitude: parseFloat(flight.fpl[10][0].latitude),
                        longitude: parseFloat(flight.fpl[10][0].longitude)
                    }

                dist = distanceLatLon(parseFloat(tempWp.Latitude), parseFloat(tempWp.Longitude),
                    latLongNext.latitude, latLongNext.longitude)

                let tempRoutePoint = {
                    name: tempWp.Name,
                    isCop: cops.includes(tempWp.Name),
                    // isRunway: tempWp.Type === 'Runway',
                    latitude: parseFloat(tempWp.Latitude),
                    longitude: parseFloat(tempWp.Longitude),
                    dist: dist,
                }
                tempPoints.push(tempRoutePoint);
            }
        )

        flight.fpl[10] = tempPoints.concat(flight.fpl[10]);
        flight.flight[0].h = geolib.getGreatCircleBearing(
            flight.flight[0].cP, flight.fpl[10][0]) - 6;
        flight.fpl[10].unshift({});
    }

}

export const calcApproach = (flight, runways) => {
    let adep = flight.adep;
    let ades = flight.ades;
    let tempRunway = runways.filter(r => r.ICAOcode === ades);
    if (tempRunway.length === 0) return;
    let adesRunway = tempRunway[0].Runways;
    if (adesRunway === '') return;
    let tempAirport = airports.filter(a => a.ICAOcode === ades);
    if (tempAirport.length === 0) return;

    flight.adesRunway = adesRunway;
    flight.adesRunwayDetails = tempAirport[0].Runways[adesRunway];
    let tempApproach = tempAirport[0].Approach.filter(a => a.Name === 'ILS' + tempRunway[0].Runways);
    if (tempApproach.length === 0) return;

    let tempStar = tempAirport[0].Star.filter(s => s.Name.substr(0, 4) ===
        flight.fpl[10][flight.fpl[10].length - 2].name.substr(0, 4) && s.Runways === adesRunway);
    let AllApproachWaypoints = [];

    if (tempStar.length > 0) {
        if (tempApproach[0].App_Waypoint[0].Name === tempStar[0].Star_Waypoint[tempStar[0].Star_Waypoint.length - 1].Name) {
            tempStar[0].Star_Waypoint.pop();
        }
        AllApproachWaypoints = AllApproachWaypoints.concat(tempStar[0].Star_Waypoint);
        flight.fpl[10].splice(flight.fpl[10].length - 2, 1);
    }

    let indexOfRunway = tempApproach[0].App_Waypoint.map(e => e.Type).indexOf('Runway');
    if (tempApproach[0].App_Waypoint.length > indexOfRunway) tempApproach[0].App_Waypoint.splice(indexOfRunway + 1, tempApproach[0].App_Waypoint.length - indexOfRunway)

    AllApproachWaypoints = AllApproachWaypoints.concat(tempApproach[0].App_Waypoint);
    let tempPoints = [];
    AllApproachWaypoints.forEach((wp, index) => {
            let dist = 0;
            let length = AllApproachWaypoints.length;
            let latLongNext;

            latLongNext = length > index + 1 ? {
                latitude: parseFloat(AllApproachWaypoints[index + 1].Latitude),
                longitude: parseFloat(AllApproachWaypoints[index + 1].Longitude)
            } : {
                latitude: parseFloat(wp.Latitude),
                longitude: parseFloat(wp.Longitude)
            };
            dist = distanceLatLon(parseFloat(wp.Latitude), parseFloat(wp.Longitude),
                latLongNext.latitude, latLongNext.longitude)

            let tempRoutePoint = {
                name: wp.Name,
                isCop: cops.includes(wp.Name),
                isRunway: wp.Type === 'Runway',
                // isInternal: internalFix.includes(routePoint.ptID),
                // isEntry: (previousFix === false && internalFix.includes(routePoint.ptID)) ? true : false,
                // isExit: false,
                // isAdep: routePoint.tag === 'ADEP',
                // isAdes: routePoint.tag === 'ADES',
                latitude: parseFloat(wp.Latitude),
                longitude: parseFloat(wp.Longitude),
                dist: dist,
            }
            tempPoints.push(tempRoutePoint);
        }
    )
    // console.log(adesRunway, tempStar[0].Star_Waypoint, tempPoints);
    // flight.fpl[10].length = flight.fpl[10].length - 2;
    if (flight.fpl[10][flight.fpl[10].length - 1].isAdes) flight.fpl[10].pop();
    flight.fpl[10] = flight.fpl[10].concat(tempPoints);

    // flight.isInApproachArea =
    return flight;
}

export const calcFlight = (flight, tickValue, startDate) => {
    console.log("flight", flight.xfl);

    let tst = new Flight({
        flight: flight.flight[tickValue],
        rfl: flight.rfl,
        fpl: flight.fpl,
        id: flight.id,
        cas: flight.cas,
        squawk: flight.flight[tickValue].sq || flight.squawk,
        adesRunway: flight.adesRunway,
        adesRunwayDetails: flight.adesRunwayDetails,
        type: flight.type,
        exC: flight.exitCop,
        ades: flight.ades,
        xfl: flight.xfl
    });
    let pointsArray = [];
    if (tickValue === 0) flight.sectors = new Set();
    // let isCalc = 0;
    while (tst.isActive) {
        tst.calculateNextPoint();
        let passedPoint, nextPoint, currentPosition;
        if (tst.isTerminated) {
            passedPoint = tst.route[tst.route.length - 1].name;
            nextPoint = passedPoint;
            currentPosition = tst.route[tst.route.length - 1];
        } else {
            passedPoint = tst.passedPoint ? tst.route[tst.prevPoint - 1].name : null;
            nextPoint = tst.route[tst.nextPoint].name;
            currentPosition = tst.currentPosition;
        }

        // passedPoint = tst.passedPoint ? tst.route[tst.prevPoint - 1].name :
        //     tst.isTerminated ? tst.route[tst.route.length - 1].name : null;
        // isCalc++;
        tst.iBSN = ((currentPosition.latitude > 47.47 && currentPosition.longitude > 22 &&
            currentPosition.latitude < 52.2 && currentPosition.longitude < 28)) ? getInBasicSectorName(currentPosition.latitude, currentPosition.longitude, tst.altitude) : null;
        tst.iBSN && flight.sectors.add(tst.iBSN);
        // if (isCalc === 15) {
        //     isCalc = 0;
        //     if (geolib.isPointInPolygon({
        //         latitude: currentPosition.latitude,
        //         longitude: currentPosition.longitude
        //     }, lvt) && tst.altitude > 2500 && tst.altitude <= 16500) {
        //         flight.sectors.add("ALVT")
        //     } else if (geolib.isPointInPolygon({
        //         latitude: currentPosition.latitude,
        //         longitude: currentPosition.longitude
        //     }, uklv)) {
        //         if (tst.altitude > 32500 && tst.altitude <= 66000) {
        //             flight.sectors.add("ALVC")
        //         } else if (tst.altitude > 6000 && tst.altitude <= 32500) {
        //             flight.sectors.add("ALVW")
        //         }
        //     }
        // }
        pointsArray.push({
            cP: currentPosition,
            c: tst.callsign,
            a: tst.altitude,
            sA: tst.selectedAltitude,
            h: tst.heading,
            sH: tst.bearing,
            isOH: tst.isOnHeading,
            isOO: tst.isOnOrbit,
            isOSt: tst.isOrbitStarted,
            oS: tst.orbitSide,
            s: tst.speed,
            sS: tst.selectedSpeed,
            t: tst.tas,
            gs: tst.gs,
            M: tst.Mach,
            isOS: tst.isOnSpeed,
            r: tst.roc,
            sR: tst.selectedRoc,
            isOR: false,
            nP: tst.nextPoint,
            nPN: nextPoint,
            prP: tst.prevPoint,
            pP: passedPoint,
            sq: tst.squawk,
            isAssumed: tst.isAssumed,
            isAdvanced: tst.isAdvanced,
            isCorrelated: tst.isCorrelated,
            tod: tst.TOD,
            toc: tst.TOC,
            isApp: tst.isApproach,
            isCILS: tst.isClearedILS,
            isGA: tst.isGA,
            isIAA: tst.isInApproachArea,
            minS: tst.minSpeed,
            maxS: tst.maxSpeed,
            maxFL: tst.maxFL,
            calcM: tst.calculatedMach,
            distToAdes: tst.distanceToDestination,
            distToNp: tst.distanceToNextPoint,
            isDesc: tst.isDescending,
            isClimb: tst.isClimbing,
            xfl: tst.xfl,
            exC: tst.exC,
            iBSN: tst.iBSN
        });

    }
    console.log("flight.sectors", flight.sectors, flight.c);
    flight.flight.length = tickValue + 1;
    flight.flight = flight.flight.concat(pointsArray);
    calcPassedPoints(flight, startDate);
    calcWorkload(flight);
    return flight;
}

export const calcWorkload = (flt) => {
    let index = flt.timeShift;
    for (let tickValue = 0; tickValue <= 180; tickValue++) {
        let index = tickValue * 5 + flt.timeShift;
        if (!flt.workload) {
            flt.workload = [];
            flt.workload.length = 180;
            flt.workload.fill(0);
        }

        if (flt.flight[index]) {
            if (flt.flight[index].isAssumed) {
                flt.workload[tickValue] = 2;
                if (Math.abs(flt.flight[index].sA - flt.flight[index].a) > 100) {
                    flt.workload[tickValue] += 5;
                }
                if (flt.flight[index + 5] && !flt.flight[index + 5].isAssumed) {
                    flt.workload[tickValue] += 5;
                }
            }
        }
    }
    // console.log(flt.workload);
}

export const calcPassedPoints = (flight, startDate) => {

    flight.passedPoints = flight.flight.reduce((pp, point) => {
        if (point.pP != null && point.pP != false) {
            let index = flight.flight.indexOf(point);
            let pointTime = new Date(startDate +
                ((index - flight.timeShift) * 4000)).toLocaleTimeString('es-ES');
            if (flight.entryCop === point.pP) flight.entryTime = pointTime.substr(0, 5);
            if (flight.exitCop === point.pP) flight.exitTime = pointTime.substr(0, 5);
            pp.push({index: index, pp: point, pt: pointTime})
        }
        return pp;
    }, []);
}
const LatDAndB = (lat1, lon1, brng, d) => {
    let R = 6372.797560856;
    let lat1inRadians = lat1 * Math.PI / 180;
    let lon1inRadians = lon1 * Math.PI / 180;
    let lat2 = Math.asin(Math.sin(lat1inRadians) * Math.cos(d / R) + Math.cos(lat1inRadians) * Math.sin(d / R) * Math.cos(brng * Math.PI / 180));
    let lon2 = lon1inRadians + Math.atan2(Math.sin(brng * Math.PI / 180) * Math.sin(d / R) * Math.cos(lat1inRadians), Math.cos(d / R) - Math.sin(lat1inRadians) * Math.sin(lat2));
    return lat2 * 180 / Math.PI;
}
const LonDAndB = (lat1, lon1, brng, d) => {
    let R = 6372.797560856;
    let lat1inRadians = lat1 * Math.PI / 180;
    let lon1inRadians = lon1 * Math.PI / 180;
    let lat2 = Math.asin(Math.sin(lat1inRadians) * Math.cos(d / R) + Math.cos(lat1inRadians) * Math.sin(d / R) * Math.cos(brng * Math.PI / 180));
    let lon2 = lon1inRadians + Math.atan2(Math.sin(brng * Math.PI / 180) * Math.sin(d / R) * Math.cos(lat1inRadians), Math.cos(d / R) - Math.sin(lat1inRadians) * Math.sin(lat2));
    return lon2 * 180 / Math.PI;
}
export const minSep = (lat1, lon1, brng1, spd1, lat2, lon2, brng2, spd2) => {
    let i, lat3, lon3, lat4, lon4, geo1, geo2;
    let minD = 100000;
    let minDCoord = {};
    let minTime, tempminTime, dTemp, dTemp2, dTemp3;

    for (i = 30; i < 1830; i += 30) {
        // lat3 = LatDAndB(lat1, lon1, brng1, (spd1 / 3600) * i);
        // lon3 = LonDAndB(lat1, lon1, brng1, (spd1 / 3600) * i);
        // lat4 = LatDAndB(lat2, lon2, brng2, (spd2 / 3600) * i);
        // lon4 = LonDAndB(lat2, lon2, brng2, (spd2 / 3600) * i);
        // dTemp = distanceLatLon(lat3, lon3, lat4, lon4);
        geo1 = geolib.computeDestinationPoint(
            {lat: lat1, lon: lon1},
            ((spd1 * 1.852 * 1000) / 60 / 60) * i,
            brng1 + 6
        );
        geo2 = geolib.computeDestinationPoint(
            {lat: lat2, lon: lon2},
            ((spd2 * 1.852 * 1000) / 60 / 60) * i,
            brng2 + 6
        );
        dTemp = geolib.convertDistance(geolib.getDistance(geo1, geo2), "sm");
        // console.log(dTemp, geoDis);

        if (dTemp < minD) {
            minD = dTemp;
            minDCoord = {geo1: geo1, geo2: geo2};
            minTime = i;
        }
    }
    geo1 = geolib.computeDestinationPoint(
        {lat: lat1, lon: lon1},
        ((spd1 * 1.852 * 1000) / 60 / 60) * (minTime + 1),
        brng1 + 6
    );
    geo2 = geolib.computeDestinationPoint(
        {lat: lat2, lon: lon2},
        ((spd2 * 1.852 * 1000) / 60 / 60) * (minTime + 1),
        brng2 + 6
    );
    dTemp2 = geolib.convertDistance(geolib.getDistance(geo1, geo2), "sm");
    // lat3 = LatDAndB(lat1, lon1, brng1, (spd1 / 3600) * (minTime + 1));
    // lon3 = LonDAndB(lat1, lon1, brng1, (spd1 / 3600) * (minTime + 1));
    // lat4 = LatDAndB(lat2, lon2, brng2, (spd2 / 3600) * (minTime + 1));
    // lon4 = LonDAndB(lat2, lon2, brng2, (spd2 / 3600) * (minTime + 1));
    // dTemp2 = distanceLatLon(lat3, lon3, lat4, lon4);
    geo1 = geolib.computeDestinationPoint(
        {lat: lat1, lon: lon1},
        ((spd1 * 1.852 * 1000) / 60 / 60) * (minTime - 1),
        brng1 + 6
    );
    geo2 = geolib.computeDestinationPoint(
        {lat: lat2, lon: lon2},
        ((spd2 * 1.852 * 1000) / 60 / 60) * (minTime - 1),
        brng2 + 6
    );
    dTemp3 = geolib.convertDistance(geolib.getDistance(geo1, geo2), "sm");
    // lat3 = LatDAndB(lat1, lon1, brng1, (spd1 / 3600) * (minTime - 1));
    // lon3 = LonDAndB(lat1, lon1, brng1, (spd1 / 3600) * (minTime - 1));
    // lat4 = LatDAndB(lat2, lon2, brng2, (spd2 / 3600) * (minTime - 1));
    // lon4 = LonDAndB(lat2, lon2, brng2, (spd2 / 3600) * (minTime - 1));
    // dTemp3 = distanceLatLon(lat3, lon3, lat4, lon4);
    tempminTime = minTime;
    if (dTemp2 < dTemp3) {
        for (i = minTime; i < tempminTime + 29; i += 1) {
            // lat3 = LatDAndB(lat1, lon1, brng1, (spd1 / 3600) * i);
            // lon3 = LonDAndB(lat1, lon1, brng1, (spd1 / 3600) * i);
            // lat4 = LatDAndB(lat2, lon2, brng2, (spd2 / 3600) * i);
            // lon4 = LonDAndB(lat2, lon2, brng2, (spd2 / 3600) * i);
            // dTemp = distanceLatLon(lat3, lon3, lat4, lon4);
            geo1 = geolib.computeDestinationPoint(
                {lat: lat1, lon: lon1},
                ((spd1 * 1.852 * 1000) / 60 / 60) * i,
                brng1 + 6
            );
            geo2 = geolib.computeDestinationPoint(
                {lat: lat2, lon: lon2},
                ((spd2 * 1.852 * 1000) / 60 / 60) * i,
                brng2 + 6
            );
            dTemp = geolib.convertDistance(geolib.getDistance(geo1, geo2), "sm");

            if (dTemp < minD) {
                minD = dTemp;
                minDCoord = {geo1: geo1, geo2: geo2};
                minTime = i;
            }
        }
    }
    if (dTemp2 > dTemp3) {
        for (i = minTime; i > tempminTime - 29; i -= 1) {
            // lat3 = LatDAndB(lat1, lon1, brng1, (spd1 / 3600) * i);
            // lon3 = LonDAndB(lat1, lon1, brng1, (spd1 / 3600) * i);
            // lat4 = LatDAndB(lat2, lon2, brng2, (spd2 / 3600) * i);
            // lon4 = LonDAndB(lat2, lon2, brng2, (spd2 / 3600) * i);
            // dTemp = distanceLatLon(lat3, lon3, lat4, lon4);
            geo1 = geolib.computeDestinationPoint(
                {lat: lat1, lon: lon1},
                ((spd1 * 1.852 * 1000) / 60 / 60) * i,
                brng1 + 6
            );
            geo2 = geolib.computeDestinationPoint(
                {lat: lat2, lon: lon2},
                ((spd2 * 1.852 * 1000) / 60 / 60) * i,
                brng2 + 6
            );
            dTemp = geolib.convertDistance(geolib.getDistance(geo1, geo2), "sm");
            //trace("i:"+i);
            if (dTemp < minD) {
                minD = dTemp;
                minDCoord = {geo1: geo1, geo2: geo2};
                minTime = i;
            }
        }
    }
    geo1 = geolib.computeDestinationPoint(
        {lat: lat1, lon: lon1},
        ((spd1 * 1.852 * 1000) / 60 / 60) * (minTime + 60),
        brng1 + 6
    );
    geo2 = geolib.computeDestinationPoint(
        {lat: lat2, lon: lon2},
        ((spd2 * 1.852 * 1000) / 60 / 60) * (minTime + 60),
        brng2 + 6
    );
    let minDCoord2 = {geo1: geo1, geo2: geo2};
    let minSepDist = (parseInt(minD * 10) / 10).toString();
    let minSepTimeSec = minTime;
    let minSepTime;
    if (minTime < 3) {
        minSepTime = "";
    } else {
        let tempSec = parseInt(((minTime / 60) - parseInt(minTime / 60)) * 60);
        if (tempSec < 10) {
            minSepTime = (parseInt(minTime / 60)).toString() + "'0" + (tempSec).toString();
        }
        if (tempSec > 9) {
            minSepTime = (parseInt(minTime / 60)).toString() + "'" + (tempSec).toString();
        }
    }
    return {
        minSepDist: minSepDist, minSepTimeSec: minSepTimeSec, minSepTime: minSepTime, minDCoord: minDCoord,
        minDCoord2: minDCoord2
    };

}

export const download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export const calc = {
    toRadians: (d) => {
        return d * (Math.PI / 180);
    },
    toDegrees: (r) => {
        return r / (Math.PI / 180);
    },
    getDiffBetweenAngles: (a, b) => {
        a = calcToRadians(a % 360);
        b = calcToRadians(b % 360);
        return calcToDegrees(
            Math.acos(Math.cos(a) * Math.cos(b) + Math.sin(b) * Math.sin(a))
        )
    }
}
let calcToRadians = calc.toRadians;
let calcToDegrees = calc.toDegrees;

export const checkIls = (flight) => {
// let approachArea = [
//     flight.
// ];
}

export const getFlightColor = (flight) => {
    // if (flight[index] !== null && flight.length > index && index >= 0) {
    //     return flight[index].isAssumed ? ASSUMED_COLOR :
    //         flight[index].isAdvanced ? ADVANCED_COLOR :
    //             flight[index].isCorrelated ? UNCONCERNED_COLOR : SSR_COLOR;
    // } else {
    //     return SSR_COLOR
    // }
    return flight ? (flight.isAssumed ? ASSUMED_COLOR :
        flight.isAdvanced ? ADVANCED_COLOR :
            flight.isCorrelated ? UNCONCERNED_COLOR : SSR_COLOR) : SSR_COLOR;
}

export const getIsInSector = (latitude, longitude, altitude, sectors) => {
    return geolib.isPointInPolygon({
        latitude: latitude,
        longitude: longitude
    }, sectors.ops.name === 'lvt' ? lvt : sectors.ops.name === 'lve' ? lve : sectors.ops.name === 'lvw' ? lvw : uklv) && altitude > sectors.ops.vLimit.lower && altitude <= sectors.ops.vLimit.upper;
}

export const calcExerciseWorkload = (flights, sectors, isPlt) => {
    let inSectorFlights = [];
    let uniqueFlights = new Set();
    for (let i = 0; i <= 900; i += 15) {
        let inSectorTest = [];
        let inSector = flights.filter(flt => {
            let ind;
            ind = i + flt.timeShift;
            if (flt.flight[ind]) {
                let inBasicSectorName = getInBasicSectorName(flt.flight[ind].cP.latitude, flt.flight[ind].cP.longitude, flt.flight[ind].a);
                if (inBasicSectorName) {
                    if (sectors.ops.basicSectors.includes(inBasicSectorName)) {
                        uniqueFlights.add(flt.c);
                        return inBasicSectorName;
                    }
                }
            } else return null
        })
        inSectorFlights.push(inSector.length);
        inSectorTest.push(inSector);
    }
    return {inSectorFlights, uniqueFlights};

}
export const getInBasicSectorName = (latitude, longitude, altitude) => {
    let tmp = {lvt: lvt, lve: lve, lvw: lvw, uklv: uklv}
    let inSectorName = basicSectors.filter(bs => altitude > bs.vLimit.lower && altitude <= bs.vLimit.upper)
        .filter(ts =>
            geolib.isPointInPolygon({
                latitude: latitude,
                longitude: longitude
            }, tmp[ts.name])
        );
    // console.log('insectorName', inSectorName[0] ? inSectorName[0].sector : "");
    return inSectorName[0] ? inSectorName[0].sector : null;
}

const basicSectors = [
    {
        name: "lvt",
        sector: "LVTM",
        vLimit: {lower: 2500, upper: 16500}
    },
    {
        name: "lve",
        sector: "E325",
        vLimit: {lower: 6000, upper: 32500}
    },
    {
        name: "lve",
        sector: "E345",
        vLimit: {lower: 32500, upper: 34500}
    },
    {
        name: "lvw",
        sector: "W325",
        vLimit: {lower: 6000, upper: 32500}
    },
    {
        name: "lvw",
        sector: "W345",
        vLimit: {lower: 32500, upper: 34500}
    },
    {
        name: "uklv",
        sector: "C355",
        vLimit: {lower: 34500, upper: 35500}
    },
    {
        name: "uklv",
        sector: "C365",
        vLimit: {lower: 35500, upper: 36500}
    },
    {
        name: "uklv",
        sector: "C375",
        vLimit: {lower: 36500, upper: 37500}
    },
    {
        name: "uklv",
        sector: "C385",
        vLimit: {lower: 37500, upper: 38500}
    },
    {
        name: "uklv",
        sector: "C660",
        vLimit: {lower: 38500, upper: 66000}
    }
];

