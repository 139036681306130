/*
    node_js
    2/1/2021 8:05 PM
    by Oleksandr
*/

import './AccLabel.css';
import {useEffect, useRef, useState} from "react";
import {calcFlight} from "../../../../calc/calc";
import RonWindow from "../windows/modalWindows/RonWindow";
import {useDrag} from "react-use-gesture";
import {SELECTED_COLOR} from "../colors/colors";
import Field from "./Field";
import {onLabelMove} from "../target/ConnectingLine";

function useKey(key) {
    // Keep track of key state
    const [pressed, setPressed] = useState(false)

    // Does an event match the key we're watching?
    const match = event => key.toLowerCase() == event.key.toLowerCase()

    // Event handlers
    const onDown = event => {
        if (match(event)) setPressed(true)
    }

    const onUp = event => {
        if (match(event)) setPressed(false)
    }

    // Bind and unbind events
    useEffect(() => {
        window.addEventListener("keydown", onDown)
        window.addEventListener("keyup", onUp)
        return () => {
            window.removeEventListener("keydown", onDown)
            window.removeEventListener("keyup", onUp)
        }
    }, [key])

    return pressed
}


const AccLabel = (props) => {
    let {
        track, color, callsignColor, updateDxDy, size, toggleDfl, toggleCflWindow, toggleDctWindow,
        addRbl, finderData,
        index, left, top,
        setSelectedFlight, selectedFlight, fltId,
        flight, updateFlights, timeOfUpdate,
        isStca, tickValue,
        target, updateLabelPos, isPlt, isEpp,
        assumeFlight, decontrolFlight, showCallsignMenu,
        showFlightMenu, addTrackPlan, isCon, conAssumeFlight, startDate,
        isMtcd
    } = props;
    ///randome delay const
    const randomDelayConst = 0;
    let connectingLine = document.getElementById("connectingLine" + fltId);
    const label = useRef();
    const [color2, setColor2] = useState('rgb(105,170,170)');
    const [colorBorder, setColorBorder] = useState('rgba(105,170,170, 0)');
    const [openRON, setOpenRON] = useState(false);
    const [{dx, dy}, setDxDy] = useState({dx: flight.dx, dy: flight.dy});
    const [isHover, setIsHover] = useState(false);

    const shift = useKey('shift');
    const f9 = useKey('F9');
    const enter = useKey('enter');
    useEffect(() => {
        if (isHover && !shift) addRbl(fltId, null, null, true);
    }, [shift])
    useEffect(() => {
        if (isHover && !f9) addRbl(fltId, null, null, true);
    }, [f9])

    useEffect(() => {
        if ((isPlt || isEpp) && isHover && !enter && !flight.flight[index].isAssumed) {
            assumeFlight(flight, index);
            // calcFlight(flight, index);
            ///TODO create redraw flights or flight
            // updateFlights();
        }
        if (isCon && isHover && !enter && !flight.isAssumed) {
            conAssumeFlight(flight, index);
            updateFlights();
        }
    }, [enter])
    const bind = useDrag(({buttons, delta: [deltaX, deltaY], last}) => {
        if (buttons === 2) {
            setDxDy({dx: dx + deltaX, dy: dy + deltaY});
            if (label != undefined)
                onLabelMove({
                        left: parseInt(left) + dx, top: parseInt(top) + dy, clientHeight: label.current.clientHeight,
                        clientWidth: label.current.clientWidth
                    },
                    {left, top}, connectingLine);
        }
    })
    let selectFlight = () => {
        // selectedFlight==id ?  setSelectedFlight(id):
        setSelectedFlight(fltId);
        // setColor2('rgb(200,170,170)');
        // console.log("AccLabel click");
        // updateFlight(id);
        // flight.timeShift += 10;
    }

    useEffect(() => {
        isStca ? setColorBorder('rgb(248,2,2)') :
            selectedFlight === fltId || isHover ? setColorBorder(SELECTED_COLOR) : setColorBorder('rgba(105,170,170, 0)');
    }, [selectedFlight, isHover]);
    useEffect(() => {
        isStca ? setColorBorder('rgb(248,2,2)') : setColorBorder('rgba(105,170,170, 0)');
    }, [isStca]);
    const handleRON = (flight) => {

    }
    let tod = flight.flight[index].tod ? [<Field text='TOD' style={{color: 'yellow'}}/>] : null;
    let mtcd = isMtcd ? [<Field text='MC' style={{color: 'red'}}/>] : null;
    let emrg = (flight.flight[index].sq === '7700' || flight.flight[index].sq === '7600' || flight.flight[index].sq === '7500') ?
        <Field text={flight.flight[index].sq} style={{color: 'red'}}/> : null;
    // if (connectingLine)
    //     console.log(connectingLine)
    // onLabelMove({
    //         left: parseInt(left) + dx, top: parseInt(top) + dy, clientHeight: label.current.clientHeight,
    //         clientWidth: label.current.clientWidth
    //     },
    //     {left, top}, connectingLine);
    return <>
        <div ref={label} {...bind()} className="AccLabel" id={"acclabel_" + fltId} //onClick={selectFlight}
             onMouseEnter={() => {
                 setIsHover(true);
             }}
             onMouseLeave={() => {
                 setIsHover(false);
             }}
             style={{
                 // lineHeight: 10 + 2 * size + "px",
                 // left: track.X + dx + "px",
                 // top: track.Y + dy + "px",
                 // textAlign: "left",
                 // position: "absolute",
                 // userSelect: "none",
                 left: parseInt(left) + dx + 'px',
                 top: parseInt(top) + dy + 'px',
                 color: color,
                 border: '1px solid ' + colorBorder
                 // fontSize: 10 + 2 * size + "px",
                 // border: track.isStca ? "1px solid red" : "none"
             }}
            // id={"label" + track.id}
        >{emrg} {tod} {mtcd} {(emrg || tod || mtcd) && <br/>}

            <Field text={flight.flight[index].c} style={{color: callsignColor}}
                   onMouseUp={(e) => {
                       if (e.button === 0) {
                           showCallsignMenu(flight, e.clientX, e.clientY);
                       }
                       if (e.button === 1) {
                           e.nativeEvent.stopPropagation();
                           e.nativeEvent.preventDefault();
                           addRbl(fltId, null, null, true)
                       }
                       ;
                   }}
            />
            {(isHover || selectedFlight === fltId) && <Field text={flight.flight[index].sq}
                                                             onClick={() => {
                                                                 if (!isPlt) return
                                                                 let promptSquawk = window.prompt('New Squawk:');
                                                                 if (promptSquawk === null) return;
                                                                 let randDelay = Math.round(Math.random() * randomDelayConst);
                                                                 flight.flight[index + randDelay].sq = promptSquawk;
                                                                 calcFlight(flight, index + randDelay, startDate.getTime());
                                                             }}/>}
            {(isPlt && flight.flight[index].isIAA) && <span>ILS</span>}
            <br/>
            <Field text={Math.round(flight.flight[index].a / 100)}/>
            <span>{Math.abs(flight.flight[index].a - flight.flight[index].sA) >= 100 ?
                flight.flight[index].isClimb ? '\u2191' : flight.flight[index].isDesc ? '\u2193' : '' : ''}</span>
            {(isPlt || isEpp) && <Field text={Math.round(flight.flight[index].sA / 100)}
                                        style={{
                                            opacity: isHover ? 1 : Math.abs(flight.flight[index].a - flight.flight[index].sA) >= 100 ?
                                                1 : 0
                                        }}
                //            (isHover || (Math.abs(flight.flight[index].a - flight.flight[index].sA) >= 100)) ?
                //                Math.round(flight.flight[index].sA / 100) :
                //                '___'}
                                        onClick={(e) => {
                                            if (!isPlt) return
                                            showFlightMenu(flight, e.clientX, e.clientY, 'sfl');
                                        }}/>}
            {isCon && <Field text={flight.cfl}
                             style={{
                                 opacity: isHover || (Math.abs(flight.flight[index].a - flight.cfl * 100) >= 100) ?
                                     1 : 0
                             }}
                             onClick={(e) => {
                                 if (!isCon) return
                                 showFlightMenu(flight, e.clientX, e.clientY, 'cfl');
                             }}/>}
            <Field text={flight.flight[index].xfl}
                   style={{
                       opacity: isHover || ((isEpp || isPlt) && Math.abs(flight.flight[index].sA - flight.flight[index].xfl * 100) >= 100) ||
                       (isCon && Math.abs(flight.cfl - flight.flight[index].xfl) >= 1)?
                           1 : 0
                   }}/>
            <br/>
            {(isHover || selectedFlight === fltId) &&
            <>
                <Field text={Math.round(flight.flight[index].t)}/>
                <Field text={flight.flight[index].isOO} onMouseUp={(e) => {
                    console.log(e.button)
                    flight.flight[index].isOO = true;
                    flight.flight[index].isOSt = false;
                    flight.flight[index].oS = e.button === 0 ? 'L' : 'R';
                    flight.flight[index].sH = flight.flight[index].h;
                    calcFlight(flight, index, startDate.getTime());
                }
                }/>
                <Field text={flight.flight[index].nPN}
                       onClick={(e) => {
                           if (!isPlt) return
                           showFlightMenu(flight, e.clientX, e.clientY, 'dct');
                           // setOpenRON(true);
                       }}
                       onMouseUp={(e) => {
                           if (e.button === 2) {
                               addTrackPlan(fltId);
                           }
                       }}
                />
                <span>{flight.fpl[3].substr(0, 4)}</span>
                <br/>
            </>}
            {((isHover || selectedFlight === fltId) && !isCon) &&
            <>
                <Field text={'h' + Math.round(flight.flight[index].h) +
                ((Math.round(flight.flight[index].h) === Math.round(flight.flight[index].sH)) ?
                    '' : '>' + parseInt(flight.flight[index].sH))}
                       onClick={(e) => {
                           if (!isPlt) return
                           showFlightMenu(flight, e.clientX, e.clientY, 'hdg');

                       }}
                       onMouseUp={(e) => {
                           if (e.button === 2) {
                               addTrackPlan(fltId);
                           }
                       }}
                />
                <Field text={'r' + parseInt(flight.flight[index].r * 1.5) * 10}/>
                {(flight.flight[index].a < 25000) ?
                    <Field text={'s' + Math.round(flight.flight[index].s) +
                    ((Math.abs(flight.flight[index].s - flight.flight[index].sS) > 1)
                        ? '>' + Math.round(flight.flight[index].sS)
                        : '')}
                           onClick={(e) => {
                               if (!isPlt) return
                               showFlightMenu(flight, e.clientX, e.clientY, 'spd');
                           }}
                    />
                    :
                    <Field text={'M' + parseFloat(flight.flight[index].calcM).toFixed(2) +
                    ((Math.abs(flight.flight[index].M - flight.flight[index].sM) >= 0.01)
                        ? flight.flight[index].M
                        : '')}
                           onClick={(e) => {
                               if (!isPlt) return
                               showFlightMenu(flight, e.clientX, e.clientY, 'mach');
                           }}
                    />}<br/>

            </>}
            <RonWindow open={openRON} flight={flight} tickValue={index} onClose={(value) => {
                setOpenRON(false);
                if (value === null) return;
                let randDelay = Math.round(Math.random() * randomDelayConst);
                let ronPointIndex = flight.fpl[10].map(e => e.name).indexOf(value.toUpperCase());
                if (ronPointIndex >= 0) {
                    for (let i = 0; i <= randDelay; i++) {
                        console.log(i, randDelay);
                        flight.flight[index + i].nP = ronPointIndex;
                        flight.flight[index + i].isOH = false;
                    }

                    calcFlight(flight, index + randDelay, startDate.getTime());
                }
            }}/>

        </div>
    </>
}
export default AccLabel;