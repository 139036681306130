/*
    node_js
    5/19/2021 12:08 PM
    by Oleksandr
*/

import Window from "./Window";
import {calcExerciseWorkload} from "../../../../calc/calc"
import React, {useEffect, useRef, useState} from "react";
import {Chart} from 'react-charts';
import * as geolib from "geolib";

const WorkloadWindow = (props) => {
    const {
        setPttWindows, pttWindows, trackNum, tickValue, flights, workloadArray, isEpp, isPlt, sectors,
        setExerciseWorkload, setTickValue, setTimeValue, assumeFlight, dispatchFlightClearance, startDate, setConflictInfoList, conflictInfoList,
        setWorkloadArray, setConflictNumberList, conflictNumberList, inSectorFlights, setInSectorFights, updateFlightsTime, timeOfUpdate
    } = props;
    const [textArr, setTextArr] = useState([]);
    const timeout = useRef(null);
    const series = React.useCallback(
        (s, i) => ({
            type:
                (i === 0 || i === 1)
                    ? 'line'
                    : 'bar'
        }),
        []
    );
    // const getSeriesStyle = React.useCallback(
    //     (s, i) => (
    //         {
    //             color: (i !== 0 || i !== 1) && 'red',
    //             opacity: 1
    //         }), [activeSeriesIndex]
    // )
    useEffect(() => {
        clearTimeout(timeout.current);
        console.log("clearTimeout");
        // eslint-disable-next-line no-unused-expressions
        timeout.current = setTimeout(() => {
            console.log("setTimeout");
            let calExerciseData = calcExerciseWorkload(flights, sectors, isPlt);
            setInSectorFights(calExerciseData.inSectorFlights);
            setExerciseWorkload({
                ops: sectors.ops,
                numberOfFlights: calExerciseData.uniqueFlights.size,
                trafficVolume: calExerciseData.inSectorFlights
            });
            evaluate(flights, conflictNumberList);
        }, 1000);
        // console.log(calExerciseData.uniqueFlights.size, calExerciseData.uniqueFlights, updateFlightsTime, timeOfUpdate);
        // console.log(inSectorFlights)
    }, [flights, conflictNumberList, sectors, updateFlightsTime, timeOfUpdate])

    useEffect(() => {
        let tempTextArr = [];
        let tempConflict = new Array(61).fill(0);
        Array.from(conflictInfoList).forEach(conflict => {
            let tStart = parseInt((conflict.flight1StartIndex - conflict.flight1.timeShift) / 15);
            let tEnd = parseInt((conflict.flight1EndIndex - conflict.flight1.timeShift) / 15);
            for (let i = tStart; i <= tEnd; i++) {
                tempConflict[i] += 1;
            }
        });
        console.log("tempConflict", tempConflict, conflictInfoList)
        const axes = [
            {primary: true, type: 'linear', position: 'bottom'},
            {type: 'linear', position: 'left'}
        ];
        const data = [
            {
                label: 'Workload',
                data: workloadArray.map((val, ind) => {
                    return [ind, val[1]]
                }).filter(val => val[1]),
                color: '#00ff22'
            },
            {
                label: 'Flights',
                data: inSectorFlights.map((val, ind) => {
                    return [ind, val]
                }).filter(val => val[0]),
                color: '#0055ff'
            },
            {
                label: 'Conflicts',
                data: tempConflict.map((val, ind) => {
                    return [ind, val]
                }).filter(val => val[0]),
                color: '#ff0000'
            }
        ];
        tempTextArr.unshift(<div style={{width: '950px', height: '300px', paddingLeft: '5px'}}>
            <Chart data={data} axes={axes} series={series} tooltip onClick={evt => {
                if (evt && evt.primary >= 0) {
                    setTimeValue(evt.primary * 60);
                    setTickValue(evt.primary * 15);
                }
            }}/>
        </div>);
        // tempTextArr.push(<button onClick={(e) => {
        //     evaluate(flights, conflictNumberList);
        //     console.log("Evaluate")
        // }}>Evaluate</button>);

        setTextArr(tempTextArr);
    }, [workloadArray, inSectorFlights, conflictInfoList]);

    const evaluate = (flights, conflictNumberList) => {
        let workloadArr = [];
        // for (let i = 0; i < flights.length; i++) {
        //     let ind1 = tickValue + flights[i].timeShift;
        // flights[i].flight[0].isAdvanced = false;
        // calcFlight(flights[i], 0, startDate.getTime());
        // let tempSetOfBasicSetors = new Set();
        // for (let ii = ind1; ii < flights[i].flight.length; ii += 15) {
        //     if (flights[i].flight[ii] && flights[i].flight[ii].iBSN) tempSetOfBasicSetors.add(flights[i].flight[ii].iBSN);
        // }
        // let arrayOfBasicSetors = Array.from(tempSetOfBasicSetors);
        // arrayOfBasicSetors.forEach(a => {
        //     if (sectors.ops.basicSectors.includes(a)) flights[i].flight[0].isAdvanced = true;
        // });
        // calcFlight(flights[i], 0, startDate.getTime());
        // }
        // for (let tickValue = 0; tickValue < 900; tickValue += 5) {
        //     for (let i = 0; i < flights.length; i++) {
        //         let ind1 = tickValue + flights[i].timeShift;
        //         if (flights[i].flight[ind1]) {
        //             if (flights[i].flight[ind1].iBSN && sectors.ops.basicSectors.includes(flights[i].flight[ind1].iBSN) && !flights[i].flight[ind1].isAssumed) {
        //                 // assumeFlight(flights[i], tickValue);
        //                 // if (flights[i].flight[ind1].isAdvanced) {
        //                 if (flights[i].flight[ind1].isAssumed) return;
        //                 console.log("assumed", tickValue, ind1);
        //                 flights[i].flight[ind1].isAssumed = true;
        //                 flights[i].flight[ind1].isAdvanced = false;
        //                 // } else if (flights[i].flight[ind1].isAssumed) {
        //                 //     flights[i].flight[ind1].isAssumed = false;
        //                 // } else if (flights[i].flight[ind1].isCorrelated) {
        //                 //     flights[i].flight[ind1].isAssumed = true;
        //                 //     flights[i].flight[ind1].isAdvanced = false;
        //                 // }
        //                 calcFlight(flights[i], ind1, startDate.getTime());
        //                 console.log("assume", flights[i].c, flights[i].flight[ind1].iBSN);
        //             } else if ((!flights[i].flight[ind1].iBSN || !sectors.ops.basicSectors.includes(flights[i].flight[ind1].iBSN)) && flights[i].flight[ind1].isAssumed) {
        //                 // assumeFlight(flights[i], tickValue);
        //                 // if (flights[i].flight[ind1].isAdvanced) {
        //                 //     flights[i].flight[ind1].isAssumed = true;
        //                 //     flights[i].flight[ind1].isAdvanced = false;
        //                 // } else if (flights[i].flight[ind1].isAssumed) {
        //                 flights[i].flight[ind1].isAssumed = false;
        //                 calcFlight(flights[i], ind1, startDate.getTime());
        //
        //                 // flights[i].flight[ind1].isAdvanced = true;
        //                 let tempSetOfBasicSetors = new Set();
        //                 for (let ii = ind1 + 5; ii < flights[i].flight.length; ii += 5) {
        //                     flights[i].flight[ii].iBSN && tempSetOfBasicSetors.add(flights[i].flight[ii].iBSN);
        //                 }
        //                 let arrayOfBasicSetors = Array.from(tempSetOfBasicSetors);
        //                 console.log("arrayOfBasicSetors", arrayOfBasicSetors, flights[i].flight[ind1].c);
        //                 arrayOfBasicSetors.forEach(a => {
        //                     console.log("arrayOfBasicSetors", a, sectors.ops.basicSectors);
        //                     if (sectors.ops.basicSectors.includes(a)) flights[i].flight[ind1].isAdvanced = true;
        //                 });
        //                 // } else if (flights[i].flight[ind1].isCorrelated) {
        //                 //     flights[i].flight[ind1].isAssumed = true;
        //                 //     flights[i].flight[ind1].isAdvanced = false;
        //                 // }
        //                 calcFlight(flights[i], ind1, startDate.getTime());
        //             }
        //             if ((flights[i].flight[ind1].tod || flights[i].flight[ind1].toc) && flights[i].flight[ind1].isAssumed) {
        //                 // dispatchFlightClearance({
        //                 //     type: 'sfl',
        //                 //     value: flights[i].flight[ind1].xfl * 100,
        //                 //     flight: flights[i],
        //                 //     index: tickValue,
        //                 //     startDate
        //                 // });
        //                 flights[i].flight[ind1].sA = flights[i].flight[ind1].xfl * 100;
        //                 calcFlight(flights[i], ind1, startDate.getTime())
        //             }
        //         }
        //     }
        // }

        let conflictsTable = [];
        let conflictInfoList = new Set();
        for (let i = 0; i < flights.length; i++) {
            for (let j = i + 1; j < flights.length; j++) {
                let tvInterval = 5;
                let tickValue = 0;
                let tv = tickValue;
                let isConflict = false;
                let lookAheadTime = 900;
                let flightConflict = [];
                let conflictInfo = {};
                while (tv < tickValue + lookAheadTime && tv < 900) {

                    let ind1 = tv + flights[i].timeShift;
                    let ind2 = tv + flights[j].timeShift;
                    if (flights[i].flight[ind1] && flights[j].flight[ind2] &&
                        (flights[i].flight[ind1].isAssumed || flights[j].flight[ind2].isAssumed)) {
                        let dist = geolib.convertDistance(geolib.getDistance(
                            flights[i].flight[ind1].cP,
                            flights[j].flight[ind2].cP), "sm");
                        if (dist <= 7 && Math.abs(flights[i].flight[ind1].a - flights[j].flight[ind2].a) < 950) {
                            if (isConflict === false) {
                                conflictInfo.flight1 = flights[i];
                                conflictInfo.flight2 = flights[j];
                                conflictInfo.flight1StartIndex = ind1;
                                conflictInfo.flight2StartIndex = ind2;
                                conflictInfo.flight1StartAltitude = flights[i].flight[ind1].a;
                                conflictInfo.flight2StartAltitude = flights[j].flight[ind2].a;
                                conflictInfo.startTickValue = tv;
                                conflictInfo.minDist = dist;
                                // conflictInfo.flight1Positions = [];
                                // conflictInfo.flight2Positions = [];
                                console.log('startOfConflict');
                            }
                            // console.log(tv, flights[i].c, flights[j].c, dist);
                            // conflictInfo.flight1Positions[ind1] = flights[i].flight[ind1].cP;
                            // conflictInfo.flight2Positions[ind2] = flights[j].flight[ind2].cP;
                            conflictInfo.minDist = conflictInfo.minDist < dist ? conflictInfo.minDist : dist;
                            flightConflict[parseInt(tv / 15)] = 1;
                            tvInterval = 1;
                            isConflict = true;
                        } else {
                            if (isConflict === true) {
                                endConflict(conflictInfo, conflictInfoList, flights[i], flights[j], ind1, ind2, tv);
                                console.log('EndOfConflict');
                            }
                            tvInterval = 5;
                            isConflict = false;
                        }
                    } else if (isConflict === true) {
                        endConflict(conflictInfo, conflictInfoList, flights[i], flights[j], ind1, ind2, tv);
                        console.log('EndOfConflict');
                        isConflict = false;
                    }
                    tv += tvInterval;
                }
                if (isConflict === true) {
                    endConflict(conflictInfo, conflictInfoList, flights[i], flights[j], 899 + flights[i].timeShift, 899 + flights[i].timeShift, 899);
                    console.log('EndOfConflict');
                    isConflict = false;
                }
                if (flightConflict.length > 0) conflictsTable.push(flightConflict);
            }
        }
        for (let tickValue = 0; tickValue < 900; tickValue += 15) {
            if (tickValue % 15 === 0) {
                conflictNumberList.length = tickValue / 15;
                conflictNumberList[tickValue / 15] = 0;
                let assumedNumber = flights.reduce(([acc, wl], curVal) => {
                    let tvi = tickValue + curVal.timeShift
                    if (curVal.flight[tvi]) {
                        if (curVal.flight[tvi].isAssumed) {
                            acc += 1;
                            if (curVal.workload[tickValue / 5]) {
                                wl += curVal.workload[tickValue / 5];
                            }
                        }
                    }
                    return [acc, wl];
                }, [0, 0]);
                // console.log(workloadArr)
                //
                // console.log('isAssumedNumber', assumedNumber);
                if (conflictsTable) {
                    conflictsTable.forEach((val, ind) => {
                        console.log(val, val[tickValue / 15]);
                        if (val[tickValue / 15] === 1) {
                            conflictNumberList[tickValue / 15] += 1;
                        }
                    });
                    assumedNumber[1] += (conflictNumberList[tickValue / 15] * 10);
                }
                workloadArr.length = tickValue / 15;
                workloadArr.push(assumedNumber)
            }
        }
        setWorkloadArray(workloadArr);
        setConflictNumberList(conflictNumberList);
        setConflictInfoList(conflictInfoList);
        // console.log("conflicts", conflictsTable, conflictNumberList, conflictInfoList);
    }

    // if (false) {
    //
    //     tempTextArr = workloadArray.map((wl, ind) => {
    //         return <div style={{float: "left", border: "1px solid grey"}}><span
    //             style={{padding: "5px"}}>{ind}</span><br/><span>{wl[0]}</span><br/><span>{wl[1]}</span></div>
    //     });
    //
    //     tempTextArr.unshift(<div style={{float: "left"}}>
    //         <span>Time: </span><br/><span>Flights: </span><br/><span>Workload: </span></div>);
    // }


    return <Window toggleWindow={() => {
        setPttWindows({...pttWindows, workloadWindow: false});
    }
    } label={'Workload'} width={1000}
                   content={textArr}
    />
}

const endConflict = (conflictInfo, conflictInfoList, flightsI, flightsJ, ind1, ind2, tv) => {
    conflictInfo.flight1EndIndex = ind1;
    conflictInfo.flight2EndIndex = ind2;
    conflictInfo.flight1EndAltitude = flightsI.flight[ind1].a;
    conflictInfo.flight2EndAltitude = flightsJ.flight[ind2].a;
    // conflictInfo.flight1Positions[ind1] = flightsI.flight[ind1].cP;
    // conflictInfo.flight2Positions[ind2] = flightsJ.flight[ind2].cP;
    conflictInfo.endTickValue = tv;
    conflictInfoList.add(conflictInfo);
    conflictInfo = {};
}

export default WorkloadWindow;