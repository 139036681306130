/*
    node_js
    2/1/2021 7:11 PM
    by Oleksandr
*/

import React, {useContext, useEffect} from "react";
import {CwpContext} from "../../contexts/CwpContext";
import FDP from "./FDP";

let q = false;
const ATG = (props) => {
    const {isPlt} = props;
    const context = useContext(CwpContext);
    const {tickValue, setTickValue, timeValue, setTimeValue, tickSpeed, tickPause} = context;

    useEffect(() => {
        const interval = setInterval(() => {
            if (!tickPause) {
                setTimeValue(timeValue + 1);
                if (timeValue % 4 === 3) {
                    setTimeValue((tickValue + 1) * 4);
                    setTickValue(tickValue + 1);
                }
            }
        }, tickSpeed);
        return () => clearInterval(interval);
    }, [timeValue, setTimeValue, tickValue, setTickValue, tickSpeed, tickPause]);

    return (
        <FDP {...props} {...context} />
    )
}

export default ATG;