/*
    node_js
    2/25/2021 3:54 AM
    by Oleksandr
*/

import {distanceLatLon, minSep} from "../../../../calc/calc";
import Rbl from "./Rbl";
import React from "react";
import * as geolib from "geolib";
import MinSep from "./MinSep";

const Rbls = (props) => {
    const {rbls, projection, tickValue, flights, deleteRbl, minSepRbl} = props;

    return (
        <>
            {rbls.map((rbl) => {
                let x1, y1, x2, y2, x3, y3, x4, y4, x5, y5, x6, y6;
                let lat1, lon1, lat2, lon2;
                let brng1, spd1, minSepText, minSepDist, timeText;
                let minSepTimeText = null;
                let minSep0 = null;
                if (rbl.p1isFlight) {
                    let flight1Index = flights.map(e => e.id).indexOf(rbl.p1Id);
                    if (flight1Index < 0) return;
                    let flight1 = flights[flight1Index].flight;
                    let index = tickValue + flights[flight1Index].timeShift;
                    if (index > flight1.length - 1) index = flight1.length - 1;
                    if (index < 0) index = 0;
                    if (!flight1[index]) return;
                    brng1 = flight1[index].h;
                    spd1 = flight1[index].t;
                    lat1 = flight1[index].cP.latitude;
                    lon1 = flight1[index].cP.longitude;
                    let pos = projection([lon1, lat1]);
                    y1 = pos[1];
                    x1 = pos[0];
                }
                if (rbl.p2isFlight) {
                    let flight2Index = flights.map(e => e.id).indexOf(rbl.p2Id);
                    if (flight2Index < 0) return;
                    let flight2 = flights[flight2Index].flight;
                    let index = tickValue + flights[flight2Index].timeShift;
                    if (index > flight2.length - 1) index = flight2.length - 1;
                    if (index < 0) index = 0;
                    if (!flight2[index]) return;
                    lat2 = flight2[index].cP.latitude;
                    lon2 = flight2[index].cP.longitude;
                    let pos = projection([lon2, lat2]);
                    y2 = pos[1];
                    x2 = pos[0];
                    let brng2 = flight2[index].h;
                    let spd2 = flight2[index].t;
                    if (spd1) {
                        minSep0 = minSep(lat1, lon1, brng1, spd1, lat2, lon2, brng2, spd2)

                        minSepDist = parseFloat(minSep0.minSepDist);
                        minSepTimeText = 'E ' + minSep0.minSepTime;
                        minSepText = 'X ' + minSepDist.toFixed(1);
                    }
                }
                if (rbl.p2CoordXY) {
                    x2 = rbl.p2CoordXY.clientX;
                    y2 = rbl.p2CoordXY.clientY;
                    let coord = projection.invert([x2, y2]);
                    lat2 = coord[1];
                    lon2 = coord[0];
                }
                if (rbl.p1Coord) {
                    lat1 = rbl.p1Coord.lat;
                    lon1 = rbl.p1Coord.lon;
                    let pos = projection([lon1, lat1]);
                    y1 = pos[1];
                    x1 = pos[0];
                }
                if (rbl.p2Coord) {
                    lat2 = rbl.p2Coord.lat;
                    lon2 = rbl.p2Coord.lon;
                    let pos = projection([lon2, lat2]);
                    y2 = pos[1];
                    x2 = pos[0];
                }
                if (!rbl.p2CoordXY && rbl.p2isMoving) return;
                let dist = distanceLatLon(lat1, lon1, lat2, lon2);
                if (spd1) {
                    let time = dist / spd1;
                    let timeMins = (60 * time);
                    timeText = 'T ' + (timeMins < 10 ? '0' + parseInt(timeMins) : parseInt(timeMins)) + "'"
                        + ('0' + parseInt((timeMins - parseInt(timeMins)) * 60)).slice(-2);
                }
                let text = parseFloat(dist);
                let bearingText = (parseInt(geolib.getGreatCircleBearing(
                    {latitude: lat1, longitude: lon1},
                    {latitude: lat2, longitude: lon2},
                )) - 6 + 360) % 360;
                if (rbl.isMinSep && minSep0 && minSep0.minDCoord && minSep0.minDCoord.geo1) {
                    lat1 = minSep0.minDCoord.geo1.latitude;
                    lon1 = minSep0.minDCoord.geo1.longitude;
                    let pos = projection([lon1, lat1]);
                    y5 = pos[1];
                    x5 = pos[0];
                    lat2 = minSep0.minDCoord.geo2.latitude;
                    lon2 = minSep0.minDCoord.geo2.longitude;
                    let pos2 = projection([lon2, lat2]);
                    y6 = pos2[1];
                    x6 = pos2[0];
                    let lat3 = minSep0.minDCoord2.geo1.latitude;
                    let lon3 = minSep0.minDCoord2.geo1.longitude;
                    let pos3 = projection([lon3, lat3]);
                    y3 = pos3[1];
                    x3 = pos3[0];
                    let lat4 = minSep0.minDCoord2.geo2.latitude;
                    let lon4 = minSep0.minDCoord2.geo2.longitude;
                    let pos4 = projection([lon4, lat4]);
                    y4 = pos4[1];
                    x4 = pos4[0];

                    // console.log(lat1, lon1, xm1, ym1);
                }

                if (!rbl.isMinSep) return (<Rbl x1={x1} y1={y1} x2={x2} y2={y2} key={rbl.rblId} id={rbl.rblId}
                                                deleteRbl={(e) => {
                                                    if (e.button === 2) deleteRbl(e);
                                                }}
                                                minSepRbl={minSepRbl}
                                                bearingText={bearingText}
                                                text={text.toFixed(1)}
                                                timeText={(text - minSepDist > 0.1 && minSepTimeText) ? minSepTimeText : timeText}
                                                minSepText={text - minSepDist > 0.1 ? minSepText : ''}/>)
                if (text - minSepDist > 0.1 && rbl.isMinSep) return (<MinSep x1={x1} y1={y1} x2={x2} y2={y2}
                                                                             x3={x3} y3={y3} x4={x4} y4={y4}
                                                                             x5={x5} y5={y5} x6={x6} y6={y6}
                                                                             key={rbl.rblId} id={rbl.rblId}
                                                                             deleteRbl={(e) => {
                                                                                 if (e.button === 2) deleteRbl(e);
                                                                             }}
                                                                             minSepRbl={minSepRbl}
                                                                             bearingText={bearingText}
                                                                             text={text.toFixed(1)}
                                                                             timeText={(text - minSepDist > 0.1 && minSepTimeText) ? minSepTimeText : timeText}
                                                                             minSepText={text - minSepDist > 0.1 ? minSepText : ''}/>)
            })}
        </>
    )
}

export default Rbls;