/*
    node_js
    3/19/2021 9:47 AM
    by Oleksandr
*/

import Window from "./Window";
import React from "react";

const RwyWindow = (props) => {
    const {setPttWindows, pttWindows, runways, setRunways} = props;
    let isRw13 = runways[0].Runways === "13";
    let isRw31 = runways[0].Runways === "31";
    const handleRwy = (e) => {
        runways[0] = {"ICAOcode": "UKLL", "Runways": e.target.value};
        setRunways([...runways, runways]);
    }

    return (
        <>
            <Window label='RUNWAYS' width={150}
                    toggleWindow={() => setPttWindows({...pttWindows, rwyWindow: false})}
                    content={<><span className='buttonSDD' style={{width: "85%"}}>UKLL</span>
                        <div style={{
                            display: "grid", gridTemplateColumns: "20px auto",
                            gridGap: "10px", color: "grey", textAlign: "center", padding: "10px"
                        }}>
                            <span>13</span>
                            <div className="form_radio_btn">
                                <input id="radio-1" type="radio" name="radio" value="13" checked={isRw13}
                                       onClick={handleRwy}/>
                                <label className="buttonSDD" htmlFor="radio-1">Arr/Dep</label>
                            </div>
                            <span>31</span>
                            <div className="form_radio_btn">
                                <input id="radio-2" type="radio" name="radio" value="31" checked={isRw31}
                                       onClick={handleRwy}/>
                                <label className="buttonSDD" htmlFor="radio-2">Arr/Dep</label>
                            </div>
                        </div>
                    </>}
            />
        </>
    )
}

export default RwyWindow;