/*
    node_js
    3/14/2021 7:22 PM
    by Oleksandr
*/
import '../CWP.css';
import React from "react";
import {download} from "../../../calc/calc";
import {getTASfromCAS} from "../../../calc/geometry/airspeedConverter"
import {Link} from "react-router-dom";

let squawks = [];
let assignedSquawks = -1;
for (let i = 1; i < 2; i++) {
    for (let j = 1; j < 2; j++) {
        for (let x = 1; x < 8; x++) {
            for (let z = 1; z < 8; z++) {
                squawks.push(i + '' + j + '' + x + '' + z);
            }
        }
    }
}
const getSquawk2 = () => {
    assignedSquawks++;
    if (assignedSquawks > squawks.length - 1) assignedSquawks = 0;
    return squawks[assignedSquawks];
}

const InfoPanel = (props) => {
    const {
        isPlt, isEpp, isCon, isDemo, pttWindows, setPttWindows,
        flights, saveExercise, exerciseName, startDate, timeValue,
        setSessionId, setIsConnected, isConnected, getAllSim, setSectors, sectors
    } = props;


    return (
        <div className='windowSdd' style={{zIndex: 1000000, top: "0px", position: "fixed", height: "24px"}}>
            <ul>
                <li><Link to='/home' className={'buttonSDD'}>Home</Link></li>
                <li><span className={`buttonSDD ${pttWindows.aswToolbox1Window ? "buttonSDDpressed" : ""}`}
                          onClick={() => setPttWindows({
                              ...pttWindows,
                              aswToolbox1Window: !pttWindows.aswToolbox1Window
                          })}>ASW Toolbox</span></li>
                {(!isCon && !isDemo) &&
                <li><span className={`buttonSDD ${pttWindows.exerciseDescriptionWindow ? "buttonSDDpressed" : ""}`}
                          style={{width: 'auto'}}
                          onClick={() => setPttWindows({
                              ...pttWindows,
                              exerciseDescriptionWindow: !pttWindows.exerciseDescriptionWindow
                          })}>{exerciseName}</span>calc</li>}
                {(isPlt || isEpp) && <>
                    <li><span className={`buttonSDD ${pttWindows.exerciseWindow ? "buttonSDDpressed" : ""}`}
                              onClick={() => setPttWindows({...pttWindows, exerciseWindow: !pttWindows.exerciseWindow})}
                    >Exercise</span></li>
                </>}
                {isEpp && <>
                    <li><span className={`buttonSDD ${pttWindows.activeWindow ? "buttonSDDpressed" : ""}`}
                              onClick={() => setPttWindows({
                                  ...pttWindows,
                                  activeWindow: !pttWindows.activeWindow
                              })}>Active</span></li>
                    <li><span className={`buttonSDD ${pttWindows.historicalWindow ? "buttonSDDpressed" : ""}`}
                              onClick={() => setPttWindows({
                                  ...pttWindows,
                                  historicalWindow: !pttWindows.historicalWindow
                              })}>Historical</span></li>

                    {/*<li><span className={'buttonSDD'} onClick={() => {*/}
                    {/*    console.log("save");*/}
                    {/*    // console.log(flights);*/}
                    {/*    let exerciseJson = [];*/}
                    {/*    flights.forEach((flight) => {*/}
                    {/*        exerciseJson.push({id: flight.id, timeShift: flight.timeShift, rfl: flight.rfl});*/}
                    {/*    });*/}
                    {/*    saveExercise(exerciseJson);*/}
                    {/*}}>Save</span></li>*/}
                </>}
                {(isPlt || isCon) &&
                <li><span className={`buttonSDD ${pttWindows.plannerWindow ? "buttonSDDpressed" : ""}`}
                          onClick={() => setPttWindows({
                              ...pttWindows,
                              plannerWindow: !pttWindows.plannerWindow
                          })}>Planner</span></li>}
                {(isPlt || isEpp) && <>
                    {/*<li><span className={`buttonSDD ${pttWindows.exerciseWindow ? "buttonSDDpressed" : ""}`}*/}
                    {/*          onClick={() => setPttWindows({...pttWindows, exerciseWindow: !pttWindows.exerciseWindow})}*/}
                    {/*>Exercise</span></li>*/}
                    <li><span className={`buttonSDD ${pttWindows.workloadWindow ? "buttonSDDpressed" : ""}`}
                              onClick={() => {
                                  setPttWindows({...pttWindows, workloadWindow: !pttWindows.workloadWindow});
                              }
                              }>Workload</span></li>
                </>}
                {isEpp && <li><span className={'buttonSDD'} onClick={() => {
                    console.log("download");
                    let text = '';
                    flights.forEach((flight) => {
                        let route = '';

                        if (flight.passedPoints) {
                            let entryPointIndex = -1;
                            let entryPointTime = '';
                            if (flight.entryCop) {
                                entryPointIndex = flight.passedPoints.map(e => e.pp.pP).indexOf(flight.entryCop);
                                entryPointTime = new Date(startDate.getTime() +
                                    ((flight.passedPoints[entryPointIndex].index - flight.timeShift) * 4000)).toLocaleTimeString('es-ES');
                                entryPointTime = entryPointTime.substr(0, 2) + entryPointTime.substr(3, 2);
                            }
                            flight.passedPoints.forEach((point, index) => {
                                if (point.pp.pP.length !== 4 && point.pp.pP !== false) {
                                    let time = (index === entryPointIndex) ? '/H' + entryPointTime : '';
                                    route += point.pp.pP + time + ' DCT ';
                                } else if (point.pp.pP === false) {
                                    return;
                                } else {
                                    route = route.substr(0, route.length - 5);
                                }
                            });
                        }


                        let eobt = new Date(startDate.getTime() +
                            (0 - flight.timeShift) * 4000).toLocaleTimeString('es-ES');
                        eobt = eobt.substr(0, 2) + eobt.substr(3, 2);

                        text += flight.fpl[0] + '-' + flight.fpl[1] + '/A' + getSquawk2() + '-' + flight.fpl[2] + '\n';
                        text += '-' + flight.fpl[3] + '-' + flight.fpl[4].replace('Z', '') + '\n';
                        text += '-' + flight.fpl[5].substr(0, 4) + eobt + '\n';
                        text += ('-N0' + parseInt(getTASfromCAS(flight.cas, flight.rfl * 100)) + 'F' + flight.rfl + ' ' + route).match(/(.{1,68}\s|[&|$])\s*/g).join('\n') + '\n';
                        text += '-' + flight.fpl[7] + '\n';
                        text += '-' + flight.fpl[8].match(/(.{1,68}\s|[)])\s*/g).join('\n') + '\n\n';
                    })
                    download(exerciseName + '.txt', text);
                }}>Download</span></li>}
                {(isPlt || isCon) && <>
                    <li><span className={'buttonSDD'} onClick={() => {
                        if (!isConnected) {
                            let sessionID = prompt('Session ID:');
                            if (sessionID === null) return;
                            setSessionId(sessionID);
                            setIsConnected(true);
                        } else if (isConnected) {
                            setIsConnected(false);

                        }
                    }}>{isConnected && 'Disconnect'}{!isConnected && 'Connect'}</span></li>
                    {/*<li><span className={'buttonSDD'}>Disconnect</span></li>*/}
                </>}
                {<li><span className={'buttonSDD'} style={{color: "green"}} onClick={() => {
                    (isPlt || isEpp) &&
                    setPttWindows({
                        ...pttWindows,
                        sectorWindow: !pttWindows.sectorWindow
                    })
                    // setSectors(sectors.ops.sector === "ALVC" ?
                    //     {
                    //         ops: {
                    //             name: "uklv",
                    //             sector: "ALVW",
                    //             basicSectors: ["W325"],
                    //             freq: "118.675",
                    //             vLimit: {lower: 6000, upper: 32500},
                    //             maxTV: 31
                    //         }
                    //     } : sectors.ops.sector === "ALVW" ?
                    //         {
                    //             ops: {
                    //                 name: "lvt",
                    //                 sector: "ALVT",
                    //                 basicSectors: ["LVTM"],
                    //                 freq: "120.525",
                    //                 vLimit: {lower: 2500, upper: 16500},
                    //                 maxTV: 25
                    //             }
                    //         } :
                    //         {
                    //             ops: {
                    //                 name: "uklv",
                    //                 sector: "ALVC",
                    //                 basicSectors: ["W345", "C355", "C365", "C375", "C385", "C660"],
                    //                 freq: "135.600",
                    //                 vLimit: {lower: 32500, upper: 66000},
                    //                 maxTV: 35
                    //             }
                    //         })
                }}>{sectors.ops.sector}-{sectors.ops.freq}</span></li>}

                <li><span className={`buttonSDD ${pttWindows.systemTimeWindow ? "buttonSDDpressed" : ""}`}
                          onClick={() => setPttWindows({
                              ...pttWindows,
                              systemTimeWindow: !pttWindows.systemTimeWindow
                          })}>{new Date(startDate.getTime() + (timeValue * 1000)).toLocaleTimeString('es-ES')}</span>
                </li>
                <li><span className={`buttonSDD ${pttWindows.mainMenu ? "buttonSDDpressed" : ""}`}
                          onClick={() => setPttWindows({
                              ...pttWindows,
                              mainMenu: !pttWindows.mainMenu
                          })}>Menu</span>
                </li>
            </ul>
        </div>
    )
}

export default InfoPanel;