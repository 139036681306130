/*
    node_js
    1/31/2021 7:14 PM
    by Oleksandr
*/
export const uklv = [
    {lat: 47.7333333333333, lon: 24.8833333333333},
    {lat: 47.7355, lon: 25.0475},
    {lat: 47.8666666666667, lon: 25.1666666666667},
    {lat: 47.9188333333333, lon: 25.6533333333333},
    {lat: 47.9321666666667, lon: 25.7166666666667},
    {lat: 47.9405, lon: 25.7775},
    {lat: 47.9516666666667, lon: 25.9583333333333},
    {lat: 48.2205, lon: 26.4696666666667},
    {lat: 48.2505, lon: 26.6236666666667},
    {lat: 48.3228333333333, lon: 26.7095},
    {lat: 48.4146666666667, lon: 26.8128333333333},
    {lat: 48.418, lon: 27.3041666666667},
    {lat: 48.4521666666667, lon: 27.7216666666667},
    {lat: 48.4216666666667, lon: 27.828},
    {lat: 48.4155, lon: 27.838},
    {lat: 48.97, lon: 27.8416666666667},
    {lat: 49.05, lon: 27.8383333333333},
    {lat: 49.15, lon: 27.845},
    {lat: 49.7616666666667, lon: 27.85},
    {lat: 50.182, lon: 27.0438333333333},
    {lat: 50.5416666666667, lon: 27.255},
    {lat: 50.85, lon: 27.4333333333333},
    {lat: 51.3761666666667, lon: 27.7458333333333},
    {lat: 51.3833333333333, lon: 27.75},
    {lat: 51.4713333333333, lon: 27.7596666666667},
    {lat: 51.4796666666667, lon: 27.6995},
    {lat: 51.7663333333333, lon: 26.7983333333333},
    {lat: 51.847, lon: 26.3958333333333},
    {lat: 51.893, lon: 26.1213333333333},
    {lat: 51.9196666666667, lon: 25.8863333333333},
    {lat: 51.8833333333333, lon: 24.9666666666667},
    {lat: 51.9013333333333, lon: 24.688},
    {lat: 51.5, lon: 23.6166666666667},
    {lat: 51.3028333333333, lon: 23.6458333333333},
    {lat: 50.8333333333333, lon: 24.1},
    {lat: 50.668, lon: 24.0503333333333},
    {lat: 50.5033333333333, lon: 24.065},
    {lat: 50.4041666666667, lon: 23.8255},
    {lat: 50.2211666666667, lon: 23.4853333333333},
    {lat: 50.0216666666667, lon: 23.212},
    {lat: 49.9658333333333, lon: 23.1383333333333},
    {lat: 49.8883333333333, lon: 23.0583333333333},
    {lat: 49.8088333333333, lon: 22.94},
    {lat: 49.5678333333333, lon: 22.6545},
    {lat: 49.21, lon: 22.7366666666667},
    {lat: 49.0888333333333, lon: 22.8333333333333},
    {lat: 49, lon: 22.7666666666667},
    {lat: 49.0888333333333, lon: 22.5666666666667},
    {lat: 48.9503333333333, lon: 22.4486666666667},
    {lat: 48.7716666666667, lon: 22.3505},
    {lat: 48.6516666666667, lon: 22.2613333333333},
    {lat: 48.6346666666667, lon: 22.2458333333333},
    {lat: 48.54, lon: 22.16},
    {lat: 48.4666666666667, lon: 22.15},
    {lat: 48.4641666666667, lon: 22.1471666666667},
    {lat: 48.4333333333333, lon: 22.1666666666667},
    {lat: 48.4083333333333, lon: 22.2333333333333},
    {lat: 48.34, lon: 22.3196666666667},
    {lat: 48.1333333333333, lon: 22.5945},
    {lat: 48.0966666666667, lon: 22.6833333333333},
    {lat: 48.1166666666667, lon: 22.8333333333333},
    {lat: 47.95, lon: 22.8666666666667},
    {lat: 48.0166666666667, lon: 23.1},
    {lat: 48.0266666666667, lon: 23.3433333333333},
    {lat: 47.9088333333333, lon: 24.2096666666667},
    {lat: 47.9583333333333, lon: 24.5833333333333},
    {lat: 47.7333333333333, lon: 24.8833333333333}
];

export const lvt = [
    {lon: 23.655, lat: 49.5283333333333},
    {lon: 24.3111111111111, lat: 49.1236111111111},
    {lon: 24.6691666666667, lat: 49.5375},
    {lon: 24.7925, lat: 49.6777777777778},
    {lon: 24.6508333333333, lat: 49.88},
    {lon: 24.3119444444444, lat: 50.0619444444444},
    {lon: 24.2413888888889, lat: 50.1422222222222},
    {lon: 23.8966666666667, lat: 50.075},
    {lon: 23.7833333333333, lat: 50.05},
    {lon: 23.6333333333333, lat: 49.9833333333333},
    {lon: 23.4166666666667, lat: 50},
    {lon: 23.2119444444444, lat: 50.0216666666667},
    {lon: 23.2088888888889, lat: 50.0208333333333},
    {lon: 23.0583333333333, lat: 49.8883333333333},
    {lon: 23.655, lat: 49.5283333333333}
];

export const lve = [{lat: 49.05, lon: 27.8383333333333},
    {lat: 49.15, lon: 27.845},
    {lat: 49.7616666666667, lon: 27.85},
    {lat: 50.182, lon: 27.0438333333333},
    {lat: 50.5416666666667, lon: 27.255},
    {lat: 50.85, lon: 27.4333333333333},
    {lat: 51.3761666666667, lon: 27.7458333333333},
    {lat: 51.3833333333333, lon: 27.75},
    {lat: 51.4713333333333, lon: 27.7596666666667},
    {lat: 51.4796666666667, lon: 27.6995},
    {lat: 51.7663333333333, lon: 26.7983333333333},
    {lat: 51.847, lon: 26.3958333333333},
    {lat: 51.893, lon: 26.1213333333333},
    {lat: 51.9196666666667, lon: 25.8863333333333},
    {lat: 51.8833333333333, lon: 24.9666666666667},
    {lat: 51.9013333333333, lon: 24.688},
    {lat: 51.5, lon: 23.6166666666667},
    {lat: 51.3028333333333, lon: 23.6458333333333},
    {lat: 50.8333333333333, lon: 24.1},
    {lat: 50.668, lon: 24.0503333333333},
    {lat: 50.5033333333333, lon: 24.065},
    {lat: 50.4041666666667, lon: 23.8255},
    {lat: 50.2211666666667, lon: 23.4853333333333},
    {lat: 50.0208333333333, lon: 23.2088888888889},
    {lat: 50.0216666666667, lon: 23.2119444444444},
    {lat: 50, lon: 23.4166666666667},
    {lat: 49.9833333333333, lon: 23.6333333333333},
    {lat: 50.05, lon: 23.7833333333333},
    {lat: 50.075, lon: 23.8966666666667},
    {lat: 50.1422222222222, lon: 24.2413888888889},
    {lat: 50.0619444444444, lon: 24.3119444444444},
    {lat: 49.88, lon: 24.6508333333333},
    {lat: 49.6777777777778, lon: 24.7925},
    {lat: 49.5375, lon: 24.6691666666667},
    {lat: 49.2966666666667, lon: 25.3983333333333},
    {lat: 49.2619444444444, lon: 26.0008333333333},
    {lat: 49.0461111111111, lon: 26.7816666666667},
    {lat: 49.05, lon: 27.8383333333333}
];

export const lvw = [{lat: 49.0461111111111, lon: 26.7816666666667},
    {lat: 49.2619444444444, lon: 26.0008333333333},
    {lat: 49.2966666666667, lon: 25.3983333333333},
    {lat: 49.5375, lon: 24.6691666666667},
    {lat: 49.6777777777778, lon: 24.7925},
    {lat: 49.88, lon: 24.6508333333333},
    {lat: 50.0619444444444, lon: 24.3119444444444},
    {lat: 50.1422222222222, lon: 24.2413888888889},
    {lat: 50.075, lon: 23.8966666666667},
    {lat: 50.05, lon: 23.7833333333333},
    {lat: 49.9833333333333, lon: 23.6333333333333},
    {lat: 50, lon: 23.4166666666667},
    {lat: 50.0216666666667, lon: 23.212},
    {lat: 49.9658333333333, lon: 23.1383333333333},
    {lat: 49.8883333333333, lon: 23.0583333333333},
    {lat: 49.8088333333333, lon: 22.94},
    {lat: 49.5678333333333, lon: 22.6545},
    {lat: 49.21, lon: 22.7366666666667},
    {lat: 49.0888333333333, lon: 22.8333333333333},
    {lat: 49, lon: 22.7666666666667},
    {lat: 49.0888333333333, lon: 22.5666666666667},
    {lat: 48.9503333333333, lon: 22.4486666666667},
    {lat: 48.7716666666667, lon: 22.3505},
    {lat: 48.6516666666667, lon: 22.2613333333333},
    {lat: 48.6346666666667, lon: 22.2458333333333},
    {lat: 48.54, lon: 22.16},
    {lat: 48.4666666666667, lon: 22.15},
    {lat: 48.4641666666667, lon: 22.1471666666667},
    {lat: 48.4333333333333, lon: 22.1666666666667},
    {lat: 48.4083333333333, lon: 22.2333333333333},
    {lat: 48.34, lon: 22.3196666666667},
    {lat: 48.1333333333333, lon: 22.5945},
    {lat: 48.0966666666667, lon: 22.6833333333333},
    {lat: 48.1166666666667, lon: 22.8333333333333},
    {lat: 47.95, lon: 22.8666666666667},
    {lat: 48.0166666666667, lon: 23.1},
    {lat: 48.0266666666667, lon: 23.3433333333333},
    {lat: 47.9088333333333, lon: 24.2096666666667},
    {lat: 47.9583333333333, lon: 24.5833333333333},
    {lat: 47.7333333333333, lon: 24.8833333333333},
    {lat: 47.7333333333333, lon: 24.8833333333333},
    {lat: 47.7355, lon: 25.0475},
    {lat: 47.8666666666667, lon: 25.1666666666667},
    {lat: 47.9188333333333, lon: 25.6533333333333},
    {lat: 47.9321666666667, lon: 25.7166666666667},
    {lat: 47.9405, lon: 25.7775},
    {lat: 47.9516666666667, lon: 25.9583333333333},
    {lat: 48.2205, lon: 26.4696666666667},
    {lat: 48.2505, lon: 26.6236666666667},
    {lat: 48.3228333333333, lon: 26.7095},
    {lat: 48.4146666666667, lon: 26.8128333333333},
    {lat: 48.418, lon: 27.3041666666667},
    {lat: 48.4521666666667, lon: 27.7216666666667},
    {lat: 48.4216666666667, lon: 27.828},
    {lat: 48.4155, lon: 27.838},
    {lat: 48.97, lon: 27.8416666666667},
    {lat: 49.05, lon: 27.8383333333333},
];

export const uklvArray = [
    {
        "type": "Feature",
        "name": "uklv",
        "color": "rgb(73, 70, 70)",
        "geometry": {
            "type": "LineString",
            "coordinates": [[24.8833333333333, 47.7333333333333],
                [25.0475, 47.7355],
                [25.1666666666667, 47.8666666666667],
                [25.6533333333333, 47.9188333333333],
                [25.7166666666667, 47.9321666666667],
                [25.7775, 47.9405],
                [25.9583333333333, 47.9516666666667],
                [26.4696666666667, 48.2205],
                [26.6236666666667, 48.2505],
                [26.7095, 48.3228333333333],
                [26.8128333333333, 48.4146666666667],
                [27.3041666666667, 48.418],
                [27.7216666666667, 48.4521666666667],
                [27.828, 48.4216666666667],
                [27.838, 48.4155],
                [27.8416666666667, 48.97],
                [27.8383333333333, 49.05],
                [27.845, 49.15],
                [27.85, 49.7616666666667],
                [27.0438333333333, 50.182],
                [27.255, 50.5416666666667],
                [27.4333333333333, 50.85],
                [27.7458333333333, 51.3761666666667],
                [27.75, 51.3833333333333],
                [27.7596666666667, 51.4713333333333],
                [27.6995, 51.4796666666667],
                [26.7983333333333, 51.7663333333333],
                [26.3958333333333, 51.847],
                [26.1213333333333, 51.893],
                [25.8863333333333, 51.9196666666667],
                [24.9666666666667, 51.8833333333333],
                [24.688, 51.9013333333333],
                [23.6166666666667, 51.5],
                [23.6458333333333, 51.3028333333333],
                [24.1, 50.8333333333333],
                [24.0503333333333, 50.668],
                [24.065, 50.5033333333333],
                [23.8255, 50.4041666666667],
                [23.4853333333333, 50.2211666666667],
                [23.212, 50.0216666666667],
                [23.1383333333333, 49.9658333333333],
                [23.0583333333333, 49.8883333333333],
                [22.94, 49.8088333333333],
                [22.6545, 49.5678333333333],
                [22.7366666666667, 49.21],
                [22.8333333333333, 49.0888333333333],
                [22.7666666666667, 49],
                [22.5666666666667, 49.0888333333333],
                [22.4486666666667, 48.9503333333333],
                [22.3505, 48.7716666666667],
                [22.2613333333333, 48.6516666666667],
                [22.2458333333333, 48.6346666666667],
                [22.16, 48.54],
                [22.15, 48.4666666666667],
                [22.1471666666667, 48.4641666666667],
                [22.1666666666667, 48.4333333333333],
                [22.2333333333333, 48.4083333333333],
                [22.3196666666667, 48.34],
                [22.5945, 48.1333333333333],
                [22.6833333333333, 48.0966666666667],
                [22.8333333333333, 48.1166666666667],
                [22.8666666666667, 47.95],
                [23.1, 48.0166666666667],
                [23.3433333333333, 48.0266666666667],
                [24.2096666666667, 47.9088333333333],
                [24.5833333333333, 47.9583333333333],
                [24.8833333333333, 47.7333333333333],
            ]
        },
    },
    {
        "type": "Feature",
        "name": "lve",
        "color": "rgb(78, 74, 74)",
        "geometry": {
            "type": "LineString",
            "coordinates": [[27.8383333333333, 49.05],
                [27.845, 49.15],
                [27.85, 49.7616666666667],
                [27.0438333333333, 50.182],
                [27.255, 50.5416666666667],
                [27.4333333333333, 50.85],
                [27.7458333333333, 51.3761666666667],
                [27.75, 51.3833333333333],
                [27.7596666666667, 51.4713333333333],
                [27.6995, 51.4796666666667],
                [26.7983333333333, 51.7663333333333],
                [26.3958333333333, 51.847],
                [26.1213333333333, 51.893],
                [25.8863333333333, 51.9196666666667],
                [24.9666666666667, 51.8833333333333],
                [24.688, 51.9013333333333],
                [23.6166666666667, 51.5],
                [23.6458333333333, 51.3028333333333],
                [24.1, 50.8333333333333],
                [24.0503333333333, 50.668],
                [24.065, 50.5033333333333],
                [23.8255, 50.4041666666667],
                [23.4853333333333, 50.2211666666667],
                [23.2088888888889, 50.0208333333333],
                [23.2119444444444, 50.0216666666667],
                [23.4166666666667, 50],
                [23.6333333333333, 49.9833333333333],
                [23.7833333333333, 50.05],
                [23.8966666666667, 50.075],
                [24.2413888888889, 50.1422222222222],
                [24.3119444444444, 50.0619444444444],
                [24.6508333333333, 49.88],
                [24.7925, 49.6777777777778],
                [24.6691666666667, 49.5375],
                [25.3983333333333, 49.2966666666667],
                [26.0008333333333, 49.2619444444444],
                [26.7816666666667, 49.0461111111111],
                [27.8383333333333, 49.05]]
        }
    },
    {
        "type": "Feature",
        "name": "lvw",
        "color": "rgb(78, 74, 74)",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [26.7816666666667, 49.0461111111111],
                [26.0008333333333, 49.2619444444444],
                [25.3983333333333, 49.2966666666667],
                [24.6691666666667, 49.5375],
                [24.7925, 49.6777777777778],
                [24.6508333333333, 49.88],
                [24.3119444444444, 50.0619444444444],
                [24.2413888888889, 50.1422222222222],
                [23.8966666666667, 50.075],
                [23.7833333333333, 50.05],
                [23.6333333333333, 49.9833333333333],
                [23.4166666666667, 50],
                [23.212, 50.0216666666667],
                [23.1383333333333, 49.9658333333333],
                [23.0583333333333, 49.8883333333333],
                [22.94, 49.8088333333333],
                [22.6545, 49.5678333333333],
                [22.7366666666667, 49.21],
                [22.8333333333333, 49.0888333333333],
                [22.7666666666667, 49],
                [22.5666666666667, 49.0888333333333],
                [22.4486666666667, 48.9503333333333],
                [22.3505, 48.7716666666667],
                [22.2613333333333, 48.6516666666667],
                [22.2458333333333, 48.6346666666667],
                [22.16, 48.54],
                [22.15, 48.4666666666667],
                [22.1471666666667, 48.4641666666667],
                [22.1666666666667, 48.4333333333333],
                [22.2333333333333, 48.4083333333333],
                [22.3196666666667, 48.34],
                [22.5945, 48.1333333333333],
                [22.6833333333333, 48.0966666666667],
                [22.8333333333333, 48.1166666666667],
                [22.8666666666667, 47.95],
                [23.1, 48.0166666666667],
                [23.3433333333333, 48.0266666666667],
                [24.2096666666667, 47.9088333333333],
                [24.5833333333333, 47.9583333333333],
                [24.8833333333333, 47.7333333333333],
                [24.8833333333333, 47.7333333333333],
                [25.0475, 47.7355],
                [25.1666666666667, 47.8666666666667],
                [25.6533333333333, 47.9188333333333],
                [25.7166666666667, 47.9321666666667],
                [25.7775, 47.9405],
                [25.9583333333333, 47.9516666666667],
                [26.4696666666667, 48.2205],
                [26.6236666666667, 48.2505],
                [26.7095, 48.3228333333333],
                [26.8128333333333, 48.4146666666667],
                [27.3041666666667, 48.418],
                [27.7216666666667, 48.4521666666667],
                [27.828, 48.4216666666667],
                [27.838, 48.4155],
                [27.8416666666667, 48.97],
                [27.8383333333333, 49.05]

            ]
        }
    },
    {
        "type": "Feature",
        "name": "ukln",
        "color": "rgb(73, 70, 70)",
        "geometry": {
            "type": "LineString",
            "coordinates": [[26.47, 48.2202777777778],
                [26.1213888888889, 48.6372222222222],
                [25.5388888888889, 48.4161111111111],
                [25.7636111111111, 47.9683333333333]]
        }
    },
    {
        "type": "Feature",
        "name": "lvt",
        "color": "rgb(78, 74, 74)",
        "geometry": {
            "type": "LineString",
            "coordinates": [[23.655, 49.5283333333333],
                [24.3111111111111, 49.1236111111111],
                [24.6691666666667, 49.5375],
                [24.7925, 49.6777777777778],
                [24.6508333333333, 49.88],
                [24.3119444444444, 50.0619444444444],
                [24.2413888888889, 50.1422222222222],
                [23.8966666666667, 50.075],
                [23.7833333333333, 50.05],
                [23.6333333333333, 49.9833333333333],
                [23.4166666666667, 50],
                [23.2119444444444, 50.0216666666667],
                [23.2088888888889, 50.0208333333333],
                [23.0583333333333, 49.8883333333333],
                [23.655, 49.5283333333333]]
        }
    },


];



