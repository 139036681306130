/*
    node_js
    3/25/2021 6:29 PM
    by Oleksandr
*/

export const strategicConstrains = [
    {
        ades: 'EPWA',
        points: ['LUGOG', 'TEPNA', 'DIBED', 'GOTIX'],
        xfl: 360
    },
    {
        ades: 'EPWA',
        points: ['ROLKA', 'USTIL', 'TOLPA'],
        xfl: 340
    },
    {
        ades: 'EPRA',
        points: ['DIBED', 'GOTIX'],
        xfl: 300
    },
    {
        ades: 'EPLB',
        points: ['TOLPA'],
        xfl: 200
    },
    {
        ades: 'EPLB',
        points: ['USTIL', 'ROLKA'],
        xfl: 240
    },
    {
        ades: 'EPLB',
        points: ['DIBED', 'GOTIX'],
        xfl: 300
    },
    {
        ades: 'EPKK',
        points: ['LUGOG', 'TEPNA', 'DIBED', 'GOTIX', 'ROLKA', 'USTIL', 'TOLPA'],
        xfl: 340
    },
    {
        ades: 'EPKT',
        points: ['LUGOG', 'TEPNA', 'DIBED', 'GOTIX', 'ROLKA', 'USTIL', 'TOLPA'],
        xfl: 340
    },
    {
        ades: 'EPRZ',
        points: ['DIBED'],
        xfl: 120
    },
    {
        ades: 'LHBP',
        points: ['LADOB', 'MALBE', 'LASOT'],
        xfl: 340
    },
    {
        ades: 'LHBP',
        points: ['LADOB', 'MALBE', 'LASOT'],
        xfl: 340
    },
    {
        ades: 'LZTT',
        points: ['LADOB', 'MALBE', 'LASOT'],
        xfl: 180
    },
    {
        ades: 'LZKZ',
        points: ['LADOB', 'MALBE', 'LASOT'],
        xfl: 140
    },
    {
        ades: 'EPRZ',
        points: ['LADOB', 'MALBE', 'LASOT'],
        xfl: 300
    },
    {
        ades: 'EPKK',
        points: ['LADOB', 'MALBE', 'LASOT'],
        xfl: 340
    },
    {
        ades: 'EPKT',
        points: ['LADOB', 'MALBE', 'LASOT'],
        xfl: 340
    },
    {
        ades: 'LHBP',
        points: ['LONLA', 'GEMTO'],
        xfl: 340
    },
    {
        ades: 'LRSV',
        points: ['BUKOV', 'ROMOL'],
        xfl: 110
    },
    {
        ades: 'LRIA',
        points: ['BUKOV', 'ROMOL', 'EROMO','BAMUD', 'RUMUK'],
        xfl: 270
    },
    {
        ades: 'LRCL',
        points: ['BUKOV', 'ROMOL', 'EROMO','BAMUD', 'RUMUK'],
        xfl: 270
    },
    {
        ades: 'LRSB',
        points: ['BUKOV', 'ROMOL', 'EROMO','BAMUD', 'RUMUK'],
        xfl: 340
    },
    {
        ades: 'UMBB',
        points: ['TADUN', 'DIBON'],
        xfl: 130
    }
];
