/*
    node_js
    1/31/2021 2:44 AM
    by Oleksandr
*/

import {calc} from "../calc";
import acGroups from "./acGroups.json"
import {strategicConstrains} from "../fdp/stategicConstrains";

const {getCASfromMach, getMachfromCAS, getTASfromCAS} = require("../geometry/airspeedConverter")
const {runways} = require("../../config/adap/runways");


const geolib = require("geolib");

class Flight {

    constructor(data) {


        let {flight, rfl, fpl, id, cas, squawk, adesRunway, adesRunwayDetails, type, exC, ades, xfl} = data;

        // console.log(flight);
        this.callsign = flight.c;
        this.route = fpl[10];

        this.currentPosition = flight.cP;
        this.nextPoint = flight.nP || 1;
        this.prevPoint = flight.prP || this.nextPoint - 1;
        this.heading = parseInt(flight.h);
        this.bearing = parseInt(flight.sH);
        this.isOnHeading = flight.isOH;
        this.isOnOrbit = flight.isOO || false;
        this.isOrbitStarted = flight.isOSt || false;
        this.orbitSide = flight.oS; //(L or R)
        this.isOnHeadingTime = 0;
        this.ROT = 0;
        this.distanceToNextPoint = 0;
        this.distanceToPrevPoint = 999999999;
        this.passedPoint = false;

        this.rfl = rfl;
        this.cas = cas;
        this.Mach = flight.M;
        this.selectedAltitude = flight.sA;
        this.speed = flight.s;
        this.isOnSpeed = flight.isOS;
        this.altitude = flight.a;
        this.roc = flight.r || 0;
        this.selectedRoc = flight.sR || 0;
        this.isDescending = flight.isDesc || false;
        this.isClimbing = flight.isClimb || false;
        this.exC = exC || null;
        this.xfl = flight.xfl || xfl || null;
        this.selectedSpeed = flight.sS;
        this.isAdvanced = flight.isAdvanced;
        this.isAssumed = flight.isAssumed;
        this.isCorrelated = flight.isCorrelated;
        this.isApproach = flight.isApp;
        this.isClearedILS = flight.isCILS || true;
        this.isGA = flight.isGA || false;
        this.isInApproachArea = flight.isIAA;
        this.ades = ades || null;
        this.adesRunway = adesRunway;
        this.adesRunwayDetails = adesRunwayDetails;
        this.isSelfApproach = false;
        // if (flight.routePlan.planPoints[0].isAdep) {
        //     this.altitude = 1000;
        //     this.speed = 145;
        //     this.selectedSpeed = 165;
        // } else {
        //     this.altitude = this.selectedAltitude;
        //     this.speed = 250;
        //     this.selectedSpeed = 250;
        // }
        this.tas = getTASfromCAS(this.speed, this.altitude);
        this.gs = this.tas; //TODO Ground speed calculation with wind effect
        this.isActive = true;
        this.isTransponderOn = true;
        this.squawk = squawk;

        // this.flightId = flight.flightId;
        this.perf = null;
        if (acGroups[type]) {
            this.perf = acGroups[type];
        }

        ///TODO LoA
        let sC = strategicConstrains.filter(sC => sC.ades === this.ades && sC.points.includes(this.exC));
        if (sC.length > 0) {
            this.xfl = this.rfl >= sC[0].xfl ? sC[0].xfl : null;
        }
        this.exCIndex = this.route.map(e => e.name).indexOf(this.exC);

    }

    calculateNextPoint = () => {
        // let newFlights = [];
        if (this.route.length === 1) {
            this.isTerminated = true;
            this.isActive = false;
            return;
        }
        this.passedPoint = false;
        this.calculateHeading();
        let perf = null;
        if (this.perf) {
            let test = this.perf.perf_layers.filter((ac) => {
                return ac.alt >= this.altitude / 100;
            })[0];
            if (test.perf) {
                perf = test['perf'];
                this.minSpeed = parseInt(perf[15]);
                if (this.minSpeed > 180 && this.altitude < 25000) this.minSpeed = 180;
                this.maxSpeed = parseInt(perf[16]);
                this.maxFL = parseInt(this.perf['perf'][0]);
                if (this.selectedAltitude / 100 > this.maxFL) {
                    this.selectedAltitude = this.maxFL * 100;
                }
                if (this.isApproach) {
                    if (this.altitude > this.selectedAltitude) {
                        this.standartRoc = parseInt(this.gs) * 5 / 15;
                        this.maxRoc = parseInt(perf[13]) / 15;
                    } else {
                        this.standartRoc = 125;
                        this.maxRoc = parseInt(perf[11]) / 15;
                    }
                } else {
                    if (this.altitude > this.selectedAltitude) {
                        this.standartRoc = parseInt(perf[12]) / 15;
                        this.maxRoc = parseInt(perf[13]) / 15;
                    }
                    if (this.altitude < this.selectedAltitude) {
                        this.standartRoc = parseInt(perf[10]) / 15;
                        this.maxRoc = parseInt(perf[11]) / 15;
                    }
                }
            }
        } else {
            this.standartRoc = 125;
            this.maxRoc = 230;
        }

        if (this.distanceToDestination < (this.altitude + 2000) / 300) {
            if (this.standartRoc > this.roc && this.standartRoc + 20 < this.maxRoc) {
                this.standartRoc += 20;
            } else if (this.standartRoc <= this.roc && this.roc + 20 < this.maxRoc) {
                this.standartRoc = this.roc + 20;
            }
        }

        if (Math.abs(this.altitude - this.selectedAltitude) <= 300 && this.standartRoc > parseInt(300 / 15)) this.standartRoc = parseInt(300 / 15);
        else if (Math.abs(this.altitude - this.selectedAltitude) <= 1200 && this.standartRoc > parseInt(1000 / 15)) this.standartRoc = parseInt(1000 / 15);
        this.selectedRoc = Math.abs(this.altitude - this.selectedAltitude) > this.standartRoc
            ? this.standartRoc : Math.abs(this.altitude - this.selectedAltitude) / 2;

        if (this.selectedAltitude > this.altitude && this.isDescending && this.roc > 0) {
            this.roc -= 30;
            if (this.roc < 0) {
                this.roc = 20;
                this.isDescending = false;
                this.isClimbing = true;
            }
        } else if (this.selectedAltitude < this.altitude && this.isClimbing && this.roc > 0) {
            this.roc -= 30;
            if (this.roc < 0) {
                this.roc = 20;
                this.isDescending = true;
                this.isClimbing = false;
            }
        } else if (!this.isDescending && !this.isClimbing) {
            if (this.altitude > this.selectedAltitude) {
                this.isDescending = true;
            } else if (this.altitude < this.selectedAltitude) {
                this.isClimbing = true;
            }
        } else {
            let rocDiff = Math.abs(this.selectedRoc - this.roc);
            if (this.selectedRoc > this.roc) {
                this.roc = (rocDiff > this.selectedRoc / 3 && rocDiff <= this.selectedRoc) ? this.roc + this.selectedRoc / 3 : this.selectedRoc;
            } else if (this.selectedRoc < this.roc) {
                this.roc = (rocDiff > 20) ? this.roc - 20 : this.selectedRoc;
            }
        }

        if (this.isClimbing) this.altitude = parseInt(this.altitude + this.roc);
        else if (this.isDescending) this.altitude = parseInt(this.altitude - this.roc);

        if (this.altitude === this.selectedAltitude) {
            this.isDescending = false;
            this.isClimbing = false;
        }

        if (this.altitude > 5000 && this.altitude < 10000) {
            if (perf) {
                if (this.isApproach) {
                    if (this.isOnSpeed) {
                        if (this.selectedSpeed > perf[16]) {
                            this.selectedSpeed = perf[5];
                        } else if (this.selectedSpeed < perf[15]) {
                            this.selectedSpeed = perf[15];
                        }
                    } else {
                        this.selectedSpeed = perf[4];
                    }
                } else {
                    this.selectedSpeed = perf[0];
                }
            } else if (this.isApproach && this.selectedSpeed >= 250) {
                this.selectedSpeed = 250;
            } else if (this.cas > 250) {
                this.selectedSpeed = 250;
            } else {
                this.selectedSpeed = this.cas;
            }
        }
        if (this.altitude > 4000 && this.altitude < 7500 && this.isApproach) {
            if (perf) {
                if (this.isOnSpeed) {
                    if (this.selectedSpeed > perf[16]) {
                        this.selectedSpeed = perf[5];
                    } else if (this.selectedSpeed < perf[15]) {
                        this.selectedSpeed = perf[15];
                    }
                } else {
                    this.selectedSpeed = perf[4];
                }
            } else if (this.cas > 220 && this.selectedSpeed > 220) {
                this.selectedSpeed = 220;
            }
        }
        if (this.altitude > 3000 && this.altitude <= 4000 && this.isApproach) {
            if (perf) {
                if (this.isOnSpeed) {
                    if (this.selectedSpeed > perf[16]) {
                        this.selectedSpeed = perf[5];
                    } else if (this.selectedSpeed < perf[15]) {
                        this.selectedSpeed = perf[15];
                    }
                } else {
                    this.selectedSpeed = perf[4];
                }
            } else if (this.cas > 200 && this.selectedSpeed > 200) {
                this.selectedSpeed = 200;
            }
        }
        if (this.altitude <= 3000) {
            if (perf) {
                if (this.isOnSpeed) {
                    if (this.selectedSpeed > perf[16]) {
                        this.selectedSpeed = perf[5];
                    } else if (this.selectedSpeed < perf[15]) {
                        this.selectedSpeed = perf[15];
                    }
                } else {
                    this.selectedSpeed = perf[4];
                }
            } else if (this.cas > 180) {
                this.selectedSpeed = 180;
            }
        }
        if (this.altitude >= 10000 && this.altitude < 25000) {
            if (!this.isOnSpeed) {
                // console.log(this.selectedSpeed, this.cas)
                if (perf) {
                    this.selectedSpeed = perf[0];
                } else {
                    this.selectedSpeed = this.cas;
                }
            } else if (this.isOnSpeed && perf) {
                if (this.selectedSpeed > this.maxSpeed) {
                    this.selectedSpeed = this.maxSpeed;
                } else if (this.selectedSpeed < this.minSpeed) {
                    this.selectedSpeed = this.minSpeed;
                }
            }
        }

        // this.selectedSpeed = 290;
        if (this.altitude >= 25000) {
            if (perf) {
                if (this.isOnSpeed) {
                    this.selectedSpeed = getCASfromMach(this.Mach, this.altitude);
                    if (this.selectedSpeed > this.maxSpeed) {
                        this.selectedSpeed = this.maxSpeed;
                    } else if (this.selectedSpeed < this.minSpeed) {
                        this.selectedSpeed = this.minSpeed;
                    }
                } else {
                    this.selectedSpeed = perf[0];
                }
            } else {
                this.selectedSpeed = getCASfromMach(this.Mach, this.altitude);
            }
        }

        if (this.speed - this.selectedSpeed < -1) this.speed += 2;
        else if (this.speed - this.selectedSpeed >= 1) this.speed -= 1;
        else if (this.speed - this.selectedSpeed === -1) this.speed += 1;
        // else if (this.speed - this.selectedSpeed <= 1 &&
        //     this.speed - this.selectedSpeed >= -1) this.speed = this.selectedSpeed
        this.tas = getTASfromCAS(this.speed, this.altitude);
        this.calculatedMach = getMachfromCAS(this.speed, this.altitude);

        this.gs = this.tas; //TODO wind effect

        let nextPosition = geolib.computeDestinationPoint(
            this.currentPosition,
            (this.tas * 1.852 * 1000) / 60 / 15,
            this.heading + 6
        );
        this.currentPosition = nextPosition;
        this.distanceToNextPoint = geolib.convertDistance(
            geolib.getDistance(this.currentPosition, this.route[this.nextPoint]),
            "sm"
        );
        if (this.nextPoint === this.prevPoint) {
            this.distanceToPrevPoint = this.distanceToNextPoint;
        } else {
            let tempDisToPrevPoint = geolib.convertDistance(
                geolib.getDistance(this.currentPosition, this.route[this.prevPoint]),
                "sm"
            );
            if (tempDisToPrevPoint < this.distanceToPrevPoint) {
                this.distanceToPrevPoint = tempDisToPrevPoint;
            } else {
                console.log(this.callsign, "this.nextPoint",tempDisToPrevPoint, this.distanceToPrevPoint,
                    this.route[this.nextPoint].name, this.nextPoint,this.route[this.prevPoint].name, this.prevPoint);
                this.distanceToPrevPoint = 999999999;
                this.prevPoint++;
                this.passedPoint = true;

            }
        }

        // let nowDate = Date.now();
        // let tempTime = (this.distanceToNextPoint / this.tas) * 60 * 60 * 1000 + nowDate;
        // let tempDate = new Date(tempTime);
        // let prevTempTime = tempTime;
        // this.route[this.nextPoint].time = tempDate.getUTCHours() + ":" + tempDate.getUTCMinutes();
        // if (this.nextPoint < this.route.length - 1) {
        //     for (let i = this.nextPoint + 1; i < this.route.length; i++) {
        //         tempTime = (this.route[i - 1].distance / this.tas) * 60 * 60 * 1000 + prevTempTime;
        //         tempDate = new Date(tempTime);
        //         prevTempTime = tempTime;
        //         this.route[i].time = tempDate.getUTCHours() + ":" + tempDate.getUTCMinutes();
        //     }
        // }
        // this.route[this.nextPoint].time2 = new Date((this.distanceToNextPoint / this.tas) * 60 * 60 * 1000 + Date.now());
        this.distanceRoute = this.route.reduce((acc, r, ind) =>
            ind >= this.nextPoint ? acc + r.dist : acc, 0);
        this.distanceToDestination = this.distanceRoute + this.distanceToNextPoint;
        // this.distanceToDestination = geolib.convertDistance(
        //     geolib.getDistance(this.currentPosition, this.route[this.route.length - 1]),
        //     "sm"
        // );

        if (this.exCIndex >= 0 && this.xfl) {
            let distToExCop = geolib.convertDistance(
                geolib.getDistance(this.currentPosition, this.route[this.exCIndex]),
                "sm"
            );
            if (distToExCop < Math.abs(this.altitude - this.xfl * 100 + 4000) / 300) {
                if (this.selectedAltitude > this.xfl * 100) {
                    this.TOD = true;
                    if (!this.isAssumed) {
                        this.selectedAltitude = this.xfl * 100;
                    }
                } else {
                    this.TOD = false;
                }
            }
            if (distToExCop < Math.abs(this.altitude - this.xfl * 100 + 10000) / 300) {
                if (this.selectedAltitude < this.xfl * 100) {
                    this.TOC = true;
                    if (!this.isAssumed) {
                        this.selectedAltitude = this.xfl * 100;
                    }
                } else {
                    this.TOC = false;
                }
            }
        }
        if (this.distanceToDestination < (this.altitude) / 300 && this.altitude > 4000) {
            this.isApproach = true;
            if (this.altitude - this.selectedAltitude < 100) {
                this.TOD = true;
                if (this.altitude > 12000 && !this.isAssumed && this.selectedAltitude > 12000) this.selectedAltitude = 12000;
            } else {
                this.TOD = false;
            }
            // this.selectedAltitude = 3000;
            //TODO Allmost done Request discend
        }
        let radiusOfTurn = ((this.tas * this.tas) / 11.26 * Math.tan(30 * Math.PI / 180)) / 6076;

        if (this.adesRunwayDetails) {
            this.isInApproachArea = geolib.isPointInPolygon(this.currentPosition, this.adesRunwayDetails.approachArea);

            if (this.altitude < 5100) {
                if (this.selectedAltitude !== this.adesRunwayDetails.elevation && this.isClearedILS &&
                    this.altitude - this.adesRunwayDetails.elevation >
                    Math.tan(3 * Math.PI / 180) * 6076 *
                    geolib.convertDistance(geolib.getDistance(this.currentPosition, this.adesRunwayDetails.threshold), "sm") &&
                    this.isInApproachArea && !this.isGA
                ) {
                    this.selectedAltitude = this.adesRunwayDetails.elevation;
                }
                if (this.isInApproachArea && this.isClearedILS && !this.isGA) {

                    // console.log('getDiffBetweenAngles', calc.getDiffBetweenAngles(this.adesRunwayDetails.heading, this.heading));
                    if (this.isOnHeading
                        && geolib.getDistanceFromLine(this.currentPosition,
                            this.adesRunwayDetails.threshold,
                            geolib.computeDestinationPoint(this.adesRunwayDetails.threshold,
                                18 * 1.852 * 1000,
                                (this.adesRunwayDetails.heading + 180 + 6) % 360)) / 1852 < radiusOfTurn / 1.3
                        && calc.getDiffBetweenAngles(this.adesRunwayDetails.heading, this.heading) < 31
                    ) {
                        // this.roc = this.gs * 5.5 / 15;
                        this.nextPoint = this.route.length - 1;
                        this.isOnHeading = false;
                        // this.isOnFinal = true;
                    }
                    // console.log(this.roc);
                } else if (this.isGA && this.selectedAltitude < 3000) {
                    this.selectedAltitude = 3000;
                }
            }

            if (this.isGA && !this.isInApproachArea) {
                this.isGA = false;
            }
        }

        // console.log(this.callsign, radiusOfTurn, this.distanceToNextPoint);
        if (this.distanceToNextPoint < radiusOfTurn * 3) {
            if (!this.route[this.nextPoint].isRunway) {
                this.nextPoint += 1;
            } else {
                if (!this.isSelfApproach) {
                    this.isSelfApproach = true;
                    this.isOnHeading = true;
                    this.isOnHeadingTime = 0;
                    this.bearing = (this.adesRunwayDetails.heading + 180) % 360;
                } else if (this.selectedAltitude > 3000) {
                    this.nextPoint = this.route.length - 3;
                    this.selectedAltitude = 3000;
                    this.prevPoint = this.nextPoint - 1;
                    // this.isSelfApproach = true;
                    // this.isOnHeading = true;
                    // this.isOnHeadingTime = 0;
                    // this.bearing = (this.adesRunwayDetails.heading + 180) % 360;
                }
                if (this.altitude < this.adesRunwayDetails.elevation + 400 && this.distanceToNextPoint < 1) {
                    this.nextPoint = this.route.length;
                }
            }
        }
        if (this.route.length === this.nextPoint) {
            this.isTerminated = true;
            // newFlights.push({adep: this.adep, flight: this});
            // this.nextPoint = 1;
            this.isActive = false;
            this.isTransponderOn = false;
            // this.currentPosition = this.route[0];
            this.altitude = 1000;
            // this.selectedAltitude = parseInt(this.rfl) * 100;
            // this.distanceToNextPoint = geolib.convertDistance(
            //     geolib.getDistance(this.currentPosition, this.route[this.nextPoint]),
            //     "sm"
            // );
        }
        // return newFlights;
    };
    calculateHeading = () => {
        this.isOnHeadingTime++;
        if (this.isSelfApproach && this.isOnHeadingTime > 60) {
            this.isSelfApproach = false;
            this.isOnHeading = false;
        } else if (this.isOnHeadingTime > 400) {
            this.isOnHeading = false;
        }
        if (!this.isOnHeading && !this.isOnOrbit) {
            let bearing = geolib.getGreatCircleBearing(
                this.currentPosition,
                this.route[this.nextPoint]
                // { latitude: this.route[this.nextPoint].latitude, longitude: this.route[this.nextPoint].longitude }
            );
            this.bearing = bearing - 6;
        } else {

        }

        ///stdROT calculating according to altitude, bigger bank angle below 7000ft
        let stdROT;
        stdROT = this.altitude > 7000 ? Math.tan(30 * Math.PI / 180) * 1091 / this.tas : Math.tan(35 * Math.PI / 180) * 1091 / this.tas;
        if (this.isOnOrbit) {
            if (Math.abs(this.heading - this.bearing) >= stdROT * 4 || !this.isOrbitStarted) {
                if (this.ROT < stdROT) this.ROT += stdROT * 0.2;
                (this.orbitSide === 'R') ? this.heading += this.ROT * 4 : this.heading -= this.ROT * 4;
            } else {
                this.ROT = Math.abs(this.heading - this.bearing) / 2;
                (this.orbitSide === 'R') ? this.heading += this.ROT : this.heading -= this.ROT;
            }
            if (Math.abs(this.heading - this.bearing) >= 90) this.isOrbitStarted = true;
            if (Math.abs(this.heading - this.bearing) <= 5 && this.isOrbitStarted === true) this.isOnOrbit = false;
        } else if (Math.abs(this.heading - this.bearing) >= stdROT * 4) {
            if (this.ROT < stdROT) this.ROT += stdROT * 0.2;
            (this.heading - this.bearing + 360) % 360 > 180
                ? (this.heading += this.ROT * 4)
                : (this.heading -= this.ROT * 4);
        } else {
            this.ROT = Math.abs(this.heading - this.bearing) / 2;
            (this.heading - this.bearing + 360) % 360 > 180
                ? (this.heading += this.ROT)
                : (this.heading -= this.ROT);
        }

        if (this.heading < 0) this.heading = 360 + this.heading;
        if (this.heading > 360) this.heading = this.heading - 360;
    };
}

export default Flight;