import React, {useEffect} from 'react'
import VideoWithVolume from "./VideoWithVolume";


const VideoChat = (props) => {
    const {
        username, startCall, setLocalVideoRef, toggleAudio, localStream,
        isLocalAudioMuted
    } = props

    // const onStartCallClicked = () => {
    //     startCall(username)
    // }
    //
    //
    // const renderVideos = () => {
    //     console.log(localStream, localStream.getAudioTracks()[0].enabled)
    //     return null
    // }
    useEffect(() => {
        if (localStream) {
            console.log(localStream.getAudioTracks()[0].enabled)
        }
    }, [localStream])

    const handleToggleAudio = (e) => {
        if (e.nativeEvent.type === 'mousedown' || e.nativeEvent.type === 'touchstart') toggleAudio(true)
        if (e.nativeEvent.type === 'mouseup' || e.nativeEvent.type === 'touchend') toggleAudio(false)
    }
    return <section id="container" style={{background:"black"}}>
        {!isLocalAudioMuted && <span style={{position: "absolute", zIndex: "15", left: "0px"}}>🔇</span>}
        <VideoWithVolume
            videoStream={localStream}
            video={<video style={{width: "200px"}} ref={setLocalVideoRef} autoPlay playsInline muted="muted"
                          onTouchStart={handleToggleAudio}
                          onTouchEnd={handleToggleAudio}
            ></video>}
        />
    </section>
}

export default VideoChat
