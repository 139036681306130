/*
    node_js
    3/5/2021 9:26 PM
    by Oleksandr
*/
import '../../CWP.css';
import React from "react";
import {calcFlight} from "../../../../calc/calc";

const CflMenu = (props) => {
    const cflArray = ['650', '630', '610', '590', '570', '550', '530', '510', '490', '470', '450', '430', '410', '400', '390', '380', '370', '360', '350', '340', '330', '320', '310', '300', '290', '280', '270', '260', '250', '240', '230', '220', '210', '200', '190', '180', '170', '160', '150', '140', '130', '120', '110', '100', '090', '080', '070', '060', '050', '040', '030', '020', '010'];
    const {callsignMenuFlight, isCon, tickValue, showFlightMenu, typeOfFlightMenu, startDate,
        dispatchFlightClearance} = props;
    let index = isCon || !callsignMenuFlight ? 0 : tickValue + callsignMenuFlight.flight.timeShift;
    return (
        <>
            {(callsignMenuFlight && typeOfFlightMenu && callsignMenuFlight.flight.flight[index]) &&
            <div className={'callsignMenu'}
                 style={{left: callsignMenuFlight.clientX + 'px', top: callsignMenuFlight.clientY + 'px'}}>
                <span>{callsignMenuFlight.flight.c}</span><br/>
                <select style={{width: '100%'}} name="Cfl" size="8"
                        onInput={(e) => {
                            dispatchFlightClearance({
                                type: 'sfl',
                                value:parseInt(e.target.value) * 100,
                                flight: callsignMenuFlight.flight,
                                index,
                                startDate,
                            });
                            // callsignMenuFlight.flight.flight[index].sA =
                            // calcFlight(callsignMenuFlight.flight, index, startDate.getTime());
                            showFlightMenu(null);
                        }}>
                    {cflArray.filter((cfl => {
                            if (!callsignMenuFlight.flight.flight[index].maxFL) return true;
                            return parseInt(cfl) <= callsignMenuFlight.flight.flight[index].maxFL;
                        }
                    ))
                        .map((cfl) => {
                            let selected = callsignMenuFlight.flight.flight[index].sA / 100 === parseInt(cfl) ? true : false;
                            return (<option selected={selected} value={cfl}>{cfl}</option>)
                        })}
                </select>
                <span style={{width: '45px'}} className={'buttonSDD'} onClick={(e) => {
                    // showFlightMenu(null);
                }}>
                    Probe
                </span>
                <input type="number" autoFocus style={{width: '55px'}} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (!e.target.value) return;
                        dispatchFlightClearance({
                            type: 'sfl',
                            value:parseInt(e.target.value) * 100,
                            flight: callsignMenuFlight.flight,
                            index,
                            startDate,
                        });
                        // callsignMenuFlight.flight.flight[index].sA = parseInt(e.target.value) * 100;
                        // calcFlight(callsignMenuFlight.flight, index, startDate.getTime());
                        showFlightMenu(null);
                    }
                }}/>
            </div>}
        </>
    );
}

export default CflMenu;
