/*
    node_js
    3/5/2021 7:05 PM
    by Oleksandr
*/

import CallsignMenu from "../menu/CallsignMenu";
import CflMenu from "../menu/CflMenu";
import DctMenu from "../menu/DctMenu";
import HdgMenu from "../menu/HdgMenu";
import AswToolbox from "../windows/asw/AswToolbox";
import MachMenu from "../menu/MachMenu";
import SpdMenu from "../menu/SpdMenu";
import CflConMenu from "../menu/CflConMenu";

const AswWindowLayer = (props) => {
    const {
        showCallsignMenu, isCallsignMenuVisible, callsignMenuFlight,
        typeOfFlightMenu, pttWindows
    } = props;
    return (<div style={{position: 'absolute', left: '0px', top: '0px'}}>
        {pttWindows.aswToolbox1Window && <AswToolbox {...props}/>}
        {isCallsignMenuVisible && <CallsignMenu callsignMenuFlight={callsignMenuFlight} {...props}/>}
        {(typeOfFlightMenu==="sfl" && !isCallsignMenuVisible) && <CflMenu {...props} />}
        {(typeOfFlightMenu==="cfl" && !isCallsignMenuVisible) && <CflConMenu {...props} />}
        {(typeOfFlightMenu==="dct" && !isCallsignMenuVisible) && <DctMenu {...props} />}
        {(typeOfFlightMenu==="hdg" && !isCallsignMenuVisible) && <HdgMenu {...props} />}
        {(typeOfFlightMenu==="spd" && !isCallsignMenuVisible) && <SpdMenu {...props} />}
        {(typeOfFlightMenu==="mach" && !isCallsignMenuVisible) && <MachMenu {...props} />}
    </div>);
}

export default AswWindowLayer;