import React, {useContext, useEffect, useState} from 'react'
import {ASW} from "./asw/ASW";
import {CwpContext} from "../../contexts/CwpContext";
import ClientSocket from "../network/ClientSocket";
import WindowLayer from "./graphics/layers/WindowLayer";
import DemoManager from "../demo/DemoManager";
import InfoPanel from "./graphics/InfoPanel";
import VideoChatContainer from "../network/VideoChatContainer";
import Loading from "./graphics/windows/Loading";
import MainMenu from "./graphics/MainMenu";
// import InfoPanel from './infoPanel/InfoPanel';
// import MainMenu from './mainMenu/MainMenu';
// import WindowLayer from './graphics/layers/WindowLayer'
// import TrackLayer from "./graphics/layers/TrackLayer";


function CWP(props) {
    const {isEpp, isPlt, isCon, isDemo} = props;
    const context = useContext(CwpContext);
    const {updateFlight, selectedFlight, getData, isConnected, sessionId, isLoading, pttWindows} = context;
    const [userName, setUserName] = useState('Lviv');
    // const [isConnected, setIsConnected] = useState(false);
    // const [sessionId, setSessionId] = useState('');
    useEffect(() => {
        if (isPlt || isEpp || isDemo) getData();
        setUserName(localStorage.getItem('user_display_name') + '___' + Math.round(Math.random()*999999));
    }, [])
    useEffect(() => {
        function onKeydown(e) {
            if (e.key === "PageDown") context.setZoom(context.zoom -= 100);
            if (e.key === "PageUp") context.setZoom(context.zoom += 100);
            if (isEpp) {
                if (e.key === "ArrowUp") updateFlight(selectedFlight, 3);
                if (e.key === "ArrowDown") updateFlight(selectedFlight, -3);
            }
        }
        window.addEventListener('keydown', onKeydown);
        return () => window.removeEventListener('keydown', onKeydown);
    }, [selectedFlight, context, updateFlight]);

    // const {
    //     isExecutiveWindowVisible, toggleExecutiveWindow, isMainMenuVisible,
    //     isPlannerWindowVisible, togglePlannerWindow, updateTracks,
    //     rteOff, finder, changeRadarMode, radarMode
    // } = props;

    // const mainMenu = <MainMenu
    //     isMainMenuVisible={isMainMenuVisible}
    //     isExecutiveWindowVisible={isExecutiveWindowVisible}
    //     isPlannerWindowVisible={isPlannerWindowVisible}
    //     toggleExecutiveWindow={toggleExecutiveWindow}
    //     togglePlannerWindow={togglePlannerWindow}
    //     rteOff={rteOff}
    //     finder={finder}
    // />
    // let buttonCaption = (radarMode.radarMode === 'int') ? 'MSDP-INT' : 'MSDP-MON';
    return (
        <div className="cwp" style={{height: '100vh'}}>
            {isLoading && <Loading />}
            {/*<TrackLayer  {...props} />*/}
            {/*<button style={{position: "fixed", top: "0px"}}*/}
            {/*        onClick={() => changeRadarMode({radarMode: radarMode.radarMode})}>*/}
            {/*    {(radarMode.radarMode === 'int') ? 'MSDP-INT' : 'MSDP-MON'}*/}
            {/*</button>*/}
            <ASW {...context} {...props} />
            {isConnected && <VideoChatContainer {...context} {...props} username={userName} room={'session' + sessionId} />}
            <WindowLayer {...context} {...props} />
            {/*{isDemo && <DemoManager {...context} {...props} />}*/}
            {/*<WindowLayer {...props}/>*/}
            <InfoPanel {...context} {...props}/>
            {pttWindows.mainMenu && <MainMenu {...context} {...props}/>}
        </div>
    )
}

export default CWP;