/*
    node_js
    9/17/2021 11:29 PM
    by Oleksandr
*/

import Window from "./Window";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";

const sectorsList = {
    sectorLVCLVU1: {
        ops: {
            name: "uklv",
            sector: "ALVC",
            allowance: "LVC+LVU",
            basicSectors: ["E345", "W345", "C355", "C365", "C375", "C385", "C660"],
            freq: "135.600",
            vLimit: {lower: 32500, upper: 66000},
            maxTV: 35
        }
    },
    sectorLVCLVU2: {
        ops: {
            name: "uklv",
            sector: "ALVC",
            allowance: "LVC+LVU",
            basicSectors: ["C355", "C365", "C375", "C385", "C660"],
            freq: "135.600",
            vLimit: {lower: 34500, upper: 66000},
            maxTV: 38
        }
    },
    sectorLVU0: {
        ops: {
            name: "uklv",
            sector: "ALVU",
            allowance: "LVU",
            basicSectors: ["C660"],
            freq: "134.050",
            vLimit: {lower: 38500, upper: 66000},
            maxTV: 32
        }
    },
    sectorLVU1: {
        ops: {
            name: "uklv",
            sector: "ALVU",
            allowance: "LVU",
            basicSectors: ["C385", "C660"],
            freq: "134.050",
            vLimit: {lower: 37500, upper: 66000},
            maxTV: 32
        }
    },
    sectorLVU2: {
        ops: {
            name: "uklv",
            sector: "ALVU",
            allowance: "LVU",
            basicSectors: ["C375", "C385", "C660"],
            freq: "134.050",
            vLimit: {lower: 36500, upper: 66000},
            maxTV: 34
        }
    },
    sectorLVU3: {
        ops: {
            name: "uklv",
            sector: "ALVU",
            allowance: "LVU",
            basicSectors: ["C365", "C375", "C385", "C660"],
            freq: "134.050",
            vLimit: {lower: 35500, upper: 66000},
            maxTV: 33
        }
    },
    sectorLVC0: {
        ops: {
            name: "uklv",
            sector: "ALVC",
            allowance: "LVC",
            basicSectors: ["C355", "C365", "C375", "C385"],
            freq: "135.600",
            vLimit: {lower: 34500, upper: 38500},
            maxTV: 35
        }
    },
    sectorLVC1: {
        ops: {
            name: "uklv",
            sector: "ALVC",
            allowance: "LVC",
            basicSectors: ["C355", "C365", "C375"],
            freq: "135.600",
            vLimit: {lower: 34500, upper: 37500},
            maxTV: 33
        }
    },
    sectorLVC2: {
        ops: {
            name: "uklv",
            sector: "ALVC",
            allowance: "LVC",
            basicSectors: ["C355", "C365"],
            freq: "135.600",
            vLimit: {lower: 34500, upper: 36500},
            maxTV: 31
        }
    },
    sectorLVC3: {
        ops: {
            name: "uklv",
            sector: "ALVC",
            allowance: "LVC",
            basicSectors: ["E345", "W345", "C355", "C365", "C375"],
            freq: "135.600",
            vLimit: {lower: 32500, upper: 37500},
            maxTV: 29
        }
    },
    sectorLVC4: {
        ops: {
            name: "uklv",
            sector: "ALVC",
            allowance: "LVC",
            basicSectors: ["E345", "W345", "C355", "C365"],
            freq: "135.600",
            vLimit: {lower: 32500, upper: 36500},
            maxTV: 29
        }
    },
    sectorLVC5: {
        ops: {
            name: "uklv",
            sector: "ALVC",
            allowance: "LVC",
            basicSectors: ["E345", "W345", "C355"],
            freq: "135.600",
            vLimit: {lower: 32500, upper: 35500},
            maxTV: 31
        }
    },
    sectorLVELVW1: {
        ops: {
            name: "uklv",
            sector: "ALVW",
            allowance: "LVE+LVW",
            basicSectors: ["E325", "E345", "W325", "W345"],
            freq: "118.675",
            vLimit: {lower: 5500, upper: 34500},
            maxTV: 31
        }
    },
    sectorLVELVW2: {
        ops: {
            name: "uklv",
            sector: "ALVW",
            allowance: "LVE+LVW",
            basicSectors: ["E325", "W325"],
            freq: "118.675",
            vLimit: {lower: 5500, upper: 32500},
            maxTV: 31
        }
    },
    sectorLVE1: {
        ops: {
            name: "lve",
            sector: "ALVE",
            allowance: "LVE",
            basicSectors: ["E325", "E345"],
            freq: "125.650",
            vLimit: {lower: 5500, upper: 34500},
            maxTV: 31
        }
    },
    sectorLVE2: {
        ops: {
            name: "lve",
            sector: "ALVE",
            allowance: "LVE",
            basicSectors: ["E325"],
            freq: "125.650",
            vLimit: {lower: 5500, upper: 32500},
            maxTV: 31
        }
    },
    sectorLVW1: {
        ops: {
            name: "lvw",
            sector: "ALVW",
            allowance: "LVW",
            basicSectors: ["W325", "W345"],
            freq: "118.675",
            vLimit: {lower: 5500, upper: 34500},
            maxTV: 31
        }
    },
    sectorLVW2: {
        ops: {
            name: "lvw",
            sector: "ALVW",
            allowance: "LVW",
            basicSectors: ["W325"],
            freq: "118.675",
            vLimit: {lower: 5500, upper: 32500},
            maxTV: 31
        }
    },
    sectorLVT: {
        ops: {
            name: "lvt",
            sector: "ALVT",
            allowance: "LVT",
            basicSectors: ["LVTM"],
            freq: "120.525",
            vLimit: {lower: 2500, upper: 16500},
            maxTV: 25
        }
    }
}

const SectorWindow = (props) => {
    const {setPttWindows, pttWindows, setSectors, sectors, updateAllFlightsForEpp, setIsLoading, isLoading} = props;
    const [isUpdatingAllFlights, setIsUpdateingAllFlights] = useState(false);

    useEffect(()=>{
        if (isUpdatingAllFlights && isLoading) {
            setTimeout(()=>updateAllFlightsForEpp(), 150);
        }
    }, [isUpdatingAllFlights, isLoading])

    const firstUpdate = useRef(false);
    useLayoutEffect(()=>{
        console.log(firstUpdate.current);
        if (firstUpdate.current)
        {
            setIsLoading(true);
            setIsUpdateingAllFlights(true);
            return;
        }
        firstUpdate.current = true;
    }, [sectors]);

    const getIsChecked = (obj) => {

        let isChecked = JSON.stringify(sectors.ops.basicSectors) === JSON.stringify(obj.ops.basicSectors);
        return isChecked;

    }
    // useLayoutEffect(() => {
    //     updateAllFlightsForEpp();
    // }, [sectors])


    return <Window toggleWindow={() => {
        setPttWindows({...pttWindows, sectorWindow: false});
    }
    } label={'Sector'} width={350}
                   content={<>
                       <table>
                           <tbody>
                           <tr style={{color: "white", fontSize: "22px"}}>
                               <td colSpan="3">{sectors.ops.basicSectors.join(" ")}</td>
                           </tr>
                           <tr>
                               <td className='headerTh' style={{textAlign: "center"}}>Sector</td>
                               <td className='headerTh' style={{textAlign: "center"}}>Limits</td>
                               <td className='headerTh' style={{textAlign: "center"}}>Configurations</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td>
                                   <input type="radio" name="sector" id="sectorLVCLVU1"
                                          checked={getIsChecked(sectorsList.sectorLVCLVU1)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVCLVU1);
                                          }}/>
                                   <label htmlFor="sectorLVCLVU1">LVC+LVU</label>
                               </td>
                               <td>FL325-FL660</td>
                               <td>2H</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td>
                                   <input type="radio" name="sector" id="sectorLVCLVU2"
                                          checked={getIsChecked(sectorsList.sectorLVCLVU2)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVCLVU2);
                                          }}/>
                                   <label htmlFor="sectorLVCLVU2">LVC+LVU</label>
                               </td>
                               <td>FL345-FL660</td>
                               <td>3U, 2U</td>
                           </tr>

                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVU0"
                                          checked={getIsChecked(sectorsList.sectorLVU0)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVU0);
                                          }}/>
                                   <label htmlFor="sectorLVU0">LVU</label>
                               </td>
                               <td>FL385-FL660</td>
                               <td>3UW</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVU1"
                                          checked={getIsChecked(sectorsList.sectorLVU1)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVU1);
                                          }}/>
                                   <label htmlFor="sectorLVU1">LVU</label>
                               </td>
                               <td>FL375-FL660</td>
                               <td>3UA, 3HA, 4UA</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVU2"
                                          checked={getIsChecked(sectorsList.sectorLVU2)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVU2);
                                          }}/>
                                   <label htmlFor="sectorLVU2">LVU</label>
                               </td>
                               <td>FL365-FL660</td>
                               <td>3UN, 3HN, 4UN</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVU3"
                                          checked={getIsChecked(sectorsList.sectorLVU3)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVU3);
                                          }}/>
                                   <label htmlFor="sectorLVU3">LVU</label>
                               </td>
                               <td>FL355-FL660</td>
                               <td>3HT</td>
                           </tr>

                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVC0"
                                          checked={getIsChecked(sectorsList.sectorLVC0)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVC0);
                                          }}/>
                                   <label htmlFor="sectorLVC0">LVC</label>
                               </td>
                               <td>FL345-FL385</td>
                               <td>3UW</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVC1"
                                          checked={getIsChecked(sectorsList.sectorLVC1)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVC1);
                                          }}/>
                                   <label htmlFor="sectorLVC1">LVC</label>
                               </td>
                               <td>FL345-FL375</td>
                               <td>3UA, 4UA</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVC2"
                                          checked={getIsChecked(sectorsList.sectorLVC2)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVC2);
                                          }}/>
                                   <label htmlFor="sectorLVC2">LVC</label>
                               </td>
                               <td>FL345-FL365</td>
                               <td>3UN, 4UN</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVC3"
                                          checked={getIsChecked(sectorsList.sectorLVC3)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVC3);
                                          }}/>
                                   <label htmlFor="sectorLVC3">LVC</label>
                               </td>
                               <td>FL325-FL375</td>
                               <td>3HA</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVC4"
                                          checked={getIsChecked(sectorsList.sectorLVC4)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVC4);
                                          }}/>
                                   <label htmlFor="sectorLVC4">LVC</label>
                               </td>
                               <td>FL325-FL365</td>
                               <td>3HN</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVC5"
                                          checked={getIsChecked(sectorsList.sectorLVC5)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVC5);
                                          }}/>
                                   <label htmlFor="sectorLVC5">LVC</label>
                               </td>
                               <td>FL325-FL355</td>
                               <td>3HT</td>
                           </tr>

                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVELVW1"
                                          checked={getIsChecked(sectorsList.sectorLVELVW1)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVELVW1);
                                          }}/>
                                   <label htmlFor="sectorLVELVW1">LVE+LVW</label>
                               </td>
                               <td>6000f-FL345</td>
                               <td>3UA, 3UN, 3UW, 2U</td>
                           </tr>
                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVELVW2"
                                          checked={getIsChecked(sectorsList.sectorLVELVW2)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVELVW2);
                                          }}/>
                                   <label htmlFor="sectorLVELVW2">LVE+LVW</label>
                               </td>
                               <td>6000f-FL325</td>
                               <td>3HT, 3HN, 3HA, 2H</td>
                           </tr>

                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVW1"
                                          checked={getIsChecked(sectorsList.sectorLVW1)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVW1);
                                          }}/>
                                   <label htmlFor="sectorLVW1">LVW</label>
                               </td>
                               <td>6000f-FL345</td>
                               <td>3U, 4UN, 4UA</td>
                           </tr>

                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVE1"
                                          checked={getIsChecked(sectorsList.sectorLVE1)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVE1);
                                          }}/>
                                   <label htmlFor="sectorLVE1">LVE</label>
                               </td>
                               <td>6000f-FL345</td>
                               <td>3U, 4UN, 4UA</td>
                           </tr>

                           <tr className='sddTableTr'>
                               <td style={{textAlign: "left"}}>
                                   <input type="radio" name="sector" id="sectorLVT"
                                          checked={getIsChecked(sectorsList.sectorLVT)}
                                          onChange={() => {
                                              setSectors(sectorsList.sectorLVT);
                                          }}/>
                                   <label htmlFor="sectorLVT">LVT</label>
                               </td>
                               <td>2700f-FL165</td>
                               <td></td>
                           </tr>


                           </tbody>
                       </table>
                   </>}
    />
}

export default SectorWindow;