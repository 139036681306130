/*
    node_js
    3/19/2021 10:51 PM
    by Oleksandr
*/

import React from "react";

const MinSep = (props) => {
    const {x1, y1, x2, y2, x3, y3, x4, y4, x5, y5, x6, y6, key, deleteRbl, id, minSepText, timeText, minSepRbl} = props;

    return (
        <g >
            <line key={key} x1={x5} y1={y5} x2={x6} y2={y6} stroke={'yellow'} />
            <line key={key} x1={x1} y1={y1} x2={x3} y2={y3} stroke={'yellow'} />
            <line key={key} x1={x2} y1={y2} x2={x4} y2={y4} stroke={'yellow'} />
            <polyline points={parseInt(x5-4) +', ' +parseInt(y5+4) + ' '+ parseInt(x5-4) + ', ' + parseInt(y5-4)
            + ' '+ parseInt(x5+4) + ', ' + parseInt(y5-4)+ ' '+ parseInt(x5+4) + ', ' + parseInt(y5+4)
            + ' ' + parseInt(x5-4) +', ' +parseInt(y5+4)} stroke='yellow' fill='transparent' />
            <polyline points={parseInt(x6-4) +', ' +parseInt(y6+4) + ' '+ parseInt(x6-4) + ', ' + parseInt(y6-4)
            + ' '+ parseInt(x6+4) + ', ' + parseInt(y6-4)+ ' '+ parseInt(x6+4) + ', ' + parseInt(y6+4)
            + ' ' + parseInt(x6-4) +', ' +parseInt(y6+4)} stroke='yellow' fill='transparent' />
            <text x={(x5+x6)/2} y={(y5+y6)/2-25} fill={'yellow'} rblid={id} onMouseUp={deleteRbl}
                  style={{pointerEvents: 'bounding-box', fontSize: '11px'}}   >
                <tspan x={(x5+x6)/2} dy='1.2em' rblid={id} onMouseUp={deleteRbl}>{timeText}</tspan>
                <tspan x={(x5+x6)/2} dy='1.2em' rblid={id} onMouseUp={deleteRbl}>{minSepText}</tspan>
            </text>
        </g>
    )
}

export default MinSep;