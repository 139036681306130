/*
    node_js
    2/2/2021 12:24 AM
    by Oleksandr
*/
import React from "react";

const SsrTarget = (props) => {
    const {track,ssrSize,color, onMouseDown, onMouseUp} = props;
    return (
        <>
            <div id={"target" + track.id}
                 onMouseDown={onMouseDown} onMouseUp={onMouseUp}
                 style={{
                border: "2px solid " + color,
                transform: "rotate(45deg)",
                position: "absolute",
                width: 5 * ssrSize + "px",
                height: 5 * ssrSize + "px",
                left: track.X - Math.sqrt(2 * 30 * ssrSize * ssrSize) / 2 + "px",
                top: track.Y - Math.sqrt(2 * 30 * ssrSize * ssrSize) / 2 + "px"
            }}></div>
        </>
    )

}
export default SsrTarget;