/*
    node_js
    3/15/2021 9:15 AM
    by Oleksandr
*/

import React, {useState} from "react";
import Window from "./Window";
import ActiveFlightsList from "./lists/ActiveFlightsList";

const ActiveWindow = (props) => {
    const {flights, pttWindows, setPttWindows, calcFlightsNum, startDate, isEpp, isPlt, tickValue, isCon, updateFlightForEpp} = props;
    const columns = ['c', 'type', 'adep', 'ades', 'entryCop', 'entryTime', 'exitCop', 'exitTime', 'rfl'];
    const columnsText = ['Callsign', 'TYPE', 'ADEP', 'ADES', 'ENTRY', 'TIME', 'EXIT', 'TIME', 'RFL'];
    const [actSort, setActSort] = useState('rfl');
    const sort = (arr) => arr.sort((a, b) => (a[actSort] > b[actSort]) ? 1 : ((b[actSort] > a[actSort]) ? -1 : 0));

    let stats = {};
    let statsFlights = {};
    flights.forEach(flt => {
        flt.sectors.forEach(sector => {
            if (stats[sector]) {
                stats[sector]+=1;
                statsFlights[sector].push(flt.c);

            }else{
                stats[sector] = 1;
                statsFlights[sector] = [];
                statsFlights[sector].push(flt.c);

            }
        })
    });
    // console.log("stats", stats, statsFlights, flights);

    return (
        <>
            <Window isVisible={true} toggleWindow={() => {
                isEpp ?
                    setPttWindows({...pttWindows, activeWindow: false}) :
                    setPttWindows({...pttWindows, plannerWindow: false});
            }}
                    label={isEpp ? 'Active Flights (' + flights.length + ')' : (isPlt || isCon) ? 'Planner (' + flights.length + ')' : ''}
                    width={isEpp ? 650 : 'auto'}
                    content={
                        <>
                            <div style={{overflowY: "scroll", height: isEpp ? "500px" : "auto"}}>
                                <table>
                                    <thead>
                                    <tr>{columns.map((heading, index) => <th className='filterHistFlights headerTh'
                                                                             onClick={() => setActSort(heading)}>{columnsText[index]}</th>)}
                                        {isEpp && <>
                                            <th className='headerTh'>XFL</th>
                                            <th className='headerTh'></th>
                                        </>}
                                    </tr>
                                    </thead>

                                    <ActiveFlightsList flights={sort(flights)}
                                                       columns={columns} calcFlightsNum={calcFlightsNum}
                                                       startDate={startDate}
                                                       isEpp={isEpp} isPlt={isPlt}
                                                       isCon={isCon}
                                                       tickValue={tickValue}
                                                       updateFlightForEpp={updateFlightForEpp}/>
                                </table>
                            </div>
                        </>}
            />
        </>
    )
}

export default ActiveWindow;