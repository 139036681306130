/*
    node_js
    3/14/2021 7:22 PM
    by Oleksandr
*/

import React from "react";

const MainMenu = (props) => {
    const {
        pttWindows, setPttWindows, isPlt, isEpp, setTrackPlanSet
    } = props;


    return (
        <div className='windowSdd'
             style={{zIndex: 1000000, bottom: "0px", position: "fixed", height: "24px", width: "100%"}}>
            <ul>
                {(isPlt || isEpp) && <li><span className={`buttonSDD ${pttWindows.rwyWindow ? "buttonSDDpressed" : ""}`}
                                               onClick={() => setPttWindows({
                                                   ...pttWindows,
                                                   rwyWindow: !pttWindows.rwyWindow
                                               })}>RWY</span></li>}
                <li><span className={`buttonSDD ${pttWindows.mtcdWindow ? "buttonSDDpressed" : ""}`}
                          onClick={() => setPttWindows({
                              ...pttWindows,
                              mtcdWindow: !pttWindows.mtcdWindow
                          })}>MTCD</span></li>
                <li><span className={`buttonSDD`}
                          onClick={() => setTrackPlanSet(new Set())}>RTE OFF</span></li>
            </ul>
        </div>
    )
}

export default MainMenu;