/*
    node_js
    1/30/2021 12:15 PM
    by Oleksandr
*/

// Define the standard atmospheric values that remain constant
var P0 = 101325;
//var T0 = 288.15;
var a0 = 340.29;
var rho0 = 1.225; // All SI units

// define the actual atm variables as global variables
//var tempSI;
var sonicSI;
var pressureSI;
var rhoSI;
//var sigma;

// define the various speeds in SI units
var cas;
var eas;
var tas;
var mach;

var sigFig = 1000;

let outputCAS, outputEAS, outputTAS, outputMach;

const getTASfromCAS = (casInput, altRaw) => {
    casCalc(casInput, altRaw);
    return outputTAS;
};
const getMachfromCAS = (casInput, altRaw) => {
    casCalc(casInput, altRaw);
    return outputMach;
};
const getCASfromMach = (machInput, altRaw) => {
    machCalc(machInput, altRaw);
    return outputCAS;
};
const casCalc = (casInput, altRaw) => {
    atmCalc(altRaw); // calc atm parameters

    var velUnit = 0; // +document.getElementById("selectionCAS").value;
    var velFactor;

    switch (velUnit) {
        case 0:
            velFactor = 0.514444444;
            break;

        case 1:
            velFactor = 1;
            break;

        case 2:
            velFactor = 0.277778;
            break;

        case 3:
            velFactor = 0.44704;
            break;
        default:
            break;
    }

    cas = velFactor * casInput;

    var qc = P0 * (Math.pow((Math.pow(cas / a0, 2) + 5) / 5, 7 / 2) - 1);

    mach = Math.pow(5 * (Math.pow(qc / pressureSI + 1, 2 / 7) - 1), 0.5);
    tas = mach * sonicSI;

    eas = tas / Math.pow(rho0 / rhoSI, 0.5);

    let unitFactor = 1.94384;
    outputCAS = Math.round(sigFig * cas * unitFactor) / sigFig;
    outputEAS = Math.round(sigFig * eas * unitFactor) / sigFig;
    outputTAS = Math.round(sigFig * tas * unitFactor) / sigFig;
    outputMach = Math.round(sigFig * mach) / sigFig;
    // console.log(outputMach + " " + outputTAS + " " + outputEAS + " " + outputCAS);

    //convertFromSI(); // convert results from SI to whatever units are required by the end user
};

const easCalc = (easInput, altRaw) => {
    atmCalc(altRaw); // calc atm parameters

    var velUnit = 0; // +document.getElementById("selectionEAS").value;
    var velFactor;

    switch (velUnit) {
        case 0:
            velFactor = 0.514444444;
            break;

        case 1:
            velFactor = 1;
            break;

        case 2:
            velFactor = 0.277778;
            break;

        case 3:
            velFactor = 0.44704;
            break;
        default:
            break;
    }

    eas = velFactor * easInput;
    tas = eas * Math.pow(rho0 / rhoSI, 0.5);
    mach = tas / sonicSI;

    let qc = pressureSI * (Math.pow(1 + 0.2 * Math.pow(mach, 2), 7 / 2) - 1);
    cas = a0 * Math.pow(5 * (Math.pow(qc / P0 + 1, 2 / 7) - 1), 0.5);

    // let outputCAS, outputEAS, outputTAS, outputMach;
    let unitFactor = 1.94384;
    outputCAS = Math.round(sigFig * cas * unitFactor) / sigFig;
    outputEAS = Math.round(sigFig * eas * unitFactor) / sigFig;
    outputTAS = Math.round(sigFig * tas * unitFactor) / sigFig;
    outputMach = Math.round(sigFig * mach) / sigFig;
    console.log(outputMach + " " + outputTAS + " " + outputEAS + " " + outputCAS);

    //convertFromSI(); // convert results from SI to whatever units are required by the end user
};

const tasCalc = (tasInput, altRaw) => {
    atmCalc(altRaw); // calc atm parameters

    var velUnit = 0; //+document.getElementById("selectionTAS").value;
    var velFactor;

    switch (velUnit) {
        case 0:
            velFactor = 0.514444444;
            break;

        case 1:
            velFactor = 1;
            break;

        case 2:
            velFactor = 0.277778;
            break;

        case 3:
            velFactor = 0.44704;
            break;
        default:
            break;
    }

    tas = velFactor * tasInput;
    eas = tas / Math.pow(rho0 / rhoSI, 0.5);
    mach = tas / sonicSI;

    let qc = pressureSI * (Math.pow(1 + 0.2 * Math.pow(mach, 2), 7 / 2) - 1);
    cas = a0 * Math.pow(5 * (Math.pow(qc / P0 + 1, 2 / 7) - 1), 0.5);
    // let outputCAS, outputEAS, outputTAS, outputMach;
    let unitFactor = 1.94384;
    outputCAS = Math.round(sigFig * cas * unitFactor) / sigFig;
    outputEAS = Math.round(sigFig * eas * unitFactor) / sigFig;
    outputTAS = Math.round(sigFig * tas * unitFactor) / sigFig;
    outputMach = Math.round(sigFig * mach) / sigFig;
    return {cas:outputCAS, Mach: outputMach};
    // console.log(outputMach + " " + outputTAS + " " + outputEAS + " " + outputCAS);

    //  convertFromSI(); // convert results from SI to whatever units are required by the end user
};

const machCalc = (machInput, altRaw) => {
    atmCalc(altRaw); // calc atm parameters

    mach = machInput;
    tas = mach * sonicSI;
    eas = tas / Math.pow(rho0 / rhoSI, 0.5);

    let qc = pressureSI * (Math.pow(1 + 0.2 * Math.pow(mach, 2), 7 / 2) - 1);
    cas = a0 * Math.pow(5 * (Math.pow(qc / P0 + 1, 2 / 7) - 1), 0.5);

    // let outputCAS, outputEAS, outputTAS, outputMach;
    let unitFactor = 1.94384;
    outputCAS = Math.round(sigFig * cas * unitFactor) / sigFig;
    outputEAS = Math.round(sigFig * eas * unitFactor) / sigFig;
    outputTAS = Math.round(sigFig * tas * unitFactor) / sigFig;
    outputMach = Math.round(sigFig * mach) / sigFig;
    // console.log(outputMach + " " + outputTAS + " " + outputEAS + " " + outputCAS);

    //convertFromSI(); // convert results from SI to whatever units are required by the end user
};

function atmCalc(altRaw) {
    //var altRaw = +document.getElementById("inputAltitude").value;
    var tempRaw = 0;
    var altSelection = 0;

    var altSI;
    if (altSelection === 0) {
        altSI = altRaw * 0.3048;
    } else {
        altSI = altRaw;
    }

    let altitudeArray = [0, 11000, 20000, 32000, 47000, 51000, 71000, 84852];
    let presRelsArray = [
        1,
        2.23361105092158e-1,
        5.403295010784876e-2,
        8.566678359291667e-3,
        1.0945601337771144e-3,
        6.606353132858367e-4,
        3.904683373343926e-5,
        3.6850095235747942e-6
    ];
    let tempsArray = [
        288.15,
        216.65,
        216.65,
        228.65,
        270.65,
        270.65,
        214.65,
        186.946
    ];
    let tempGradArray = [-6.5, 0, 1, 2.8, 0, -2.8, -2, 0];

    var i = 0;
    while (altSI > altitudeArray[i + 1]) {
        i = i + 1;
    }

    // i defines the array position that I require for the calc
    var alts = altitudeArray[i];
    var presRels = presRelsArray[i];
    var temps = tempsArray[i];
    var tempGrad = tempGradArray[i] / 1000;

    var deltaAlt = altSI - alts;
    var stdTemp = temps + deltaAlt * tempGrad; // this is the standard temperature at STP

    var tempSI = stdTemp + tempRaw; // includes the temp offset
    //var tempDegC = tempSI - 273.15;
    //var roundTSI = Math.round(sigFig * tempSI) / sigFig;
    //var roundTDeg = Math.round(sigFig * tempDegC) / sigFig;

    //	document.getElementById("writeAtmTemp").innerHTML = roundTSI+" K |"+roundTDeg+ " &deg;C";

    // Now for the relative pressure calc:

    //define constants
    var airMol = 28.9644;
    var rhoSL = 1.225; // kg/m3
    var pSL = 101325; // Pa
    var tSL = 288.15; //K
    var gamma = 1.4;
    var g = 9.80665; // m/s2
    //var stdTempGrad = -0.0065; // deg K/m
    var rGas = 8.31432; //kg/Mol/K
    var R = 287.053;
    var gMR = (g * airMol) / rGas;
    //var dryMM = 0.0289644; //kg/mol
    //var adLapse = 0.00198; //K/ft
    let relPres;

    if (Math.abs(tempGrad) < 1e-10) {
        relPres = presRels * Math.exp((-1 * gMR * deltaAlt) / 1000 / temps);
    } else {
        relPres = presRels * Math.pow(temps / stdTemp, gMR / tempGrad / 1000);
    }

    // Now I can calculate the results:

    sonicSI = Math.sqrt(gamma * R * tempSI);
    pressureSI = pSL * relPres;
    rhoSI = rhoSL * relPres * (tSL / tempSI);
    //sigma = rhoSI / rhoSL;
} // end atmCalc()

module.exports = {
    getTASfromCAS,
    getMachfromCAS,
    getCASfromMach,
    casCalc,
    easCalc,
    tasCalc,
    machCalc
}
