/*
    node_js
    2/13/2021 9:43 AM
    by Oleksandr
*/
import './AccLabel.css';

const Field = (props) => {
    const {text, onClick, style, onMouseUp} = props;
    return (
        <div className="Field" onClick={onClick} style={style} onMouseUpCapture={onMouseUp}>
            {text}
        </div>
    )
}

export default Field;