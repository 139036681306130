/*
    node_js
    5/22/2021 3:45 AM
    by Oleksandr
*/

import {useEffect, useState} from "react";
import * as geolib from "geolib";

/**
 * asds
 * @param props
 * @returns {*}
 * @constructor
 */
const FDP = (props) => {
    const {
        tickValue, flights, setFlights, conflictNumberList, setConflictNumberList, flightClearances, sectors,
        workloadArray, setWorkloadArray, assumeFlight, dispatchFlightClearance, startDate
    } = props;
    const [workloadArr, setWorkloadArr] = useState([]);
    useEffect(() => {
        if (!flights[0] || tickValue % 5 !== 0) return;
        // console.log(tickValue);


        // console.log('flights',flights[0].flight[0].c, flights[0].flight[tickValue+flights[0].timeShift].sA);
        // setFlights(flights);
    }, [tickValue, flights, setFlights, conflictNumberList, workloadArr, workloadArray, setWorkloadArray, sectors])
    // useEffect(() => {
    //     console.log('workloadArr', workloadArr);
    // }, [workloadArr])

    // useEffect(()=>{
    //     tst(flights);
    // }, [flights, tst])

    return (<></>)
}
export default FDP;