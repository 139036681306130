/*
    node_js
    2/1/2021 11:24 PM
    by Oleksandr
*/
import React, {useEffect} from "react";
import {getProjection, xmlToLat, xmlToLon} from "../../../../calc/calc";
import {uklv, uklvArray} from "../../../../config/adap/map";
import {
    ukll_app_rw13ils,
    ukll_app_rw13star,
    ukll_app_rw31ils,
    ukll_app_rw31star,
    ukln_app_rw15star,
    ukln_app_rw33star
} from "../../../../config/adap/ukll_app";
import * as d3 from "d3";
import '../../CWP.css';

const MapLayer = (props2) => {
    const {
        zoom, lonLatCenter, setLonLatCenter, map,
        sectors
    } = props2;

    let length = uklv.length;
    let sector = false;
    let linePath = [];
    let projection = getProjection(zoom, lonLatCenter);


    const path = d3.geoPath().projection(projection);

    // svg
    //     .selectAll("path")
    //     .attr("d", path)
    // .attr("fill", d => d.color)


    useEffect(() => {
        let svg = d3
            .select('#borderGroup')
            .selectAll("path")

        svg.remove();
        svg = d3
            .select('#borderGroup')
            .selectAll("path")
            .data(uklvArray);

        svg.enter()
            .append('path')
            .attr("d", path)
            .style("fill", d => d.name === sectors.ops.name ? "rgba(78, 74, 74, 1)" : "rgba(73, 70, 70,0)")
            .style("stroke", "rgb(150,150,150)")
            .style("stroke-width", 0.5);

    }, [sectors, zoom, lonLatCenter])

    length = ukll_app_rw31ils.length;
    let linePath_rw31ils = [];

    ukll_app_rw31ils.forEach((coord, index) => {
        if (index < length - 1) {
            if (coord.lat === '#' || ukll_app_rw31ils[index + 1].lat === '#') return;
            let coord1 = projection([coord.lon, coord.lat]);
            let coord2 = projection([ukll_app_rw31ils[index + 1].lon, ukll_app_rw31ils[index + 1].lat]);

            linePath_rw31ils.push(<line x1={coord1[0]}
                                        y1={coord1[1]}
                                        x2={coord2[0]}
                                        y2={coord2[1]}
                                        stroke={'black'}
                                        key={"ukll_app_rw31ils" + index}
            />);
        }
    });

    length = ukll_app_rw31star.length;
    let linePath_rw31star = [];
    ukll_app_rw31star.forEach((coord, index) => {
        if (index < length - 1) {
            if (coord.lat === '#' || ukll_app_rw31star[index + 1].lat === '#') return;
            if (coord.first === '#' || ukll_app_rw31star[index + 1].first === '#') {
                let coordFirt = projection([coord.lon, coord.lat]);
                linePath_rw31star.push(<use xlinkHref={"#myDot"} x={coordFirt[0] - 2} y={coordFirt[1] - 2}
                                            stroke={'#72b4ff'} fill={'#72b4ff'}/>);
                return;
            }
            let coord1 = projection([coord.lon, coord.lat]);
            let coord2 = projection([ukll_app_rw31star[index + 1].lon, ukll_app_rw31star[index + 1].lat]);

            linePath_rw31star.push(<line x1={coord1[0]}
                                         y1={coord1[1]}
                                         x2={coord2[0]}
                                         y2={coord2[1]}
                                         stroke={'#72b4ff'}
                                         key={"ukll_app_rw31star" + index}
            />);
            linePath_rw31star.push(<use xlinkHref={"#myDot"} x={coord2[0] - 2} y={coord2[1] - 2} stroke={'#72b4ff'}
                                        fill={'#72b4ff'}/>);
        }
    });

    length = ukll_app_rw13ils.length;
    let linePath_rw13ils = [];

    ukll_app_rw13ils.forEach((coord, index) => {
        if (index < length - 1) {
            if (coord.lat === '#' || ukll_app_rw13ils[index + 1].lat === '#') return;
            let coord1 = projection([coord.lon, coord.lat]);
            let coord2 = projection([ukll_app_rw13ils[index + 1].lon, ukll_app_rw13ils[index + 1].lat]);

            linePath_rw13ils.push(<line x1={coord1[0]}
                                        y1={coord1[1]}
                                        x2={coord2[0]}
                                        y2={coord2[1]}
                                        stroke={'black'}
                                        key={"ukll_app_rw13ils" + index}
            />);
        }
    });

    length = ukll_app_rw13star.length;
    let linePath_rw13star = [];
    ukll_app_rw13star.forEach((coord, index) => {
        if (index < length - 1) {
            if (coord.lat === '#' || ukll_app_rw13star[index + 1].lat === '#') return;
            if (coord.first === '#' || ukll_app_rw13star[index + 1].first === '#') {
                let coordFirt = projection([coord.lon, coord.lat]);
                linePath_rw13star.push(<use xlinkHref={"#myDot"} x={coordFirt[0] - 2} y={coordFirt[1] - 2}
                                            stroke={'#72b4ff'} fill={'#72b4ff'}/>);
                return;
            }
            let coord1 = projection([coord.lon, coord.lat]);
            let coord2 = projection([ukll_app_rw13star[index + 1].lon, ukll_app_rw13star[index + 1].lat]);

            linePath_rw13star.push(<line x1={coord1[0]}
                                         y1={coord1[1]}
                                         x2={coord2[0]}
                                         y2={coord2[1]}
                                         stroke={'#72b4ff'}
                                         key={"ukll_app_rw13star" + index}
            />);
            linePath_rw13star.push(<use xlinkHref={"#myDot"} x={coord2[0] - 2} y={coord2[1] - 2} stroke={'#72b4ff'}
                                        fill={'#72b4ff'}/>);
        }
    });

    length = ukln_app_rw15star.length;
    let linePath_rw15star = [];
    ukln_app_rw15star.forEach((coord, index) => {
        if (index < length - 1) {
            if (coord.lat === '#' || ukln_app_rw15star[index + 1].lat === '#') return;
            if (coord.first === '#' || ukln_app_rw15star[index + 1].first === '#') {
                let coordFirt = projection([coord.lon, coord.lat]);
                linePath_rw15star.push(<use xlinkHref={"#myDot"} x={coordFirt[0] - 2} y={coordFirt[1] - 2}
                                            stroke={'#72b4ff'} fill={'#72b4ff'}/>);
                return;
            }
            let coord1 = projection([coord.lon, coord.lat]);
            let coord2 = projection([ukln_app_rw15star[index + 1].lon, ukln_app_rw15star[index + 1].lat]);

            linePath_rw15star.push(<line x1={coord1[0]}
                                         y1={coord1[1]}
                                         x2={coord2[0]}
                                         y2={coord2[1]}
                                         stroke={'#72b4ff'}
                                         key={"ukln_app_rw15star" + index}
            />);
            linePath_rw15star.push(<use xlinkHref={"#myDot"} x={coord2[0] - 2} y={coord2[1] - 2} stroke={'#72b4ff'}
                                        fill={'#72b4ff'}/>);
        }
    });

    length = ukln_app_rw33star.length;
    let linePath_rw33star = [];
    ukln_app_rw33star.forEach((coord, index) => {
        if (index < length - 1) {
            if (coord.lat === '#' || ukln_app_rw33star[index + 1].lat === '#') return;
            if (coord.first === '#' || ukln_app_rw33star[index + 1].first === '#') {
                let coordFirt = projection([coord.lon, coord.lat]);
                linePath_rw33star.push(<use xlinkHref={"#myDot"} x={coordFirt[0] - 2} y={coordFirt[1] - 2}
                                            stroke={'#72b4ff'} fill={'#72b4ff'}/>);
                return;
            }
            let coord1 = projection([coord.lon, coord.lat]);
            let coord2 = projection([ukln_app_rw33star[index + 1].lon, ukln_app_rw33star[index + 1].lat]);

            linePath_rw33star.push(<line x1={coord1[0]}
                                         y1={coord1[1]}
                                         x2={coord2[0]}
                                         y2={coord2[1]}
                                         stroke={'#72b4ff'}
                                         key={"ukln_app_rw33star" + index}
            />);
            linePath_rw33star.push(<use xlinkHref={"#myDot"} x={coord2[0] - 2} y={coord2[1] - 2} stroke={'#72b4ff'}
                                        fill={'#72b4ff'}/>);
        }
    });

    const points = [{
        name: "AMIRI",
        latitude: "N051.55.11.000",
        longitude: "E025.53.11.000"
    }, {name: "BAMUD", latitude: "N047.54.32.000", longitude: "E024.12.35.000"},
        {
            name: "BUKOV",
            latitude: "N047.57.06.000",
            longitude: "E025.57.30.000"
        }, {name: "BURAK", latitude: "N051.50.49.000", longitude: "E026.23.45.000"}, {
            name: "DIBED",
            latitude: "N049.53.18.000",
            longitude: "E023.03.30.000"
        }, {name: "DIBON", latitude: "N051.53.35.000", longitude: "E026.07.17.000"}, {
            name: "DIDUR",
            latitude: "N048.35.37.000",
            longitude: "E027.50.20.000"
        }, {name: "DORER", latitude: "N050.27.57.000", longitude: "E027.12.28.000"}, {
            name: "GEMTO",
            latitude: "N048.08.00.000",
            longitude: "E022.35.40.000"
        },
        {name: "GOTIX", latitude: "N050.01.18.000", longitude: "E023.12.43.000"},
        {name: "INROG", latitude: "N048.24.53.000", longitude: "E026.48.46.000"}, {
            name: "KOROP", latitude: "N049.09.00.000", longitude: "E027.50.42.000"
        }, {
            name: "LADOB",
            latitude: "N048.57.01.000",
            longitude: "E022.26.55.000"
        }, {name: "LASOT", latitude: "N048.38.05.000", longitude: "E022.14.45.000"}, {
            name: "LONLA",
            latitude: "N048.20.24.000",
            longitude: "E022.19.11.000"
        }, {
            name: "LUGOL",
            latitude: "N049.12.20.000",
            longitude: "E022.44.17.000"
        }, {name: "MALBE", latitude: "N048.49.26.000", longitude: "E022.22.30.000"}, {
            name: "OKROT",
            latitude: "N050.42.25.000",
            longitude: "E027.20.56.000"
        },
        {
            name: "POBUV",
            latitude: "N049.57.04.000",
            longitude: "E027.29.22.000"
        }, {name: "ROLKA", latitude: "N050.24.15.000", longitude: "E023.49.32.000"}, {
            name: "ROMOL",
            latitude: "N047.44.08.000",
            longitude: "E025.02.51.000"
        }, {name: "RUMUK", latitude: "N048.01.36.000", longitude: "E023.20.36.000"}, {
            name: "SITBA",
            latitude: "N049.23.27.000",
            longitude: "E027.50.49.000"
        }, {
            name: "SOMAT",
            latitude: "N051.55.44.000",
            longitude: "E025.22.15.000"
        }, {name: "SORON", latitude: "N049.45.42.000", longitude: "E027.51.00.000"},
        {
            name: "PEVOT",
            latitude: "N050.10.55.000",
            longitude: "E027.02.38.000"
        }, {name: "TADUN", latitude: "N051.54.05.000", longitude: "E024.41.17.000"}, {
            name: "TAKET",
            latitude: "N048.58.12.000",
            longitude: "E027.50.30.000"
        }, {name: "TEPNA", latitude: "N049.30.17.000", longitude: "E022.39.22.000"}, {
            name: "TOLPA",
            latitude: "N051.18.10.000",
            longitude: "E023.38.45.000"
        }, {name: "UNDOL", latitude: "N048.27.08.000", longitude: "E027.43.18.000"}, {
            name: "USTIL",
            latitude: "N050.50.00.000",
            longitude: "E024.06.00.000"
        }];
    const extFreq = [{
        latitude: 50.30,
        longitude: 22.70,
        name: 'WARSZAWA 1B\n125.45'
    },
        {
            latitude: 49.00,
            longitude: 21.00,
            name: 'BRATISLAVA 2VB\nW - 135.965 F340^\nL - 134.475'
        },
        {
            latitude: 48.00,
            longitude: 21.50,
            name: 'BUDAPEST E1\nT - 132.790 F370^\nL - 120.37'
        },
        {
            latitude: 48.18,
            longitude: 27.40,
            name: 'CHISINAU C 1A\n130.975'
        },
        {
            latitude: 47.50,
            longitude: 24.45,
            name: 'BUCURESTI R\n127.075'
        },
        {
            latitude: 50.50,
            longitude: 27.60,
            name: 'KYIV R LIV\n135.825'
        },
        {
            latitude: 48.90,
            longitude: 28.00,
            name: 'KYIV R ODS\n131.375'
        },
        {
            latitude: 52.22,
            longitude: 25.20,
            name: 'MINSK C 1\n124.825'
        }]

    const extFreqDraw = extFreq.map(item => {
        let coord1 = projection([item.longitude, item.latitude]);
        return <text style={{fontSize: "10px"}} fill={'#64c8ff'} x={coord1[0] + 3} y={coord1[1]}>{
            item.name.split('\n').map(txt => <tspan dy="1.2em" x={coord1[0] + 3}>{txt}</tspan>)
        }</text>

    })

    const pointsDraw = points.map(item => {
        let coord1 = projection([xmlToLon(item.longitude), xmlToLat(item.latitude)]);
        return <>
            <use xlinkHref={"#myDot"} x={coord1[0] - 2} y={coord1[1] - 2} stroke={'darkgrey'}
                 fill={'darkgrey'}/>
            <text style={{fontSize: "10px"}} fill={'darkgrey'} x={coord1[0] + 3} y={coord1[1]}>{
                item.name
            }</text>
        </>
    });


    return <div style={{position: "absolute", left: "0px", top: "0px"}} id='mapSvgDiv'>
        <svg width="2000" height="2000" id='mapSvg'>
            <g id='borderGroup'>
            </g>
            <symbol id="myDot" width="4" height="4">
                <polygon points="0,4 4,4 2,0"/>
            </symbol>
            {map.borders && linePath}

            {map.ukll_app_rw31star && linePath_rw31star}
            {map.ukll_app_rw31ils && linePath_rw31ils}
            {map.ukll_app_rw13star && linePath_rw13star}
            {map.ukll_app_rw13ils && linePath_rw13ils}
            {map.ukln_app_rw15star && linePath_rw15star}
            {map.ukln_app_rw33star && linePath_rw33star}

            {map.points && pointsDraw}
            {map.extFreq && extFreqDraw}
        </svg>
        {/*<div style={{position: 'absolute', left: '0px', top: '0px'}}>*/}

        {/*</div>*/}
    </div>;
}

export default MapLayer;