/*
    node_js
    2/22/2021 7:43 PM
    by Oleksandr
*/
import auth from '../auth/Auth'
import {withRouter} from "react-router-dom";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import React, {useContext, useEffect, useState} from "react";
import Window from "./cwp/graphics/windows/Window";
import {withStyles} from "@material-ui/core";
import {CwpContext} from "../contexts/CwpContext";
import Loading from "./cwp/graphics/windows/Loading";

const CssTextField = withStyles(theme => ({
    root: {
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'lightgrey',
        }
    },
}))(TextField);


const Login = (props) => {
    console.log("props", props);
    const {isLoading, setIsLoading} = useContext(CwpContext);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const handleLogin = (event) => {
        auth.login(login, password, (resp) => {
            if (resp.success) {
                props.history.push("/home")
            } else {
                setLogin('');
                setPassword('');
                document.getElementById('login').value = '';
                document.getElementById('login').focus();
                document.getElementById('password').value = '';
                document.getElementById('dialogContentText').innerHTML = 'Invalid Login or Password';
                document.getElementById('dialogContentText').style.color = 'red';
            }
        })
        event.preventDefault();

    }

    useEffect(() => {
        auth.validateToken((resp) => {
            if (resp.success) {
                props.history.push("/home")
            }
            setIsLoading(false);
        });
    }, [setIsLoading])
    document.getElementsByTagName('body')[0].style.position = '';


    if (isLoading) {
        return (<Loading />)
    } else {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>

                <Window style={{top: '30%'}} label='Login' hideCloseButton={true} content={<>
                    <form onSubmit={handleLogin} style={{textAlign: "middle"}}>
                        <DialogContent>
                            <DialogContentText key='dialogContentText' id='dialogContentText'>
                                To login to this App, please enter your <b>lvivacc.site</b> login and password here.
                            </DialogContentText>

                            <CssTextField
                                autoFocus
                                margin="dense"
                                id="login"
                                label="Login"
                                type="login"
                                fullWidth
                                onChange={(e) => setLogin(e.target.value)}
                            />
                            <CssTextField
                                margin="dense"
                                id="password"
                                label="Password"
                                type="password"
                                // InputLabelProps={{className:classes.label}}
                                fullWidth
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </DialogContent>
                        <div style={{margin: "10px"}}>
                            <input type="submit" value="Login" className={'buttonSDD'} style={{
                                width: "80%", margin: "0 auto",
                                position: "relative", display: "block", height: "25px"
                            }}/>
                        </div>
                    </form>
                </>}/>

            </div>

        )
    }

}
export default withRouter(Login);