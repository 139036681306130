import './App.css';
import {CwpContextProvider} from "./contexts/CwpContext";
import './assets/css/style.css'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./components/Home";
import {useEffect, useState} from "react";
import {ProtectedRoute} from "./auth/ProtectedRoute";
import EPP from "./components/EPP";
import PLT from "./components/PLT";
import Login from "./components/Login";
import CNTL from "./components/CNTL";
import DEMO from "./components/DEMO";

function App(props) {
    let showLogIn = () => {
        let login = prompt("Login:");
        let password = prompt("Password:");
        const loginData = {
            username: login,
            password: password
        };
        fetch('https://lvivacc.site/wp-json/jwt-auth/v1/token',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                // redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(loginData) // body data type must match "Content-Type" header
            }
        )
            .then(function (response) {
                console.log(response)
                return response.json();
            })
            .then((res) => {
                // console.log(res, res.atm_code.code);
                localStorage.setItem('token', res.token);
                localStorage.setItem('user_nicename', res.user_nicename);
                localStorage.setItem('user_email', res.user_email);
                localStorage.setItem('user_display_name', res.user_display_name);
                localStorage.setItem('atm_code', res.atm_code ? res.atm_code.code : undefined);
            })
            .then(() => {
                    if (localStorage.getItem('token')) {
                        setIsLoggedIn(true);
                    } else {
                        showLogIn();
                    }
                }
            )

        // console.log(login, password);
        // setIsLoggedIn(true);
    }
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (!isLoggedIn) {
            // showLogIn();
        }
    }, [])

    return (
        <div className="App">
            <Router>
                <CwpContextProvider>
                    <Switch>
                        <Route exact path="/"><Login/></Route>
                        <ProtectedRoute exact path="/home" component={Home}>
                        </ProtectedRoute>
                        <ProtectedRoute path="/epp" component={EPP}/>
                        <ProtectedRoute path="/plt" component={PLT}/>
                        <ProtectedRoute path="/con" component={CNTL}/>
                        <ProtectedRoute path="/demo" component={DEMO}/>
                    </Switch>
                </CwpContextProvider>
            </Router>
        </div>
    );
}

export default App;
