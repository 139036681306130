/*
    node_js
    3/17/2021 2:19 PM
    by Oleksandr
*/

import '../../CWP.css';
import React, {useState} from "react";
import {calcFlight} from "../../../../calc/calc";
import {getMachfromCAS} from "../../../../calc/geometry/airspeedConverter";

const MachMenu = (props) => {
    const spdArray = [0.20, 0.30, 0.40, 0.50, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.60, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.70, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.80, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.90, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99
    ];
    const {
        callsignMenuFlight, isCon, tickValue, showFlightMenu, typeOfFlightMenu, startDate,
        dispatchFlightClearance
    } = props;
    const [machValue, setMachValue] = useState('');
    let index = isCon || !callsignMenuFlight ? 0 : tickValue + callsignMenuFlight.flight.timeShift;
    return (
        <>
            {(callsignMenuFlight && typeOfFlightMenu && callsignMenuFlight.flight.flight[index]) &&
            <div className={'callsignMenu'}
                 style={{left: callsignMenuFlight.clientX + 'px', top: callsignMenuFlight.clientY + 'px'}}>
                <span>{callsignMenuFlight.flight.c}</span><br/>
                <select style={{width: '100%'}} name="Cfl" size="8"
                        onInput={(e) => {
                            dispatchFlightClearance({
                                type: 'mach',
                                value: e.target.value,
                                flight: callsignMenuFlight.flight,
                                index,
                                startDate,
                            });
                            showFlightMenu(null);
                        }}>
                    {spdArray.filter((spd => {
                            if (!callsignMenuFlight.flight.flight[index].maxS) return true;
                            let maxM = getMachfromCAS(callsignMenuFlight.flight.flight[index].maxS,
                                callsignMenuFlight.flight.flight[index].a);
                            let minM = getMachfromCAS(callsignMenuFlight.flight.flight[index].minS,
                                callsignMenuFlight.flight.flight[index].a);
                            return spd <= maxM && spd >= minM;
                        }
                    ))
                        .map((spd) => {
                            let selected = parseFloat(callsignMenuFlight.flight.flight[index].calcM.toFixed(2)) === spd;
                            return (<option selected={selected} value={spd}>{spd}</option>)
                        })}
                </select>
                <span style={{width: '45px'}} className={'buttonSDD'} onClick={(e) => {
                    dispatchFlightClearance({
                        type: 'std',
                        flight: callsignMenuFlight.flight,
                        index,
                        startDate,
                    });                    showFlightMenu(null);
                }}>
                    STD
                </span>
                <input type="number" autoFocus style={{width: '55px'}}

                       step='0.01' min='0.01' max='0.99' value={machValue}
                       onChange={e => setMachValue(
                           e.target.value
                       )}
                       onKeyDown={(e) => {

                           if (e.key === 'Enter') {
                               if (!e.target.value) return;
                               dispatchFlightClearance({
                                   type: 'mach',
                                   value: e.target.value,
                                   flight: callsignMenuFlight.flight,
                                   index,
                                   startDate,
                               });
                               showFlightMenu(null);
                           }
                       }}/>
            </div>}
        </>
    );
}

export default MachMenu;
