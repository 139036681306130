/*
    node_js
    4/5/2021 12:09 AM
    by Oleksandr
*/

import React, {useEffect, useState} from "react";

const VideoWithVolume = (props) => {
    const {video, videoStream} = props;
    const [average, setAverage] = useState(0);
    useEffect(() => {
        if (!videoStream) return
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(videoStream);
        let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
        analyser.smoothingTimeConstant = 0.3;
        analyser.fftSize = 1024;
        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        let setAv = (av) => {
            setAverage(parseInt(av));

        }
        javascriptNode.onaudioprocess = () => {
            var array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            var values = 0;

            var length = array.length;
            for (var i = 0; i < length; i++) {
                values += array[i];
            }

            var average = values / length;
            setAv(average);
        }

    }, [videoStream])
    return (
        <div style={{position:"relative", zIndex:"14", border:"1px solid grey"}}>
            {video}
            <svg style={{width:"100%", height: "7px", overflow:"hidden", position:"absolute", left:"0px", top:"0px"}}>
                <rect fill='green' height="7" width={parseInt(average)}/>
            </svg>

        </div>
    )
}

export default VideoWithVolume;