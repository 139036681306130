
export const doOffer = async (to, offer, username) => {
  console.log('offer')
  return JSON.stringify({
    type: 'offer',
    from: username,
    offer: JSON.stringify(offer)
  })
}

export const doAnswer = async (to, answer, username) => {
  console.log('answer')
  return JSON.stringify({
    type: 'answer',
    from: username,
    answer: JSON.stringify(answer)
  })
}

export const doCandidate = async (to, candidate, username) => {
  return JSON.stringify({
    type: 'candidate',
    from: username,
    candidate: JSON.stringify(candidate)
  })
}
