/*
    node_js
    2/1/2021 7:27 PM
    by Oleksandr
*/
import '../../CWP.css';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    calcApproach,
    calcDeparture,
    calcFlight,
    calcPassedPoints,
    calcStcaAll,
    getIsInSector,
    getProjection
} from "../../../../calc/calc";
import LoadExerciseWindow from "../windows/modalWindows/LoadExerciseWindow";
import Rbls from "../rbl/Rbls";
import {convertFromRaw, EditorState} from "draft-js";
import {
    ADVANCED_COLOR,
    ALERT_COLOR,
    ASSUMED_COLOR,
    CONCERNED_COLOR,
    DFLASSUMED_COLOR,
    SSR_COLOR,
    UNCONCERNED_COLOR
} from "../colors/colors";
import * as geolib from "geolib";
import {lvt, uklv} from "../../../../config/adap/map";
import Target from "../target/Target";
import ConnectingLine from "../target/ConnectingLine";
import AccLabel from "../label/AccLabel";
import TargetHistory from "../target/TargetHistory";
import Window from "../windows/Window";
import WorkloadWindow from "../windows/WorkloadWindow";

const TrackLayer = (props) => {
    let {
        flights, timeValue, tickValue, setTickValue, tickPause, setTickPause,
        zoom, setTickSpeed, tickSpeed, setTimeValue,
        startDate, setSelectedFlight, selectedFlight,
        timeOfUpdate, calcFlightsNum, trackNum,
        historicalFlights, setHistoricalFlights, setFlights, saveExercise,
        exerciseName, setExerciseName, isEpp,
        getSquawk, isPlt, files, isCon,
        setZoom, lonLatCenter, setLonLatCenter,
        isDemo, setAddTrackPlan,
        assumeFlight, decontrolFlight, conAssumeFlight,
        showCallsignMenu,
        updateFlightsTime, showFlightMenu, pttWindows, setPttWindows,
        setExerciseDescription, runways, getAllSim,
        speedVector, sectors,
        conflictInfoList, trackPlanSet, setTrackPlanSet
    } = props;


    const [flightSpan, setFlightSpan] = useState('');
    const [lines, setLines] = useState([]);
    const [flightTarget, setFlightTarget] = useState('');
    const [flightTargetHistory, setFlightTargetHistory] = useState('');
    const [markerDot, setMarkerDot] = useState('');
    const [trackPlan, setTrackPlan] = useState([]);
    const [trackPlanLines, setTrackPlanLines] = useState([]);
    const [trackPlanMtcdLines, setTrackPlanMtcdLines] = useState([]);
    const [stcasText, setStcasText] = useState([]);
    const [rblsArray, setRblsArray] = useState([]);
    const [isMouseMoveOnDiv, setIsMouseMoveOnDiv] = useState(false);
    const [isMouseMoveOnSvg, setIsMouseMoveOnSvg] = useState(false);
    const [stcas, setStcas] = useState([]);
    const [mtcdIds, setMtcdIds] = useState(new Set());
    const [stcasIds, setStcasIds] = useState(new Set());

    const trackLayerDiv = useRef();
    const trackLayerSvg = useRef();

    let calculateTrackPlan = (flt, tempTrackPlanLine, tempTrackPlan, trackPlanMtcdLines) => {
        let projection = getProjection(zoom, lonLatCenter);
        let {flight, timeShift, id} = flt;
        let index = isCon ? 0 : tickValue + timeShift;
        if (flight[index] && flight.length > index) {
            let pos = projection([flight[index].cP.longitude, flight[index].cP.latitude]);
            let top = pos[1] + 'px';
            let left = pos[0] + 'px';
            let tempFirstPoint = true; //false;
            let tempNextPoint = flight[index].nP;
            // console.log(flight, flt);
            let nextPointIndex = -1;
            if (flt.fpl[10][tempNextPoint]) nextPointIndex = flt.passedPoints.map(e => e.pp.pP).indexOf(flt.fpl[10][tempNextPoint].name);
            let nextPointTime = '';
            let dflColor = isCon ? getDflColor(flt) : getDflColor(flight[index]);

            if (nextPointIndex >= 0) {
                nextPointTime = new Date(startDate.getTime() + ((flt.passedPoints[nextPointIndex].index - timeShift) * 4000)).toLocaleTimeString('es-ES');

                pos = projection([flt.fpl[10][tempNextPoint].longitude, flt.fpl[10][tempNextPoint].latitude]);
                let top3 = pos[1] + 'px';
                let left3 = pos[0] + 'px';
                tempTrackPlanLine.push(<line x1={left} y1={top} x2={left3} y2={top3} stroke={dflColor}
                                             key={"trackPlan_line0" + flt.id}></line>);
            }
            for (let i = flight[index].nP; i < flt.fpl[10].length - 1; i++) {
                let nextPointIndex = flt.passedPoints.map(e => e.pp.pP).indexOf(flt.fpl[10][i].name);
                let nextPointTime = '';
                if (nextPointIndex >= 0) {
                    nextPointTime = new Date(startDate.getTime() + ((flt.passedPoints[nextPointIndex].index - timeShift) * 4000)).toLocaleTimeString('es-ES');

                    pos = projection([flt.fpl[10][i].longitude, flt.fpl[10][i].latitude]);
                    let top1 = pos[1] + 'px';
                    let left1 = pos[0] + 'px';
                    pos = projection([flt.fpl[10][i + 1].longitude, flt.fpl[10][i + 1].latitude]);
                    let top2 = pos[1] + 'px';
                    let left2 = pos[0] + 'px';
                    let dflPointTextL1, dflPointTextL2;
                    if (flt.fpl[10][i].name.substr(0, 2) === 'LL') {
                        dflPointTextL1 = flt.fpl[10][i].name;
                    } else {
                        dflPointTextL1 = (nextPointTime.substr(0, 5)) + ' ' + flt.fpl[10][i].name
                        dflPointTextL2 = Math.round(flt.passedPoints[nextPointIndex].pp.a / 100);
                    }
                    tempTrackPlanLine.push(<line x1={left1} y1={top1} x2={left2} y2={top2} stroke={dflColor}
                                                 key={"trackPlan_line" + i + flt.id}></line>);
                    tempTrackPlan.push(<div key={"trackPlan_" + id + "_" + flt.fpl[10][i].latitude + Math.random()}
                                            className='trackPlan'
                                            style={{
                                                position: 'absolute',
                                                top: top1,
                                                left: left1,
                                                color: dflColor
                                            }}>{dflPointTextL1}<br/>{dflPointTextL2}</div>);
                }
            }
        }
        if (trackPlanMtcdLines[id]) {
            trackPlanMtcdLines[id].forEach((tpml, tInd) => {
                let pos1 = projection(tpml.pos1);
                let pos2 = projection(tpml.pos2);
                tempTrackPlanLine.push(<line x1={pos1[0]} y1={pos1[1]}
                                             x2={pos2[0]}
                                             y2={pos2[1]}
                                             stroke="red"
                                             key={"trackPlan_lineMtcd"+ flt.id + "_" + tInd + "_" + pos1[0]}></line>);
            });
        }
    }

    let updateAllTrackPlans = () => {
        let tempTrackPlan = [];
        let tempTrackPlanLine = [];
        trackPlanSet.forEach((trPl, fltId) => {
            let flight1Index = flights.map(e => e.id).indexOf(trPl);
            if (flight1Index < 0) return;
            calculateTrackPlan(flights[flight1Index], tempTrackPlanLine, tempTrackPlan, trackPlanMtcdLines);
        });
        setTrackPlan(tempTrackPlan);
        setTrackPlanLines(tempTrackPlanLine);
        // console.log(tempTrackPlan);
    }
    const addTrackPlan = (id) => {
        if (trackPlanSet.has(id)) {
            trackPlanSet.delete(id);
        } else {
            trackPlanSet.add(id);
        }
        setTrackPlanSet(trackPlanSet);
        updateAllTrackPlans();
    }

    useEffect(()=>{
        updateAllTrackPlans();
    }, [trackPlanSet])

    let addRbl = (id, isDelete, pCoord, isFlight) => {
        // console.log(id);
        if (rblsArray.length === 0 || rblsArray[rblsArray.length - 1].p2Id) {
            console.log('first RBL')
            rblsArray.push({
                p1isFlight: isFlight,
                p1Id: id,
                p1Coord: pCoord,
                p1isMoving: false,
                rblId: 'rbl' + new Date().getTime(),
                p2isMoving: true,
                isMinSep: false
            });
            setIsMouseMoveOnDiv(true);
        } else {
            // console.log('second RBL')
            setIsMouseMoveOnDiv(false);

            let newRbl = rblsArray[rblsArray.length - 1];
            newRbl.p2isFlight = isFlight;
            newRbl.p2Id = id;
            newRbl.p2isMoving = false;
            newRbl.p2Coord = pCoord;
            newRbl.p2CoordXY = null;

        }
        setRblsArray([...rblsArray]);

    }

    const mouseMoveOnDiv = useCallback((e) => {
        if (rblsArray.length === 0 || (rblsArray[rblsArray.length - 1] === undefined && !rblsArray[rblsArray.length - 1].p2isMoving)) return;
        rblsArray[rblsArray.length - 1].p2CoordXY = {clientX: e.clientX, clientY: e.clientY};
        setRblsArray([...rblsArray]);
    }, [rblsArray, setRblsArray, tickValue, setTickValue]);

    useEffect(() => {
        // console.log('isMouseMoveOnDiv', isMouseMoveOnDiv)
        if (isMouseMoveOnDiv) trackLayerDiv.current.addEventListener('mousemove', mouseMoveOnDiv);
        else trackLayerDiv.current.removeEventListener('mousemove', mouseMoveOnDiv);
        return () => {
            if (trackLayerDiv.current) return trackLayerDiv.current.removeEventListener('mousemove', mouseMoveOnDiv)
        }
    }, [isMouseMoveOnDiv, setIsMouseMoveOnDiv, mouseMoveOnDiv])

    const mouseUpOnDiv = useCallback((e) => {
        // console.log('mouseUpOnDiv', isMouseMoveOnDiv, isMouseMoveOnSvg);
        if (!isMouseMoveOnSvg && e.button === 1) {
            let projection = getProjection(zoom, lonLatCenter);
            let coord = projection.invert([e.clientX, e.clientY]);
            addRbl('point', null, {lat: coord[1], lon: coord[0]}, false)
            // console.log(rblsArray)
        }
        if (isMouseMoveOnDiv && e.button === 2) {
            // console.log('right mouse delete isMouseMoveOnDiv')
            setIsMouseMoveOnDiv(false);
            rblsArray.splice(rblsArray.length - 1, 1);
            setRblsArray([...rblsArray]);
        }
    }, [isMouseMoveOnDiv, rblsArray, setRblsArray, isMouseMoveOnSvg]);

    useEffect(() => {
        trackLayerDiv.current.addEventListener('mouseup', mouseUpOnDiv, false);
        return () => {
            if (trackLayerDiv.current) return trackLayerDiv.current.removeEventListener('mouseup', mouseUpOnDiv, false);
        }
    }, [isMouseMoveOnDiv, setIsMouseMoveOnDiv, mouseUpOnDiv])

    let deleteRbl = (e) => {
        // console.log('rbl delete: ', e.target.getAttribute('rblId'))
        let rblId = e.target.rblId ? e.target.rblId : e.target.getAttribute('rblid');
        let rblIndex = rblsArray.map(e => e.rblId).indexOf(rblId);
        if (rblIndex < 0) return;
        rblsArray.splice(rblIndex, 1);
        setRblsArray([...rblsArray]);
    }

    let minSepRbl = (e) => {
        let rblId = e.target.rblId ? e.target.rblId : e.target.getAttribute('rblid');
        // console.log(rblId);
        let rblIndex = rblsArray.map(e => e.rblId).indexOf(rblId);
        if (rblIndex < 0) return;
        rblsArray[rblIndex].isMinSep = true;
        setRblsArray([...rblsArray]);
        // console.log(rblsArray);
    }

    const loadExercise = (file) => {
        // setFlights([]);
        // let promptName = window.prompt('Exercise name:');
        if (file === null) return;
        //  return (<LoadExerciseWindow open={true} />);

        setExerciseName(file.replace('.sim', ''));
        let flightsClone = [];
        setTickValue(0);
        console.log('fl clone: ', flightsClone);
        // flights = [];
        fetch(file,
            {
                mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                console.log(response);
                return response.json();
            })
            .then(resp => {
                resp.forEach((flight) => {
                    const index = historicalFlights.map(e => e.id).indexOf(flight.id);
                    if (index >= 0) {
                        historicalFlights[index].timeShift = flight.timeShift;
                        // console.log("calcFlight: " + index);
                        let tempFlight;
                        if (isPlt) {
                            tempFlight = JSON.parse(JSON.stringify(historicalFlights[index]));
                        } else {
                            tempFlight = historicalFlights[index];
                        }
                        if (flight.rfl) {
                            tempFlight.rfl = flight.rfl;
                            tempFlight.flight[0].sA = parseInt(flight.rfl) * 100;
                            tempFlight.flight[0].isAdvanced = true;
                            tempFlight.flight[0].isCorrelated = true;
                        }
                        tempFlight.squawk = getSquawk();
                        calcDeparture(tempFlight, runways);
                        calcApproach(tempFlight, runways);
                        // calcFlight(tempFlight, 0);

                        calcFlight(tempFlight, 0, startDate.getTime());
                        let ind1 = tempFlight.timeShift;

                        let tempSetOfBasicSetors = new Set();
                        for (let ii = ind1; ii < tempFlight.flight.length; ii += 15) {
                            if (tempFlight.flight[ii] && tempFlight.flight[ii].iBSN) tempSetOfBasicSetors.add(tempFlight.flight[ii].iBSN);
                        }
                        let arrayOfBasicSetors = Array.from(tempSetOfBasicSetors);
                        arrayOfBasicSetors.forEach(a => {
                            if (sectors.ops.basicSectors.includes(a)) tempFlight.flight[0].isAdvanced = true;
                        });
                        calcFlight(tempFlight, 0, startDate.getTime());

                        if ((isDemo || isPlt) && tempFlight.timeShift > 0) {
                            tempFlight.flight.splice(0, flight.timeShift);
                            tempFlight.timeShift = 0;
                        }


                        tempFlight.dx = 0;
                        tempFlight.dy = 0;
                        tempFlight.isTrackPlan = false;

                        calcPassedPoints(tempFlight, startDate.getTime());
                        flightsClone.push(tempFlight);
                    }
                })
                setFlights(flightsClone);
                return null;
            })
            .then(() => calcFlightsNum(flightsClone))
            .then(() => {
                // if (flightsClone.length > 0) setSelectedFlight(flightsClone[0].id)
                setTickPause(false);
            });
        let searchParams = new URLSearchParams();
        searchParams.set('name', file.replace('.sim', ''));
        // searchParams.set('exerciseDescription', exerciseDescription);
        const requestOptions = {
            method: 'POST',
            mode: "cors",
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: searchParams
        };
        fetch('https://lvivacc.site/ptt/exercise.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data[0]) {
                    let tempDescr;
                    try {
                        tempDescr = convertFromRaw(JSON.parse(data[0].exerciseDescription));
                        setExerciseDescription(EditorState.createWithContent(tempDescr));
                        // console.log(tempDescr);
                    } catch (e) {
                        // console.log(e);
                        setExerciseDescription(EditorState.createEmpty());
                    }

                } else {
                    setExerciseDescription(EditorState.createWithText(''));
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    useEffect(() => {
        if (isCon) {
            return;
        }
        if (isDemo) {
            loadExercise("JS_test2.sim")
            return;
        }
        // if (!isEpp || !isPlt) return;

        // console.log('autoload');
        // getAllSim().then(() => setPttWindows({...pttWindows, leWindow: true}));

    }, [historicalFlights]);

    useEffect(() => {

        function onKeyup(e) {

            if (isEpp) {

                // if (e.key === "d") {
                //     console.log("download");
                //     let text = '';
                //     flights.forEach((flight) => {
                //         let route = '';
                //
                //         if (flight.passedPoints) {
                //             let entryPointIndex = -1;
                //             let entryPointTime = '';
                //             if (flight.entryCop) {
                //                 entryPointIndex = flight.passedPoints.map(e => e.pp.pP).indexOf(flight.entryCop);
                //                 entryPointTime = new Date(startDate.getTime() +
                //                     ((flight.passedPoints[entryPointIndex].index - flight.timeShift) * 4000)).toLocaleTimeString('es-ES');
                //                 entryPointTime = entryPointTime.substr(0, 2) + entryPointTime.substr(3, 2);
                //             }
                //             flight.passedPoints.forEach((point, index) => {
                //                 if (point.pp.pP.length !== 4) {
                //                     let time = (index === entryPointIndex) ? '/H' + entryPointTime : '';
                //                     route += point.pp.pP + time + ' DCT ';
                //                 } else {
                //                     route = route.substr(0, route.length - 5);
                //                 }
                //             });
                //         }
                //
                //         // else {
                //         //     if (flight.passedPoints) {
                //         //         let entryPointIndex = flight.passedPoints.map(e => e.pp.pP).indexOf(flight.flight[0].nPN);
                //         //         if (entryPointIndex >= 0) {
                //         //             let entryPointTime = new Date(startDate.getTime() +
                //         //                 ((flight.passedPoints[entryPointIndex].index - flight.timeShift) * 4000)).toLocaleTimeString('es-ES');
                //         //             // entryPTtext = flight.flight[0].nPN + '/' + entryPointTime.substr(0, 5);
                //         //         }
                //         //     }
                //         // }
                //
                //         let eobt = new Date(startDate.getTime() +
                //             ((0 - flight.timeShift) * 4000)).toLocaleTimeString('es-ES');
                //         eobt = eobt.substr(0, 2) + eobt.substr(3, 2);
                //
                //         text += flight.fpl[0] + '-' + flight.fpl[1] + '/A' + getSquawk2() + '-' + flight.fpl[2] + '\n';
                //         text += '-' + flight.fpl[3] + '-' + flight.fpl[4] + '\n';
                //         text += '-' + flight.fpl[5].substr(0, 4) + eobt + '\n';
                //         text += ('-N0' + parseInt(flight.spd) + 'F' + flight.rfl + ' ' + route).match(/(.{1,68}\s|[&|$])\s*/g).join('\n') + '\n';
                //         text += '-' + flight.fpl[7] + '\n';
                //         text += '-' + flight.fpl[8].match(/(.{1,68}\s|[)])\s*/g).join('\n') + '\n\n';
                //     })
                //     download(exerciseName + '.txt', text);
                // }
            }
        }

        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);

        calcFlightsNum(flights);
        console.log(historicalFlights);
    }, [historicalFlights, flights, exerciseName])

///TODO переробити updateFlights
    const updateFlights = () => {
        drawTrackLayer();
    }

    useEffect(() => {
        updateFlights()
    }, [updateFlightsTime]);


    useEffect(() => {

        trackLayerSvg.current.addEventListener('mousedown', mouseDownOnSVG, false);
        trackLayerSvg.current.addEventListener('mouseup', mouseUpOnSVG, false);


    }, []);

    useEffect(() => {
        //TODO STCA prediction
        setStcas(calcStcaAll(flights, tickValue + 1, isCon));
        let tempStcasIds = new Set();
        let tempConflictsIds = new Set();
        let tempStcaText = [];
        let tMtcdLines = [];
        let tempTrackPlanMtcdLine = [];

        Array.from(conflictInfoList).forEach(conflict => {
            let tf1ei = conflict.flight1EndIndex - conflict.flight1.timeShift;
            let tf2ei = conflict.flight2EndIndex - conflict.flight2.timeShift;
            let tf1si = conflict.flight1StartIndex - conflict.flight1.timeShift;
            let tf2si = conflict.flight2StartIndex - conflict.flight2.timeShift;
            if (tf1ei > tickValue) {
                tempConflictsIds.add(conflict.flight1.id);
                tMtcdLines[conflict.flight1.id] = [];
                for (let i = tf1si > tickValue ? tf1si + conflict.flight1.timeShift : tickValue + conflict.flight1.timeShift; i <= tf1ei + conflict.flight1.timeShift; i++) {
                    let pos = [conflict.flight1.flight[i].cP.longitude, conflict.flight1.flight[i].cP.latitude];
                    tMtcdLines[conflict.flight1.id].push(pos);
                }
                tempTrackPlanMtcdLine[conflict.flight1.id] = tempTrackPlanMtcdLine[conflict.flight1.id] || [];

                tMtcdLines[conflict.flight1.id].forEach((pos, ind) => {
                    if (tMtcdLines[conflict.flight1.id][ind + 1]) {
                        tempTrackPlanMtcdLine[conflict.flight1.id].push({
                            pos1: pos,
                            pos2: tMtcdLines[conflict.flight1.id][ind + 1]
                        });
                    }
                });
            }

            if (tf2ei > tickValue) {
                tempConflictsIds.add(conflict.flight2.id);
                tMtcdLines[conflict.flight2.id] = [];
                for (let i = tf2si > tickValue ? tf2si + conflict.flight2.timeShift : tickValue + conflict.flight2.timeShift; i <= tf2ei + conflict.flight2.timeShift; i++) {
                    let pos = [conflict.flight2.flight[i].cP.longitude, conflict.flight2.flight[i].cP.latitude];
                    tMtcdLines[conflict.flight2.id].push(pos);
                }
                tempTrackPlanMtcdLine[conflict.flight2.id] = tempTrackPlanMtcdLine[conflict.flight2.id] || [];
                tMtcdLines[conflict.flight2.id].forEach((pos, ind) => {
                    if (tMtcdLines[conflict.flight2.id][ind + 1]) {
                        tempTrackPlanMtcdLine[conflict.flight2.id].push({
                            pos1: pos,
                            pos2: tMtcdLines[conflict.flight2.id][ind + 1]
                        });
                    }
                });
            }


        });
        setTrackPlanMtcdLines(tempTrackPlanMtcdLine);


        setMtcdIds(tempConflictsIds);
        // setStcasIds(tempStcasIds);
        stcas.forEach(a => {
            tempStcasIds.add(a.id1);
            tempStcasIds.add(a.id2);

            tempStcaText.push(<tr key={"stca_" + a.id1 + "_" + a.id2} className='sddTableTr' style={{color: "red"}}>
                <td style={{paddingLeft: "10px", paddingRight: "10px"}}>{a.callsign1}</td>
                <td style={{paddingLeft: "10px", paddingRight: "10px"}}>{a.callsign2}</td>
                <td style={{paddingLeft: "10px", paddingRight: "10px"}}>{a.distance.toFixed(2) + 'NM'}</td>
            </tr>);
        });
        setStcasIds(tempStcasIds);
        setStcasText(tempStcaText);
    }, [tickValue, flights, updateFlightsTime])

    useEffect(() => {
            drawTrackLayer();
            updateAllTrackPlans();
        },
        [tickValue, setFlightSpan, startDate, zoom, setFlightTarget, selectedFlight, timeOfUpdate, setZoom,
            lonLatCenter, setLonLatCenter, flights, setStcasIds, stcasIds, speedVector]
    );
    const getFlightColor = (flight) => {
        // if (isPlt || isEpp) {
        //     return flight[index].isAssumed ? ASSUMED_COLOR :
        //         flight[index].isAdvanced ? ADVANCED_COLOR :
        //             flight[index].isCorrelated ? UNCONCERNED_COLOR : SSR_COLOR;
        // } else if (isCon) {
        return flight.isAssumed ? ASSUMED_COLOR :
            flight.isAdvanced ? ADVANCED_COLOR :
                flight.isCorrelated ? UNCONCERNED_COLOR : SSR_COLOR;
        // } else {
        //     return SSR_COLOR
        // }
    }
    const getCallsignColor = (flight, color, cP, a) => {
        const isInSector = getIsInSector(cP.latitude, cP.longitude, a, sectors);
        return flight.isAssumed ? ASSUMED_COLOR :
            flight.isAdvanced ? ASSUMED_COLOR :
                isInSector ? CONCERNED_COLOR : color;
    }

    const getDflColor = (flight) => {
        return flight.isAssumed ? DFLASSUMED_COLOR : getFlightColor(flight);
    }

    const drawTrackLayer = () => {
        //TODO Time calculation
        // startDate.setHours(12,
        //     tickValue * 4 / 60 % 60,
        //     tickValue * 4 % 60);
        let flightSpan2 = [];
        let tempLines = [];
        let target = [];
        let targetHistory = [];
        let tempMarkerDot = [];
        let tempTrackPlan = [];
        let tempTrackPlanLine = [];

        // console.log(flights);

        let projection = getProjection(zoom, lonLatCenter);
        flights.forEach((flt, fltId) => {
            let {flight, timeShift, id} = flt;


            let index = isCon ? 0 : tickValue + timeShift;
            let color, callsignColor;


            // let innerText = '';
            let top, left, visibility;
            // console.log(index, flight[index], flight.length, timeShift)
            if (flight[index] !== null && flight.length > index && index >= 0) {
                color = isCon ? getFlightColor(flt) : getFlightColor(flight[index]);
                flight.isUklv = geolib.isPointInPolygon({
                    latitude: flight[index].cP.latitude,
                    longitude: flight[index].cP.longitude
                }, uklv);
                flt.isLvt = geolib.isPointInPolygon({
                    latitude: flight[index].cP.latitude,
                    longitude: flight[index].cP.longitude
                }, lvt) && flight[index].a <= 16500;
                callsignColor = isCon ? getCallsignColor(flt, color, flight[index].cP, flight[index].a) :
                    getCallsignColor(flight[index], color, flight[index].cP, flight[index].a);
                visibility = 'visible';
                let pos = projection([flight[index].cP.longitude, flight[index].cP.latitude]);
                top = pos[1] + 'px';
                left = pos[0] + 'px';
                let targetColor = stcasIds.has(id) ? ALERT_COLOR : color;
                let isMtcd = mtcdIds.has(id) ? true : false;
                let tempTarget = (<Target color={targetColor}
                                          track={{X: parseInt(left), Y: parseInt(top)}}
                                          ssrSize={1} key={"target_" + id} id={id}
                                          selectedFlight={selectedFlight} setSelectedFlight={setSelectedFlight}
                                          flight={flt} updateAllTrackPlans={updateAllTrackPlans}
                                          addTrackPlan={addTrackPlan}
                />);
                target.push(tempTarget);
                tempLines.push(<ConnectingLine key={'connecting_line_' + id}
                                               track={flight} fltId={id}
                                               left={left} top={top} color={targetColor}/>)
                ///Calculating Vector
                if (speedVector.isVisible) {
                    let previousPosition = flight[index].cP;
                    for (let i = 1; i <= speedVector.time; i++) {
                        let nextPosition = geolib.computeDestinationPoint(
                            flight[index].cP,
                            ((flight[index].gs || flight[index].t) * 1.852 * 1000) * i / 60,
                            flight[index].h + 6
                        );
                        let pos0 = projection([previousPosition.longitude, previousPosition.latitude]);
                        pos = projection([nextPosition.longitude, nextPosition.latitude]);
                        targetHistory.push(
                            <>
                                <defs>
                                    <marker id={'dot' + id} viewBox='0 0 6 6' refX='3' refY='3'
                                            markerWidth='3' markerHeight='3'>
                                        <circle cx='3' cy='3' r='3' fill={targetColor}/>
                                    </marker>
                                </defs>
                                <line x1={pos0[0]} y1={pos0[1]}
                                      x2={pos[0]}
                                      y2={pos[1]}
                                      stroke={targetColor}
                                      markerEnd={'url(#dot' + id + ')'}
                                ></line>
                            </>);
                        previousPosition = nextPosition;
                    }
                }
                ///Pushing AccLabel
                let accLabel = (<AccLabel color={color} callsignColor={callsignColor}
                                          index={index} left={left} top={top}
                                          setSelectedFlight={setSelectedFlight} fltId={id}
                                          selectedFlight={selectedFlight}
                                          key={"acclabel_" + id} flight={flt}
                                          id={"acclabel_" + id}
                                          updateFlights={updateFlights}
                                          timeOfUpdate={timeOfUpdate}
                                          isStca={stcasIds.has(id)}
                                          tickValue={tickValue} target={tempTarget}
                                          isPlt={isPlt} isCon={isCon} isEpp={isEpp}
                                          addRbl={addRbl} addTrackPlan={addTrackPlan}
                                          isMtcd={isMtcd}
                    // udateFlights={udateFlights}
                                          assumeFlight={assumeFlight}
                                          conAssumeFlight={conAssumeFlight}
                                          decontrolFlight={decontrolFlight}
                                          showCallsignMenu={showCallsignMenu}
                                          showFlightMenu={showFlightMenu}
                                          startDate={startDate}
                />);
                flightSpan2.push(accLabel);

                ///Calculating History
                for (let i = index - 10; i < index; i++) {
                    if (i > 0) {
                        pos = projection([flight[i].cP.longitude, flight[i].cP.latitude]);
                        let hTop = pos[1];
                        let hLeft = pos[0];
                        targetHistory.push(<TargetHistory color={targetColor}
                                                          track={{X: parseInt(hLeft), Y: parseInt(hTop)}}
                                                          key={"target_history_" + id + flight[i].cP.latitude}
                        />);
                    }
                }
                // calculateTrackPlan(flt, tempTrackPlanLine, tempTrackPlan);
            } else {
                visibility = 'hidden';
            }
        })
        ;
        setFlightSpan(flightSpan2);
        setLines(tempLines);
        setFlightTargetHistory(targetHistory);
        setMarkerDot(tempMarkerDot);
        setFlightTarget(target);
        // setTrackPlan(tempTrackPlan);
        // setTrackPlanLines(tempTrackPlanLine);

        // updateAllTrackPlans();
    }

    let mouseDownOnSVG = (e) => {
        setIsMouseMoveOnSvg(false);
        trackLayerSvg.current.addEventListener('mousemove', mouseMoveOnSVG, false);
    }
    let mouseMoveOnSVG = (e) => {

        if (e.buttons === 2) {
            let tempZoom = zoom += e.movementY * 30;

            if (tempZoom < 1000) tempZoom = 1000;
            setZoom(tempZoom);
        }

        if (e.buttons === 4) {
            setIsMouseMoveOnSvg(true);
            lonLatCenter = [lonLatCenter[0] - e.movementX / 100,
                lonLatCenter[1] + e.movementY / 100];
            setLonLatCenter(lonLatCenter);
        }
    }

    let mouseUpOnSVG = (e) => {
        // console.log('mouseUpOnSVG', isMouseMoveOnSvg);
        showCallsignMenu(null);
        trackLayerSvg.current.removeEventListener('mousemove', mouseMoveOnSVG, false);
    }


    let updateTickPause = () => {
        setTickPause(!tickPause);
    }
    let selectFlight = (e) => {
        setSelectedFlight(e.target.id);
    }

    let addFlight = (e) => {
        const index = historicalFlights.map(e => e.id).indexOf(e.target.id);
        historicalFlights[index].dx = 0;
        historicalFlights[index].dy = 0;
        setFlights([...flights, historicalFlights[index]]);
        calcFlightsNum(flights);
    }

    let onSpeedChange = (e) => {
        setTickSpeed(parseInt(e.target.value));
    }

    return <div className="TrackLayer" ref={trackLayerDiv}>
        {trackPlan}
        <svg width="2000" height="2000" id={"trackLayerSvg"} ref={trackLayerSvg}>

            {trackPlanLines}
            {/*{trackPlanMtcdLines}*/}
            {flightTargetHistory}
            {lines}
            {/*{tr}*/}
            <Rbls rbls={rblsArray} projection={getProjection(zoom, lonLatCenter)}
                  tickValue={tickValue} flights={flights} deleteRbl={deleteRbl}
                  minSepRbl={minSepRbl}/>

        </svg>

        {flightTarget}
        {flightSpan}
        {pttWindows.systemTimeWindow &&
        <Window isVisible={true} toggleWindow={() => {
            setPttWindows({...pttWindows, systemTimeWindow: false});
        }
        } label={'System Time'} width={300}
                content={<>
                    <div style={{width: '294px', background: 'black', color: 'white', fontSize: '70px', margin: '3px'}}>
                        {new Date(startDate.getTime() + (timeValue * 1000)).toLocaleTimeString('es-ES')}
                    </div>

                    {(isEpp || isPlt) && <>
                        <div>
                            <span className={'buttonSDD'} style={{float: 'left', margin: '3px'}}
                                  onClick={updateTickPause}>{tickPause ? 'Start' : 'Pause'}</span>

                            <div style={{fontSize: '15px', padding: '4px'}} onChange={onSpeedChange}>
                                <input type="radio" value={1000} name="speed" checked={tickSpeed === 1000}/> x1
                                <input type="radio" value={500} name="speed" checked={tickSpeed === 500}/> x2
                                <input type="radio" value={250} name="speed" checked={tickSpeed === 250}/> x4
                                {/*<input type="radio" value={5} name="speed" checked={tickSpeed === 5}/> x200*/}
                            </div>
                            {/*<button onClick={saveExercise}>Save</button>*/}
                        </div>
                        <div>
                            <input style={{width: '98%'}} value={tickValue} type="range" id="time" name="time" min="0"
                                   max="900" onChange={(e) => {
                                setTimeValue(parseInt(e.target.value) * 4);
                                setTickValue(parseInt(e.target.value));
                            }}/>
                        </div>
                    </>}
                </>}
        />}


        {stcasText.length > 0 && <div style={{
            position: "fixed", right: "0px", top: "0px", fontSize: "15px",
            width: "300px"
        }}>
            <Window label='ST CONFLICT' width={'auto'} hideCloseButton={true}
                    content={<table>
                        <thead>
                        <tr>
                            <th className='headerTh'>C/S</th>
                            <th className='headerTh' className='headerTh'>C/S</th>
                            <th className='headerTh'>CDIS</th>
                        </tr>
                        </thead>
                        <tbody>{stcasText}</tbody>
                    </table>}/>
        </div>}
        {((isEpp || isPlt) && pttWindows.workloadWindow) &&
        <WorkloadWindow {...props} />

        }


        <LoadExerciseWindow open={pttWindows.leWindow} files={files}
                            onClose={(value) => {
                                console.log(value);
                                loadExercise(value);
                                setPttWindows({...pttWindows, leWindow: false});
                            }}/>
    </div>
}

export default TrackLayer;