/*
    node_js
    2/11/2021 3:58 PM
    by Oleksandr
*/
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export default function RonWindow(props) {
    const {flight, tickValue, open, onClose} = props;
    const points = flight.fpl[10].filter((p, index) => {
        return (flight.flight[tickValue].nP <= index)
    }).map(p => p.name);
    return (
        <>
            <Dialog open={open} onClose={() => {
                onClose(null);
            }}>
                <DialogTitle id="ronWindow-title">Resume own navigation to:</DialogTitle>
                <List>
                    {points.map((point) => (
                        <ListItem button key={'ronWindow_' + point} onClick={() => onClose(point)}>
                            <ListItemText primary={point}/>
                        </ListItem>
                    ))}
                </List>

            </Dialog>
        </>
    );
}

// export default RonWindow;