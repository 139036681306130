/*
    node_js
    3/23/2021 2:02 AM
    by Oleksandr
*/

import Window from "../Window";
import React from "react";

const MapWindow = (props) => {
    const {pttWindows, setPttWindows, map, setMap} = props;

    return (
        <Window label='MAP #1' width={'auto'} dXY={{dx: 280, dy: 80}}
                toggleWindow={() => setPttWindows({...pttWindows, mapWindow: false})}
                content={<>
                    <div key='map1' style={{
                        display: "grid", gridTemplateColumns: "auto auto",
                        gridGap: "7px", color: "white", textAlign: "left", padding: "5px"
                    }}>
                        <label htmlFor='points'>POINTS</label>
                        <input type='checkbox' checked={map.points ? 'checked' : ''} name='points' value='points'
                               onClick={e => setMap({...map, [e.target.value]: !map[e.target.value]})}/>
                        <label htmlFor='ukll_app_rw13ils'>ILS13</label>
                        <input type='checkbox' checked={map.ukll_app_rw13ils ? 'checked' : ''} name='ukll_app_rw13ils'
                               value='ukll_app_rw13ils'
                               onClick={e => setMap({...map, [e.target.value]: !map[e.target.value]})}/>
                        <label>RNAV13</label>
                        <input type='checkbox' checked={map.ukll_app_rw13star ? 'checked' : ''} name='ukll_app_rw13star'
                               value='ukll_app_rw13star'
                               onClick={e => setMap({...map, [e.target.value]: !map[e.target.value]})}/>
                        <label>ILS31</label>
                        <input type='checkbox' checked={map.ukll_app_rw31ils ? 'checked' : ''} name='ukll_app_rw31ils'
                               value='ukll_app_rw31ils'
                               onClick={e => setMap({...map, [e.target.value]: !map[e.target.value]})}/>
                        <label>RNAV31</label>
                        <input type='checkbox' checked={map.ukll_app_rw31star ? 'checked' : ''} name='ukll_app_rw31star'
                               value='ukll_app_rw31star'
                               onClick={e => setMap({...map, [e.target.value]: !map[e.target.value]})}/>
                        <label>RNAV15</label>
                        <input type='checkbox' checked={map.ukln_app_rw15star ? 'checked' : ''} name='ukln_app_rw15star'
                               value='ukln_app_rw15star'
                               onClick={e => setMap({...map, [e.target.value]: !map[e.target.value]})}/>
                        <label>RNAV33</label>
                        <input type='checkbox' checked={map.ukln_app_rw33star ? 'checked' : ''} name='ukln_app_rw33star'
                               value='ukln_app_rw33star'
                               onClick={e => setMap({...map, [e.target.value]: !map[e.target.value]})}/>
                        <label>External Freq</label>
                        <input type='checkbox' checked={map.extFreq ? 'checked' : ''} name='extFreq'
                               value='extFreq'
                               onClick={e => setMap({...map, [e.target.value]: !map[e.target.value]})}/>
                    </div>
                </>}/>
    )
}

export default MapWindow;