/*
    node_js
    3/5/2021 11:58 PM
    by Oleksandr
*/

import React from "react";
import {calcFlight} from "../../../../calc/calc";

const HdgMenu = (props) => {
    const hdgArray = ['000', '010', '020', '030', '040', '050', '060', '070', '080', '090', '100', '110', '120', '130', '140', '150', '160', '170', '180', '190', '200', '210', '220', '230', '240', '250', '260', '270', '280', '290', '300', '310', '320', '330', '340', '350'];
    const {
        callsignMenuFlight, isCon, tickValue, showFlightMenu, typeOfFlightMenu, startDate,
        dispatchFlightClearance
    } = props;
    let index = isCon || !callsignMenuFlight ? 0 : tickValue + callsignMenuFlight.flight.timeShift;
    return (
        <>
            {(callsignMenuFlight && typeOfFlightMenu && callsignMenuFlight.flight.flight[index]) &&
            <div className={'callsignMenu'}
                 style={{left: callsignMenuFlight.clientX + 'px', top: callsignMenuFlight.clientY + 'px'}}>
                <span>{callsignMenuFlight.flight.c}</span><br/>
                <select style={{width: '100%'}} name="Hdg" size="8"
                        onInput={(e) => {
                            dispatchFlightClearance({
                                type: 'hdg',
                                value: e.target.value,
                                flight: callsignMenuFlight.flight,
                                index,
                                startDate,
                            });
                            showFlightMenu(null);
                        }}>
                    {hdgArray.map((hdg) => {
                        let selected = callsignMenuFlight.flight.flight[index].sH === parseInt(hdg) ? true : false;
                        return (<option selected={selected} value={hdg}>{hdg}</option>)
                    })}
                </select>
                <input type="number" autoFocus style={{width: '55px'}} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (!e.target.value) return;
                        dispatchFlightClearance({
                            type: 'hdg',
                            value: e.target.value,
                            flight: callsignMenuFlight.flight,
                            index,
                            startDate,
                        });
                        showFlightMenu(null);
                    }
                }}/>
            </div>

            }

        </>
    )
}
export default HdgMenu;