/*
    node_js
    2/22/2021 7:15 PM
    by Oleksandr
*/

import {Redirect, Route} from "react-router-dom";
import React from "react";
import auth from '../auth/Auth';

export const ProtectedRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest}
            render={props => {
                if (auth.isAuthenticated()) {
                    return <Component {...props}/>;
                } else {
                    return <Redirect to={
                        {
                            pathname: "/",
                            state: {
                                from: props.location
                            }
                        }
                    } />
                    }}
                }
                />
                )
            }