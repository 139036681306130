/*
    node_js
    3/13/2021 5:35 PM
    by Oleksandr
*/

import React from "react";
import {calcApproach, calcDeparture, calcFlight} from "../../../../../calc/calc";

const HistFlightsList = (props) => {
    const {
        historicalFlights, columns, calcFlightsNum, setFlights, flights,
        startDate, runways, updateFlightForEpp
    } = props;
    const addFlight = (e) => {
        const index = historicalFlights.map(e => e.id).indexOf(e.target.getAttribute('fltId'));
        calcDeparture(historicalFlights[index], runways);
        calcApproach(historicalFlights[index], runways);
        updateFlightForEpp(historicalFlights[index], true);
        // calcFlight(historicalFlights[index], 0, startDate.getTime());

        // calcPassedPoints(historicalFlights[index]);
        historicalFlights[index].dx = 0;
        historicalFlights[index].dy = 0;
        setFlights([...flights, historicalFlights[index]]);
        calcFlightsNum(flights);
    }
    return (

        <tbody>
        {
            historicalFlights.map(row =>
                <tr className='sddTableTr'>
                    {
                        columns.map(column => <td className='sddTableTd'>
                            {row[column]}
                        </td>)
                    }
                    <td className='sddTableTd'>
                        <span className='buttonSDD' fltId={row.id} onClick={addFlight}>Add</span>
                    </td>
                </tr>
            )
        }

        </tbody>

    )
}

export default HistFlightsList;