/*
    node_js
    2/22/2021 7:41 PM
    by Oleksandr
*/

import CWP from "./cwp/CWP";
import ATG from "./atg/ATG";
import {ProtectedRoute} from "../auth/ProtectedRoute";
import {useContext} from "react";
import {CwpContext} from "../contexts/CwpContext";

const PLT = () => {
    const context = useContext(CwpContext);

    return (
        <>
            <CWP isPlt={true} context={context}/>
            <ATG isPlt={true} context={context}/>
        </>
    )
}
export default PLT;