/*
    node_js
    11/28/2021 11:26 PM
    by Oleksandr
*/

import Window from "../Window";
import React, {useEffect, useState} from "react";

const MtcdList = (props) => {
    const {setPttWindows, pttWindows, conflictInfoList, tickValue, trackPlanSet, setTrackPlanSet} = props;
    const [mtcdText, setMtcdText] = useState([]);
    useEffect(() => {
        let tMtcdText = [];
        Array.from(conflictInfoList).filter(conflict => conflict.endTickValue >= tickValue)
            .forEach((conflict, ind) => {
                tMtcdText.push(<tr className='sddTableTr'>
                    <td className='sddTableTd' style={{color: "red"}} onMouseUp={e => {
                        if (e.button !== 2) return;
                        if (trackPlanSet.has(conflict.flight1.id) && trackPlanSet.has(conflict.flight2.id)) {
                            trackPlanSet.delete(conflict.flight1.id);
                            trackPlanSet.delete(conflict.flight2.id);
                        } else {
                            trackPlanSet.add(conflict.flight1.id);
                            trackPlanSet.add(conflict.flight2.id);
                        }
                        setTrackPlanSet(trackPlanSet);
                    }}>{ind + 1}</td>
                    <td className='sddTableTd'>{conflict.startTickValue - tickValue > 0 ? parseInt((conflict.startTickValue - tickValue) / 15) : 0}</td>
                    <td className='sddTableTd'>{conflict.flight1.flight[tickValue].c}</td>
                    <td className='sddTableTd'>{conflict.flight2.flight[tickValue].c}</td>
                    <td className='sddTableTd'>{conflict.minDist.toFixed(2) + "NM"}</td>
                </tr>)
            })
        setMtcdText(tMtcdText);
        // console.log("conflictInfoList", , tickValue);

    }, [conflictInfoList, tickValue]);
    return <>
        <Window label='CONFLICT/RISK DISPLAY'
                toggleWindow={() => setPttWindows({...pttWindows, mtcdWindow: false})}
                content={
                    <table>
                        <thead>
                        <tr>
                            <td className='headerTh'>#</td>
                            <td className='headerTh'>TIME</td>
                            <td className='headerTh'>C/S</td>
                            <td className='headerTh'>C/S</td>
                            <td className='headerTh'>MDIS</td>
                        </tr>
                        </thead>
                        <tbody>
                        {mtcdText}
                        </tbody>
                    </table>
                }>

        </Window>
    </>
}

export default MtcdList;