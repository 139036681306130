/*
    node_js
    2/2/2021 12:23 AM
    by Oleksandr
*/
import React from "react";
import SsrTarget from "./SsrTarget";

const Target = (props) => {
    const {
        flight, setSelectedFlight, selectedFlight, id,
        updateAllTrackPlans, addTrackPlan
    } = props;
    let clickHoldTimer = null;
    const handleMouseDown = (e) => {
        if (e.buttons === 1)
            clickHoldTimer = setTimeout(() => {
                setSelectedFlight(selectedFlight === id ? null : id);
            }, 1000);

        if (e.buttons === 2) {
            // flight.isTrackPlan = !flight.isTrackPlan;
            addTrackPlan(id);
            // updateAllTrackPlans();
        }
    }
    const handleMouseUp = (e) => {


        clearTimeout(clickHoldTimer);
    }
    // const {size} = props;
    const target = <SsrTarget {...props}
                              onMouseDown={handleMouseDown}
                              onMouseUp={handleMouseUp}/>// track.hasDAP ? <SmodeTarget {...props} /> : <SsrTarget {...props} />
    return (target);
}
export default Target;