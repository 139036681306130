/*
    node_js
    1/31/2021 7:24 PM
    by Oleksandr
*/
import {uklv} from "../config/adap/map";


import {createContext, useEffect, useReducer, useState} from 'react';
import * as geolib from "geolib";
import {calcExerciseWorkload, calcFlight, calcPassedPoints} from "../calc/calc";
import {convertToRaw} from "draft-js";
import flightClearanceReducer from "../reducers/flightClearanceReducer";

let squawks = [];
let assignedSquawks = -1;
for (let i = 1; i < 7; i++) {
    for (let j = 1; j < 7; j++) {
        for (let x = 1; x < 8; x++) {
            for (let z = 1; z < 8; z++) {
                squawks.push(i + '' + j + '' + x + '' + z);
            }
        }
    }
}
const getSquawk = () => {
    assignedSquawks++;
    if (assignedSquawks > squawks.length - 1) assignedSquawks = 0;
    return squawks[assignedSquawks];
}
const shuffle = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}
shuffle(squawks);

export const CwpContext = createContext({
    zoom: 6000,
    setZoom: () => {
    },
    tickValue: 0,
    setTickValue: () => {
    },
    tickSpeed: 1000,
    setTickSpeed: () => {
    },
    timeValue: 0,
    setTimeValue: () => {
    },
    flights: [],
    setFlights: () => {
    },
    startDate: new Date(),
    setStartDate: () => {
    },
});

export function CwpContextProvider({children}) {
    const [isLoading, setIsLoading] = useState(true);
    const [zoom, setZoom] = useState(6000);
    const [tickValue, setTickValue] = useState(0);
    const [timeValue, setTimeValue] = useState(0);
    const [tickSpeed, setTickSpeed] = useState(1000);
    const [tickPause, setTickPause] = useState(true);
    const [flights, setFlights] = useState([]);
    const [historicalFlights, setHistoricalFlights] = useState([]);
    const [trackNum, setTrackNum] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [timeOfUpdate, setTimeOfUpdate] = useState(null);
    const [exerciseName, setExerciseName] = useState('new_exercise');
    const [files, setFiles] = useState([]);
    const [lonLatCenter, setLonLatCenter] = useState([23, 50.7]);
    const [addTrackPlan, setAddTrackPlan] = useState();
    const [isCallsignMenuVisible, setIsCallsignMenuVisible] = useState(false);
    const [callsignMenuFlight, setCallsignMenuFlight] = useState(null);
    const [typeOfFlightMenu, setTypeofFlightMenu] = useState(null);
    const [updateFlights, setUpdateFlights] = useState(() => {
    });

    const [updateFlightsTime, setUpdateFlightsTime] = useState(new Date().getTime());
    const [map, setMap] = useState({
        borders: true, ukll_app_rw31ils: false, ukll_app_rw31star: false,
        ukll_app_rw13ils: false, ukll_app_rw13star: false,
        ukln_app_rw15star: false, ukln_app_rw33star: false,
        points: true, extFreq: true
    });
    const [pttWindows, setPttWindows] = useState({
        exerciseWindow: false, activeWindow: false, historicalWindow: false, aswToolbox1Window: false,
        systemTimeWindow: false, workloadWindow: false, exerciseDescriptionWindow: false,
        rwyWindow: false, speedVectorWindow: false, mapWindow: false, plannerWindow: false,
        sectorWindow: false, mtcdWindow: true, mainMenu: true
    });
    const [trackPlanSet, setTrackPlanSet] = useState(new Set);

    const [isConnected, setIsConnected] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [exerciseDescription, setExerciseDescription] = useState('');
    const [runways, setRunways] = useState([{"ICAOcode": "UKLL", "Runways": "31"},
        {"ICAOcode": "UKLN", "Runways": "15"}]);
    const [speedVector, setSpeedVector] = useState({isVisible: true, time: 3});
    const [sectors, setSectors] = useState({
        ops: {
            name: "uklv",
            sector: "ALVC",
            basicSectors: ["E345", "W345", "C355", "C365", "C375", "C385", "C660"],
            freq: "135.600",
            vLimit: {lower: 32500, upper: 66000},
            maxTV: 35
        }
    })
    const [exerciseData, setExerciseData] = useState();
    const [conflictNumberList, setConflictNumberList] = useState([]);
    const [conflictInfoList, setConflictInfoList] = useState({});
    const [workloadArray, setWorkloadArray] = useState([]);
    const [exerciseWorkload, setExerciseWorkload] = useState({});
    const [inSectorFlights, setInSectorFights] = useState([]);


    const [flightClearances, dispatchFlightClearance] = useReducer(flightClearanceReducer, {})


    const getData = async () => {
        fetch('parsedFpl.js',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            // .then(function (flights) {
            //     let calculatedFlights = [];
            //     flights.forEach((fpl, index) => {
            //
            //         // let cops = ["PEVOT",
            //         //     "USTIL",
            //         //     "ROLKA",
            //         //     "GOTIX",
            //         //     "DIBED",
            //         //     "POBUV",
            //         //     "TEPNA",
            //         //     "DIDUR",
            //         //     "INROG",
            //         //     "UNDOL",
            //         //     "RUMUK",
            //         //     "ROMOL",
            //         //     "BUKOV",
            //         //     "LUGOL",
            //         //     "EROMO",
            //         //     "TUREC",
            //         //     "BAMUD",
            //         //     "LONLA",
            //         //     "GEMTO",
            //         //     "LADOB",
            //         //     "MALBE",
            //         //     "LASOT",
            //         //     "BURAK",
            //         //     "DIBON",
            //         //     "AMIRI",
            //         //     "TOLPA",
            //         //     "IVNER",
            //         //     "SOMAT",
            //         //     "TADUN",
            //         //     "OKROT",
            //         //     "SORON",
            //         //     "SITBA",
            //         //     "KOROP",
            //         //     "TAKET",
            //         //     "DORER"];
            //         // let internalFix = ["TUKPU",
            //         //     "LN405",
            //         //     "ADBAN",
            //         //     "AMIRI",
            //         //     "LN101",
            //         //     "VADIM",
            //         //     "LLN01",
            //         //     "TE",
            //         //     "SR",
            //         //     "LOPNU",
            //         //     "L13D01",
            //         //     "LN401",
            //         //     "SOLNU",
            //         //     "GORKU",
            //         //     "LADOB",
            //         //     "PEPIL",
            //         //     "BB",
            //         //     "ROLKA",
            //         //     "INROG",
            //         //     "GOTRA",
            //         //     "PEMIR",
            //         //     "RUMUK",
            //         //     "PEVUK",
            //         //     "ILTUK",
            //         //     "LONLA",
            //         //     "LUGOL",
            //         //     "LURIK",
            //         //     "UNDOL",
            //         //     "OBENO",
            //         //     "RELNO",
            //         //     "DIBON",
            //         //     "OTRAK",
            //         //     "SORON",
            //         //     "RULUT",
            //         //     "GOTIX",
            //         //     "LL402",
            //         //     "LL403",
            //         //     "KOVUS",
            //         //     "LL111",
            //         //     "LL112",
            //         //     "PIGUM",
            //         //     "BURAK",
            //         //     "TOLPA",
            //         //     "USTIL",
            //         //     "EROMO",
            //         //     "SOMAT",
            //         //     "IDKOG",
            //         //     "OSGAS",
            //         //     "ABSON",
            //         //     "POBUV",
            //         //     "BABIS",
            //         //     "TERBU",
            //         //     "GIDNO",
            //         //     "OKRIK",
            //         //     "DIBED",
            //         //     "SVITA",
            //         //     "LL401",
            //         //     "TUMBI",
            //         //     "KOKUP",
            //         //     "SIRSI",
            //         //     "LL106",
            //         //     "LL109",
            //         //     "LL113",
            //         //     "LL114",
            //         //     "LL115",
            //         //     "LL201",
            //         //     "LL202",
            //         //     "LL204",
            //         //     "LL205",
            //         //     "LLE01",
            //         //     "TETNA",
            //         //     "BAGSA",
            //         //     "POBED",
            //         //     "BAMUD",
            //         //     "IVF",
            //         //     "IVNER",
            //         //     "KUSAK",
            //         //     "LL108",
            //         //     "RVN",
            //         //     "DIDUR",
            //         //     "TADUN",
            //         //     "LIV",
            //         //     "VABOD",
            //         //     "DORER",
            //         //     "TAKET",
            //         //     "OKROT",
            //         //     "PEVOT",
            //         //     "ILO",
            //         //     "ILV",
            //         //     "TEPNA",
            //         //     "IF",
            //         //     "UO",
            //         //     "UZH",
            //         //     "LL107",
            //         //     "BANUV",
            //         //     "LLE02",
            //         //     "TER",
            //         //     "LLW01",
            //         //     "LNE01",
            //         //     "CR",
            //         //     "IV",
            //         //     "LASOT",
            //         //     "LL110",
            //         //     "LNS01",
            //         //     "GEMTO",
            //         //     "LN201",
            //         //     "LN210",
            //         //     "LN301",
            //         //     "LN205",
            //         //     "SITBA",
            //         //     "LN410",
            //         //     "NALAD",
            //         //     "ORTUL",
            //         //     "TAKON",
            //         //     "LL116",
            //         //     "BUKOV",
            //         //     "LN215",
            //         //     "UKLLK",
            //         //     "UKLLV",
            //         //     "UKLLJ",
            //         //     "UKLLZ",
            //         //     "UKLLB",
            //         //     "UKLLS",
            //         //     "UKLLN",
            //         //     "UKLLT",
            //         //     "UKLLC",
            //         //     "UKLLG",
            //         //     "UKLLM",
            //         //     "MALBE",
            //         //     "UKLLR",
            //         //     "UKLLI",
            //         //     "UKLLP",
            //         //     "UKLRA",
            //         //     "UKLRI",
            //         //     "UKLRK",
            //         //     "UKLRS",
            //         //     "UKLRU",
            //         //     "LL104",
            //         //     "TUREC",
            //         //     "LL102",
            //         //     "ODESU",
            //         //     "VI",
            //         //     "LNN10",
            //         //     "LL103",
            //         //     "LNS05",
            //         //     "LL301",
            //         //     "KOROP",
            //         //     "LL302",
            //         //     "LL303",
            //         //     "LL304",
            //         //     "LNN05",
            //         //     "URFAN",
            //         //     "LANKU",
            //         //     "ROMOL",
            //         //     "LNN01",
            //         //     "LL203",
            //         //     "LL105",
            //         //     "TOVNI",
            //         //     "RD",
            //         //     "RIMOS"];
            //
            //         // let calculatedFlight = calcFlight(fpl, 0);
            //         // if (calculatedFlight != null) {
            //         //     calculatedFlight = {
            //         //         c: calculatedFlight[0].c,
            //         //         flight: calculatedFlight,
            //         //         timeShift: 0, //parseInt(500 - Math.random() * 1000),
            //         //         entryCop: fpl.entryCop || '',
            //         //         exitCop: fpl.exitCop || '',
            //         //         id: fpl[9],
            //         //         rfl: fpl.rfl,
            //         //         cas: fpl.cas,
            //         //         spd: fpl.spd,
            //         //         fpl: fpl,
            //         //         adep: fpl[5].substr(0, 4),
            //         //         ades: fpl[7].substr(0, 4)
            //         //     }
            //         //
            //         //     calculatedFlights.push(calculatedFlight);
            //         // }
            //     });
            //
            //     // console.log(calculatedFlights)
            //     // return calculatedFlights;
            //     return ...
            // })
            .then(function (myJson) {
                setHistoricalFlights([...myJson]);
                return myJson;
            })
            .then(function (myJson) {
                // console.log(myJson)

                myJson.forEach((flt) => {
                    const {flight} = flt;
                    flt.timeShift = 0;
                    flt.type = flt.fpl[3].split('/')[0];
                    flt.passedPoints = flight.reduce((pp, point) => {
                        if (point.pP != null) {
                            pp.push({index: flight.indexOf(point), pp: point})
                        }
                        return pp;
                    }, []);
                    flt.isAdvanced = true;
                    flt.isCorrelated = true;
                });

                return null;
            })
            .then((tst) => {
                let date1 = Date.now()
            });
        // getAllSim()
        //     .then(data=>{
        //         console.log(data);
        //         setFiles(data);
        //     });
        getAllSim().then(() => {
            console.log(files);
        })


    }

    const calcFlightsNum = (flightsArray) => {
        let tempFlightsNum = [];
        for (let i = 0; i <= 1050; i += 75) {
            let flNumPerMin = 0;
            flightsArray.map((flt) => {
                let {flight, timeShift, entryCop} = flt;
                let index = i + timeShift;
                if (flight.length > index && index >= 0) {
                    if (geolib.isPointInPolygon({
                        latitude: flight[index].cP.latitude,
                        longitude: flight[index].cP.longitude
                    }, uklv)) flNumPerMin++;
                }
            });
            tempFlightsNum.push(flNumPerMin);
        }
        // console.log(Date.now() - date1);

        setTrackNum(tempFlightsNum);
        console.log(tempFlightsNum);
    }

    const updateFlight = (id, value) => {
        if (id == null) return;
        let flight = flights.filter((flight) => {
            return flight.id === id;
        })[0];
        flight.timeShift += value;
        calcPassedPoints(flight, startDate.getTime());
        // calcFlight(flight, 0, startDate.getTime())

        setTimeOfUpdate(new Date());
    }
    const updateAllFlightsForEpp = () => {
        // setIsLoading(true);
        console.log("updateAllFlightsForEpp")
        flights.forEach(flight => {
            flight.flight[0].isAdvanced = false;
            flight.flight[0].isCorrelated = true;
            updateFlightForEpp(flight, true);
        });
        setIsLoading(false);
        setUpdateFlightsTime(new Date().getTime());
    }
    const updateFlightForEpp = (flight, isEpp) => {
        console.log("updateFlightForEpp");
        flight.flight[0].isAdvanced = false;
        calcFlight(flight, 0, startDate.getTime());
        let ind1 = flight.timeShift;

        let tempSetOfBasicSetors = new Set();
        for (let ii = ind1; ii < flight.flight.length; ii += 15) {
            if (flight.flight[ii] && flight.flight[ii].iBSN) tempSetOfBasicSetors.add(flight.flight[ii].iBSN);
        }
        let arrayOfBasicSetors = Array.from(tempSetOfBasicSetors);
        arrayOfBasicSetors.forEach(a => {
            if (sectors.ops.basicSectors.includes(a)) flight.flight[0].isAdvanced = true;
        });
        calcFlight(flight, 0, startDate.getTime());
        if (!isEpp) return;
        for (let tickValue = 0; tickValue < 900; tickValue += 5) {
            let ind1 = tickValue + flight.timeShift;
            if (flight.flight[ind1]) {
                if (flight.flight[ind1].iBSN && sectors.ops.basicSectors.includes(flight.flight[ind1].iBSN) && !flight.flight[ind1].isAssumed) {
                    if (flight.flight[ind1].isAssumed) return;
                    flight.flight[ind1].isAssumed = true;
                    flight.flight[ind1].isAdvanced = false;
                    calcFlight(flight, ind1, startDate.getTime());
                    console.log("assume", flight.c, flight.flight[ind1].iBSN);

                } else if ((!flight.flight[ind1].iBSN || !sectors.ops.basicSectors.includes(flight.flight[ind1].iBSN)) && flight.flight[ind1].isAssumed) {
                    flight.flight[ind1].isAssumed = false;
                    calcFlight(flight, ind1, startDate.getTime());

                    let tempSetOfBasicSetors = new Set();
                    for (let ii = ind1 + 5; ii < flight.flight.length; ii += 5) {
                        flight.flight[ii].iBSN && tempSetOfBasicSetors.add(flight.flight[ii].iBSN);
                    }
                    let arrayOfBasicSetors = Array.from(tempSetOfBasicSetors);
                    arrayOfBasicSetors.forEach(a => {
                        console.log("arrayOfBasicSetors", a, sectors.ops.basicSectors);
                        if (sectors.ops.basicSectors.includes(a)) flight.flight[ind1].isAdvanced = true;
                    });

                    calcFlight(flight, ind1, startDate.getTime());
                }
                if ((flight.flight[ind1].tod || flight.flight[ind1].toc) && flight.flight[ind1].isAssumed) {
                    console.log("tod, toc", flight.flight[ind1].c, flight.flight[ind1].tod, flight.flight[ind1].toc)
                    flight.flight[ind1].sA = flight.flight[ind1].xfl * 100;
                    calcFlight(flight, ind1, startDate.getTime())
                }
            }
        }
        setUpdateFlightsTime(new Date().getTime());

    }

    const saveExercise = async (exerciseJson, flights) => {
        // let promptName = 'tst';
        let atm_code = localStorage.getItem('atm_code');
        atm_code = atm_code === 'undefined' ? 'CON' : atm_code;
        let promptName = window.prompt('Exercise name: ' + atm_code + '_',
            exerciseName.replace(atm_code + '_', ''));
        if (promptName === null) return;

        // console.log(flights, atm_code);

        // let exerciseJson = [];
        // flights.forEach((flight) => {
        //     exerciseJson.push({id: flight.id, timeShift: flight.timeShift});
        // });
        let searchParams = new URLSearchParams();
        // searchParams.set('name', atm_code + '_' + promptName);
        // searchParams.set('text', JSON.stringify(exerciseJson));
        // let requestOptions = {
        //     method: 'POST',
        //     mode: "no-cors",
        //     cache: "no-cache",
        //     headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        //     body: searchParams
        // };
        // fetch('http://ptt.lvivacc.site/test.php', requestOptions)
        //     .then(response => {
        //         console.log(response)
        //     })
        // searchParams = new URLSearchParams();
        // let exerciseJson = [];
        // flights.forEach((flight) => {
        //     exerciseJson.push({id: flight.id, timeShift: flight.timeShift});
        // });action == "update
        let calExerciseData = calcExerciseWorkload(flights, sectors, false);
        // setInSectorFights(calExerciseData.inSectorFlights);
        console.log(flights.length, calExerciseData);
        let exerciseWorkload = {
            ops: sectors.ops,
            numberOfFlights: calExerciseData.uniqueFlights.size,
            trafficVolume: calExerciseData.inSectorFlights
        };

        searchParams.set('action', 'update');
        searchParams.set('exercise', atm_code + '_' + promptName);
        searchParams.set('exerciseDescription', JSON.stringify(convertToRaw(exerciseDescription.getCurrentContent())));
        searchParams.set('flights', JSON.stringify(exerciseJson));
        searchParams.set('exerciseWorkload', JSON.stringify(exerciseWorkload));
        console.log('exerciseWorkload', JSON.stringify(exerciseWorkload));
        let requestOptions = {
            method: 'POST',
            mode: "cors",
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: searchParams
        };
        fetch('https://lvivacc.site/ptt/exercise_2.php', requestOptions)
            .then(response => response.json())
            .then(() => {
                getAllSim();
                setExerciseName(atm_code + '_' + promptName);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        // console.log(JSON.stringify(exerciseJson))
    }

    const getAllSim = async () => {
        let searchParams = new URLSearchParams();
        searchParams.set('action', 'getAllSim');
        let requestOptions = {
            method: 'POST',
            mode: "cors",
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: searchParams
        };
        // fetch('https://ptt.lvivacc.site/getAllSim.php', requestOptions)
        fetch('https://lvivacc.site/ptt/exercise_2.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setFiles(data);
                // return data;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const assumeFlight = (flight, index) => {
        if (flight.flight[index].isAdvanced) {
            flight.flight[index].isAssumed = true;
            flight.flight[index].isAdvanced = false;
        } else if (flight.flight[index].isAssumed) {
            flight.flight[index].isAssumed = false;
        } else if (flight.flight[index].isCorrelated) {
            flight.flight[index].isAssumed = true;
            flight.flight[index].isAdvanced = false;
        }
        calcFlight(flight, index, startDate.getTime());
        dispatchFlightClearance({
            type: 'assume',
            flight,
            index,
            startDate,
        });
        setUpdateFlightsTime(new Date().getTime());
    }

    const decontrolFlight = (flight, index) => {
        // console.log("decontrolFlight");
        flight.flight[index].isAssumed = false;
        // calcFlight(flight, index);
    }

    const conAssumeFlight = (flight) => {
        // console.log(flight)
        const autoTrackPlan = () =>{
            trackPlanSet.add(flight.id);
            setTrackPlanSet(trackPlanSet);

            setTimeout(()=>{
                trackPlanSet.delete(flight.id);
                setTrackPlanSet(trackPlanSet);
            }, 1000)
        }
        if (flight.isAdvanced) {
            flight.isAssumed = true;
            flight.isAdvanced = false;
            autoTrackPlan();
        } else if (flight.isAssumed) conDecontrolFlight(flight);
        else {
            flight.isAssumed = true;
            flight.isAdvanced = false;
            autoTrackPlan();
        }
        setUpdateFlightsTime(new Date().getTime());



        // console.log(flight)
    }

    const conDecontrolFlight = (flight) => {
        flight.isAssumed = false;
    }

    const showCallsignMenu = (flight, clientX, clientY) => {
        if (flight === null) {
            setIsCallsignMenuVisible(false);
            setCallsignMenuFlight(null);
        } else {
            setIsCallsignMenuVisible(true);
            setCallsignMenuFlight({flight, clientX, clientY});
        }
    }

    const showFlightMenu = (flight, clientX, clientY, type) => {
        if (clientY + 200 > document.body.clientHeight) clientY = document.body.clientHeight - 210;
        if (flight === null) {
            setTypeofFlightMenu(null);
            setCallsignMenuFlight(null);
        } else {
            setTypeofFlightMenu(type);
            setCallsignMenuFlight({flight, clientX, clientY});
        }
    }


    const state = {
        isLoading, setIsLoading,
        zoom, setZoom, tickValue, setTickValue,
        timeValue, setTimeValue, tickPause, setTickPause,
        flights, setFlights, trackNum, setTrackNum,
        startDate, setStartDate, tickSpeed, setTickSpeed,
        selectedFlight, setSelectedFlight, updateFlight,
        timeOfUpdate, calcFlightsNum, historicalFlights, setHistoricalFlights,
        saveExercise, exerciseName, setExerciseName,
        getSquawk, getAllSim, files, getData,
        lonLatCenter, setLonLatCenter,
        addTrackPlan, setAddTrackPlan,
        assumeFlight, decontrolFlight, conAssumeFlight,
        showCallsignMenu, isCallsignMenuVisible, callsignMenuFlight,
        updateFlights, setUpdateFlights,
        updateFlightsTime, setUpdateFlightsTime,
        showFlightMenu, typeOfFlightMenu, setTypeofFlightMenu,
        map, setMap, pttWindows, setPttWindows,
        isConnected, setIsConnected, sessionId, setSessionId,
        exerciseDescription, setExerciseDescription,
        runways, setRunways,
        speedVector, setSpeedVector,
        sectors, setSectors,
        flightClearances, dispatchFlightClearance,
        setExerciseData,
        conflictNumberList, setConflictNumberList, conflictInfoList, setConflictInfoList,
        workloadArray, setWorkloadArray, exerciseWorkload, setExerciseWorkload, inSectorFlights, setInSectorFights,
        updateFlightForEpp, updateAllFlightsForEpp, trackPlanSet, setTrackPlanSet
    }
    // useEffect(() => {
    //     getData();
    // }, [setFlights]);

    useEffect(() => {
        let date = new Date();
        date.setHours(12, 0, 0);
        setStartDate(date);
    }, [setStartDate]);

    return (
        <CwpContext.Provider value={state}>
            {children}
        </CwpContext.Provider>
    );

}