/*
    node_js
    3/12/2021 5:50 AM
    by Oleksandr
*/

import Window from "../Window";
import React from "react";
import "../../../CWP.css";
import SpeedVectorWindow from "./SpeedVectorWindow";
import MapWindow from "./MapWindow";

const AswToolbox = (props) => {
    const {map, setMap, pttWindows, setPttWindows, speedVector, setSpeedVector} = props;

    return (
        <>
            <Window isVisible={true} toggleWindow={() => setPttWindows({...pttWindows, aswToolbox1Window: false})}
                    label={'Asw Toolbox #1'} width={'auto'}
                    content={<>
                        <div style={{
                            display: "grid", gridTemplateColumns: "auto auto auto auto",
                            gridGap: "3px", color: "grey", textAlign: "center", padding: "3px"
                        }}>
                            <span className={`buttonSDD buttonSDDdisabled`}>Trail 10</span>
                            <span className={`buttonSDD buttonSDDdisabled`}>R&B Track</span>
                            <span className={`buttonSDD ${pttWindows.mapWindow?"buttonSDDpressed":""}`}
                                  onClick={e=>setPttWindows({...pttWindows, mapWindow: !pttWindows.mapWindow})}
                            >Map</span>
                            <span className={`buttonSDD ${pttWindows.speedVectorWindow?"buttonSDDpressed":""}`}
                                  onClick={e=>setPttWindows({...pttWindows, speedVectorWindow: !pttWindows.speedVectorWindow})}
                            >Vector {speedVector.time}</span>
                            {/*<span className={'buttonSDD'} onClick={() => {*/}
                            {/*    setMap({...map, points: !map.points});*/}
                            {/*}}>Points</span><br/>*/}
                            {/*<span className={'buttonSDD'} onClick={() => {*/}
                            {/*    setMap({...map, ukll_app_rw13ils: !map.ukll_app_rw13ils});*/}
                            {/*}}>ILS13</span>*/}
                            {/*<span className={'buttonSDD'} onClick={() => {*/}
                            {/*    setMap({...map, ukll_app_rw13star: !map.ukll_app_rw13star});*/}
                            {/*}}>RNAV13</span>*/}
                            {/*<span className={'buttonSDD'} onClick={() => {*/}
                            {/*    setMap({...map, ukll_app_rw31ils: !map.ukll_app_rw31ils});*/}
                            {/*}}>ILS31</span>*/}
                            {/*<span className={'buttonSDD'} onClick={() => {*/}
                            {/*    setMap({...map, ukll_app_rw31star: !map.ukll_app_rw31star});*/}
                            {/*}}>RNAV31</span>*/}

                        </div>
                    </>}
            />
            {pttWindows.mapWindow && <MapWindow {...props} />}
            {pttWindows.speedVectorWindow && <SpeedVectorWindow {...props} />}
        </>
    )
}

export default AswToolbox;