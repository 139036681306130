/*
    node_js
    3/15/2021 8:59 AM
    by Oleksandr
*/

import HistFlightsList from "./lists/HistFlightsList";
import Window from "./Window";
import React, {useState} from "react";

const HistoricalWindow = (props) => {
    const {
        historicalFlights, calcFlightsNum, setFlights, flights, pttWindows, setPttWindows,
        startDate, runways, updateFlightForEpp
    } = props;
    const [q, setQ] = useState({'c': '','type':'', 'adep': '', 'ades': '', 'entryCop': '', 'exitCop': '', 'rfl': ''});
    const columns = ['c','type', 'adep', 'ades', 'entryCop', 'exitCop', 'rfl'];
    const columnsText = ['Callsign', 'TYPE', 'ADEP', 'ADES', 'ENTRY', 'EXIT', 'RFL'];
    const [histSort, setHistSort] = useState('rfl');

    const search = (rows) => rows.filter((row) =>
        columns.every(
            column => {
                if (row[column] && q[column] === '') return 1;
                if (row[column] === undefined && q[column] === '') return 1;
                return (row[column] && q[column]) && row[column].toString().toLowerCase().indexOf(q[column].toLowerCase()) > -1
            }
        )
    );
    const filter = (rows) => {
        if (flights.length > 0) return rows.filter((row) => flights.every((flt) => flt.id !== row.id))
        else return rows
    };
    const sort = (arr) => arr.sort((a, b) => (a[histSort] > b[histSort]) ? 1 : ((b[histSort] > a[histSort]) ? -1 : 0));


    return (
        <>
            <Window isVisible={true} toggleWindow={() => setPttWindows({...pttWindows, historicalWindow: false})}
                    label={'Historical Flights'}
                    width={710}
                    content={
                        <>
                            <div style={{overflowY: "scroll", height: "550px"}}>
                                <table>
                                    <thead>
                                    <tr style={{height: "27px"}}>
                                        {
                                            columns.map((column) =>
                                                <td className='headerTh'>
                                                    <input className='filterHistFlights' type='text'
                                                           id={'filterHistFlights_' + column} onKeyUp={e => {
                                                        e.target.value = e.target.value.toUpperCase().replaceAll(' ', '');
                                                        if (e.keyCode === 13) {
                                                            let filterObj = {};
                                                            columns.map((column2) => {
                                                                    let inputFilter = document.getElementById('filterHistFlights_' + column2);
                                                                    filterObj[column2] = inputFilter.value.replaceAll(' ', '');
                                                                }
                                                            );
                                                            setQ(filterObj);
                                                        }
                                                    }}/>
                                                </td>
                                            )
                                        }
                                        <td><span className='buttonSDD' onClick={() => {
                                            let filterObj = {};
                                            columns.map((column2) => {
                                                    let inputFilter = document.getElementById('filterHistFlights_' + column2);
                                                    filterObj[column2] = inputFilter.value.replaceAll(' ', '');
                                                }
                                            );
                                            setQ(filterObj);
                                        }
                                        }>Update</span></td>
                                    </tr>
                                    <tr>{columns.map((heading, index) => <th className='headerTh'
                                                                             onClick={() => setHistSort(heading)}>{columnsText[index]}</th>)}
                                        <th className='headerTh'></th>
                                    </tr>

                                    </thead>

                                    <HistFlightsList
                                        historicalFlights={sort(filter(search(historicalFlights)).slice(0, 400))}
                                        columns={columns} calcFlightsNum={calcFlightsNum} setFlights={setFlights}
                                        flights={flights} startDate={startDate} runways={runways} updateFlightForEpp={updateFlightForEpp}/>
                                </table>
                            </div>
                        </>
                    }
            />
        </>
    )
}

export default HistoricalWindow;