/*
    node_js
    2/19/2021 3:10 PM
    by Oleksandr
*/

import '../../CWP.css';
import React, {useState} from 'react';
import HistFlightsList from "../windows/lists/HistFlightsList";
import Window from "../windows/Window";
import HistoricalWindow from "../windows/HistoricalWindow";
import ActiveWindow from "../windows/ActiveWindow";
import ExerciseDescriptionWindow from "../windows/ExerciseDescriptionWindow";
import RwyWindow from "../windows/RwyWindow";
import ExerciseWindow from "../windows/ExerciseWindow";
import SectorWindow from "../windows/SectorWindow";
import PlannerList from "../windows/lists/PlannerList";
import MtcdList from "../windows/lists/MtcdList";

const WindowLayer = (props) => {
    const {historicalFlights, pttWindows, setPttWindows,
        isEpp, isCon, isDemo, isPlt, flights} = props;



    return (
        <>
            {pttWindows.mtcdWindow && <MtcdList {...props} />}
            {(!isCon && !isDemo && pttWindows.exerciseDescriptionWindow) &&
            <ExerciseDescriptionWindow {...props} />}
            {(isEpp && pttWindows.historicalWindow) &&
            <HistoricalWindow {...props} />
            }
            {(isEpp && pttWindows.activeWindow) &&
            <ActiveWindow {...props} flights={flights} />
            }
            {((isEpp || isPlt) && pttWindows.rwyWindow) && <RwyWindow {...props} />}
            {pttWindows.exerciseWindow && <ExerciseWindow {...props}/>}
            {pttWindows.sectorWindow && <SectorWindow {...props}/>}
            {/*<EppTable {...props} />*/}
            {(pttWindows.plannerWindow && (isPlt || isCon)) && <PlannerList {...props} />}
        </>
    )
}

export default WindowLayer;